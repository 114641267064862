import { useSnackbar } from 'notistack5';
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import { Typography, Stack, Button } from '@material-ui/core';
import { defaultTo } from 'lodash';
import { GENERIC_CONTENT_POSITIONS, SUBSCRIPTION_POSITIONS } from '../../../utils/constantsEditor';
import FooterSubscriptionEditorCommon from './FooterSubscriptionEditorCommon';
import { EditorConatiner } from '../../../common/EditorConatiner';
import { REQUIRED_OPTION_MESSAGE } from '../../../utils/constants';

export default function FooterSubscriptionEditor({ heading, config, closeEdit, handleUpdateComponent }) {
  const { enqueueSnackbar } = useSnackbar();

  const showSubscrition = config?.showSubscrition;
  const subscription = config?.subscription;

  const FooterSubscriptionSchema = Yup.object().shape({
    showSubscrition: Yup.boolean().required(REQUIRED_OPTION_MESSAGE),
    subscription: Yup.object().shape({
      title: Yup.string().nullable(),
      description: Yup.string().nullable(),
      placeholder: Yup.string().nullable(),
      buttonText: Yup.string().nullable(),
      buttonColour: Yup.string().nullable(),
      buttonTextColour: Yup.string().nullable(),
      viewType: Yup.string().required(REQUIRED_OPTION_MESSAGE),
      horizontalPosition: Yup.string().required(REQUIRED_OPTION_MESSAGE),
      textPosition: Yup.string().required(REQUIRED_OPTION_MESSAGE),
      mobileTextPosition: Yup.string().required(REQUIRED_OPTION_MESSAGE),
      showDivider: Yup.boolean().required(REQUIRED_OPTION_MESSAGE),
      dividerColour: Yup.string().nullable()
    })
  });

  const form = useFormik({
    initialValues: {
      showSubscrition: defaultTo(showSubscrition, true),
      subscription: {
        title: defaultTo(subscription?.title, ''),
        description: defaultTo(subscription?.description, ''),
        placeholder: defaultTo(subscription?.placeholder, ''),
        buttonText: defaultTo(subscription?.buttonText, ''),
        buttonColour: defaultTo(subscription?.buttonColour, ''),
        buttonTextColour: defaultTo(subscription?.buttonTextColour, ''),
        viewType: defaultTo(subscription?.viewType, SUBSCRIPTION_POSITIONS[0]),
        horizontalPosition: defaultTo(subscription?.horizontalPosition, GENERIC_CONTENT_POSITIONS[1].value),
        textPosition: defaultTo(subscription?.textPosition, GENERIC_CONTENT_POSITIONS[1].value),
        mobileTextPosition: defaultTo(subscription?.mobileTextPosition, GENERIC_CONTENT_POSITIONS[1].value),
        showDivider: defaultTo(subscription?.showDivider, false),
        dividerColour: defaultTo(subscription?.dividerColour, '')
      }
    },
    validationSchema: FooterSubscriptionSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        handleUpdateComponent({
          ...config,
          ...values
        });
        enqueueSnackbar('Saved', { variant: 'success' });
        setSubmitting(false);
      } catch (error) {
        console.error(error);
        setSubmitting(false);
      }
    }
  });

  const { handleSubmit } = form;

  return (
    <FormikProvider value={form}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <EditorConatiner>
          <Stack spacing={3}>
            <Stack spacing={2} justifyContent="flex-end" direction="row">
              <Button variant="outlined" color="primary" onClick={closeEdit}>
                Cancel
              </Button>
              <Button variant="contained" color="primary" type="submit">
                Save
              </Button>
            </Stack>
            <Typography variant="h5">{heading}</Typography>

            <FooterSubscriptionEditorCommon form={form} />
          </Stack>
        </EditorConatiner>
      </Form>
    </FormikProvider>
  );
}
