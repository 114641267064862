// material
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'src/redux/store';
import useEditor from 'src/hooks/useEditor';
import { updateLayoutComponent } from 'src/redux/slices/store';
import { Stack } from '@material-ui/core';
import documentHeader20Filled from '@iconify/icons-fluent/document-header-20-filled';
import documentHeaderFooter20Filled from '@iconify/icons-fluent/document-header-footer-20-filled';
import documentFooter20Filled from '@iconify/icons-fluent/document-footer-20-filled';
import emailOutline from '@iconify/icons-eva/email-outline';
import shelfPosition from '@iconify/icons-material-symbols/shelf-position';
import colourLens from '@iconify/icons-mdi/colour-lens';
import megaphoneLoud16Filled from '@iconify/icons-fluent/megaphone-loud-16-filled';
import dollarSign from '@iconify/icons-ant-design/dollar-circle-filled';
import sideNavigation from '@iconify/icons-material-symbols/side-navigation';
import SingleRowComponent from '../components/SingleRowComponent';
import HeaderEditor from './form-components/header/HeaderEditor';
import FooterEditor from './form-components/footer/FooterEditor';
import AnnouncementEditor from './form-components/AnnouncementEditor';
import { GenericEditorLayout } from '../components/GenericEditorLayout';
import MultiViewRowComponent from '../components/MultiViewRowComponent';
import FooterSubscriptionEditor from './form-components/footer/FooterSubscriptionEditor';
import FooterOrientationEditor from './form-components/footer/FooterOrientationEditor';
import BodyEditor from './form-components/BodyEditor';
import CurrencyEditor from './form-components/CurrencyEditor';
import HeaderOrientationEditor from './form-components/header/HeaderOrientationEditor';
import HeaderStyleEditor from './form-components/header/HeaderStyleEditor';
import FooterStyleEditor from './form-components/footer/FooterStyleEditor';
import AnnouncementStyleEditor from './form-components/AnnouncementStyleEditor';

// ------------------------------------------------------------------ View Components

const AnnouncementRow = ({ ...other }) => {
  return (
    <MultiViewRowComponent
      componentName="Announcement"
      title="Announcement"
      icon={megaphoneLoud16Filled}
      disableOrder
      rows={[
        {
          name: 'General',
          component: <AnnouncementEditor heading="Announcement" {...other} />,
          icon: documentHeader20Filled
        },
        {
          name: 'Style',
          component: <AnnouncementStyleEditor heading="Style" {...other} />,
          icon: colourLens
        }
      ]}
      {...other}
    />
  );
};

const BodyRow = ({ ...other }) => {
  return (
    <SingleRowComponent
      disableOrder
      componentName="Body"
      title="Body"
      icon={documentHeaderFooter20Filled}
      editComponent={<BodyEditor heading="Body" {...other} />}
      {...other}
    />
  );
};

const HeaderRow = ({ ...other }) => {
  return (
    <MultiViewRowComponent
      componentName="Header"
      title="Header"
      icon={documentHeader20Filled}
      disableOrder
      rows={[
        {
          name: 'General',
          component: <HeaderEditor heading="General" {...other} />,
          icon: documentHeader20Filled
        },
        {
          name: 'Orientation',
          component: <HeaderOrientationEditor heading="Orientation" {...other} />,
          icon: shelfPosition
        },
        {
          name: 'Style',
          component: <HeaderStyleEditor heading="Style" {...other} />,
          icon: colourLens
        }
      ]}
      {...other}
    />
  );
};

const FooterRow = ({ ...other }) => {
  return (
    <MultiViewRowComponent
      componentName="Footer"
      title="Footer"
      icon={documentFooter20Filled}
      rows={[
        {
          name: 'General',
          component: <FooterEditor heading="Footer" {...other} />,
          icon: documentFooter20Filled
        },
        {
          name: 'Orientation',
          component: <FooterOrientationEditor heading="Orientation" {...other} />,
          icon: shelfPosition
        },
        {
          name: 'Style',
          component: <FooterStyleEditor heading="Style" {...other} />,
          icon: colourLens
        },
        {
          name: 'Email Sign Up',
          component: <FooterSubscriptionEditor heading="Email Sign Up" {...other} />,
          icon: emailOutline
        }
      ]}
      {...other}
    />
  );
};

const CurrencyRow = ({ config, handleUpdateComponent, ...other }) => {
  return (
    <MultiViewRowComponent
      componentName="Currency"
      title="Currency"
      icon={dollarSign}
      rows={[
        {
          name: 'Footer',
          component: (
            <CurrencyEditor
              heading="Footer"
              subHeading="Configure the currency selector that is located at the bottom of the page inside the footer"
              config={config.footer}
              handleUpdateComponent={(values) => handleUpdateComponent({ ...config, footer: { ...values } })}
              {...other}
            />
          ),
          icon: documentFooter20Filled
        },
        {
          name: 'Mobile Sidebar',
          component: (
            <CurrencyEditor
              isMobileView
              heading="Mobile Sidebar"
              subHeading="Configure the currency selector that is located in the sidebar when in mobile view"
              config={config.sidebar}
              handleUpdateComponent={(values) => handleUpdateComponent({ ...config, sidebar: { ...values } })}
              {...other}
            />
          ),
          icon: sideNavigation
        }
      ]}
      {...other}
    />
  );
};

// ------------------------------------------------------------------ Main

export default function LayoutSidebar() {
  const dispatch = useDispatch();
  const {
    editor: {
      page,
      theme: { layout }
    },
    options: { navigations }
  } = useSelector((state) => state.store);

  const { editDrawer, onUpdateEditorDrawer } = useEditor();

  const handleOpenDrawer = useCallback(
    (editComponent) => onUpdateEditorDrawer({ open: true, component: editComponent }),
    [onUpdateEditorDrawer]
  );

  const handleCloseDrawer = useCallback(
    () => onUpdateEditorDrawer({ open: false, component: null }),
    [onUpdateEditorDrawer]
  );

  const handleUpdateHeaderComponent = useCallback(
    (values) => {
      dispatch(updateLayoutComponent({ id: 'header', values }));
    },
    [dispatch]
  );

  const handleUpdateFooterComponent = useCallback(
    (values) => {
      dispatch(updateLayoutComponent({ id: 'footer', values }));
    },
    [dispatch]
  );

  const handleUpdateBodyComponent = useCallback(
    (values) => {
      dispatch(updateLayoutComponent({ id: 'body', values }));
    },
    [dispatch]
  );

  const handleUpdateAnnouncementComponent = useCallback(
    (values) => {
      dispatch(updateLayoutComponent({ id: 'announcement', values }));
    },
    [dispatch]
  );

  const handleUpdateCurrencyComponent = useCallback(
    (values) => {
      dispatch(updateLayoutComponent({ id: 'currency', values }));
    },
    [dispatch]
  );

  const ComponentList = useMemo(() => {
    const common = {
      options: navigations,
      openEdit: handleOpenDrawer,
      closeEdit: handleCloseDrawer
    };
    return (
      <Stack direction="column" spacing={1} sx={{ p: 2 }}>
        <AnnouncementRow
          config={layout.announcement}
          handleUpdateComponent={handleUpdateAnnouncementComponent}
          {...common}
        />
        <HeaderRow config={layout.header} handleUpdateComponent={handleUpdateHeaderComponent} {...common} />
        <BodyRow config={layout.body} handleUpdateComponent={handleUpdateBodyComponent} {...common} />
        <FooterRow config={layout.footer} handleUpdateComponent={handleUpdateFooterComponent} {...common} />
        <CurrencyRow config={layout.currency} handleUpdateComponent={handleUpdateCurrencyComponent} {...common} />
      </Stack>
    );
  }, [
    navigations,
    layout,
    handleCloseDrawer,
    handleOpenDrawer,
    handleUpdateAnnouncementComponent,
    handleUpdateBodyComponent,
    handleUpdateFooterComponent,
    handleUpdateHeaderComponent,
    handleUpdateCurrencyComponent
  ]);

  return (
    <GenericEditorLayout page={page} editDrawer={editDrawer} handleCloseDrawer={handleCloseDrawer}>
      {ComponentList}
    </GenericEditorLayout>
  );
}
