import { Box, Card, Stack, Typography } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { Icon } from '@iconify/react';
import cogIcon from '@iconify/icons-bxs/cog';

const RootStyle = styled(Box)(() => ({
  '&&': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));

const RootCard = styled(Card)(({ theme }) => ({
  '&&': {
    boxShadow: 'none',
    padding: theme.spacing(5, 5, 10),
    marginTop: theme.spacing(5),
    textAlign: 'center'
  }
}));

const AlertIcon = styled(Icon)(({ theme }) => ({
  '&&': {
    color: theme.palette.grey[400]
  }
}));

export const EmptyConfigurationsView = () => {
  return (
    <RootStyle>
      <RootCard>
        <Stack sx={{ textAlign: 'center', alignItems: 'center' }} direction="column" spacing={2}>
          <AlertIcon icon={cogIcon} width={100} />
          <Typography variant="h4" color="primary.main">
            No Configurations Enabled
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ fontSize: 16 }}>
            Enable either Logo or Navigation to view configurations
          </Typography>
        </Stack>
      </RootCard>
    </RootStyle>
  );
};
