import * as Yup from 'yup';
import { styled } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack5';
import { Form, FormikProvider, useFormik } from 'formik';
import { Box, Typography, Stack, Divider, Button, TextField } from '@material-ui/core';
import { defaultTo } from 'lodash';

const EditorConatiner = styled(Box)(({ theme }) => ({
  padding: theme.spacing(5)
}));

export default function ProductsMessagesEditor({ config, heading, closeEdit, handleUpdateComponent }) {
  const { enqueueSnackbar } = useSnackbar();

  const ProductsMessagesSchema = Yup.object().shape({
    title: Yup.string().nullable().optional(),
    description: Yup.string().nullable().optional()
  });

  const form = useFormik({
    initialValues: {
      title: defaultTo(config?.title, ''),
      description: defaultTo(config?.description, '')
    },
    validationSchema: ProductsMessagesSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        handleUpdateComponent(values);
        enqueueSnackbar('Saved', { variant: 'success' });
        setSubmitting(false);
      } catch (error) {
        console.error(error);
        setSubmitting(false);
      }
    }
  });

  const { initialValues, values, getFieldProps, resetForm, handleSubmit } = form;

  return (
    <FormikProvider value={form}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <EditorConatiner>
          <Stack spacing={3}>
            <Stack spacing={2} justifyContent="flex-end" direction="row">
              <Button variant="outlined" color="primary" onClick={resetForm} disabled={initialValues === values}>
                Reset
              </Button>
              <Box sx={{ flexGrow: 1 }} />
              <Button variant="outlined" color="primary" onClick={closeEdit}>
                Cancel
              </Button>
              <Button variant="contained" color="primary" type="submit">
                Save
              </Button>
            </Stack>
            <div>
              <Typography variant="h5">{heading}</Typography>
              <Typography
                variant="subtitle2"
                color="text.secondary"
                sx={{ fontWeight: (theme) => theme.typography.fontWeightRegular, my: (theme) => theme.spacing(1) }}
              >
                If you choose to leave these values empty, then a default value will be displayed in it's place
              </Typography>
            </div>

            <Divider />

            <Stack spacing={2}>
              <div>
                <Typography variant="body1">
                  <strong>Empty Products</strong>
                </Typography>
                <Typography
                  variant="subtitle2"
                  color="text.secondary"
                  sx={{ fontWeight: (theme) => theme.typography.fontWeightRegular, my: (theme) => theme.spacing(1) }}
                >
                  This is displayed when there are no products available
                </Typography>
              </div>
              <TextField fullWidth autoComplete="off" label="Title" {...getFieldProps('title')} />
              <TextField
                rows={5}
                multiline
                fullWidth
                autoComplete="off"
                label="Description"
                {...getFieldProps('description')}
              />
            </Stack>
          </Stack>
        </EditorConatiner>
      </Form>
    </FormikProvider>
  );
}
