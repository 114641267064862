import { useSnackbar } from 'notistack5';
import { Form, FormikProvider, useFormik } from 'formik';
import { Box, Typography, TextField, Stack, Divider, Button, FormHelperText } from '@material-ui/core';
import { defaultTo } from 'lodash';
import {
  GENERIC_CONTENT_POSITIONS,
  GENERIC_CONTENT_VERTICAL_POSITIONS,
  PAGE_POSITIONS
} from '../../utils/constantsEditor';
import MultiOptionPicker from '../../components/pickers/MultiOptionPicker';
import { EditorConatiner } from '../../common/EditorConatiner';

export default function AnnouncementEditor({ config, heading, closeEdit, handleUpdateComponent }) {
  const { enqueueSnackbar } = useSnackbar();

  const title = config?.title;
  const contentPosition = config?.contentPosition;
  const contentVerticalPosition = config?.contentVerticalPosition;
  const position = config?.position;

  const form = useFormik({
    initialValues: {
      title: defaultTo(title, ''),
      contentPosition: defaultTo(contentPosition, GENERIC_CONTENT_POSITIONS[1].value),
      contentVerticalPosition: defaultTo(contentVerticalPosition, GENERIC_CONTENT_VERTICAL_POSITIONS[1].value),
      position: defaultTo(position, PAGE_POSITIONS[0].value)
    },
    onSubmit: async (values, { setSubmitting }) => {
      try {
        handleUpdateComponent(values);
        enqueueSnackbar('Saved', { variant: 'success' });
        setSubmitting(false);
      } catch (error) {
        console.error(error);
        setSubmitting(false);
      }
    }
  });

  const { values, errors, touched, handleSubmit, getFieldProps, setFieldValue } = form;

  return (
    <FormikProvider value={form}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <EditorConatiner>
          <Stack spacing={3}>
            <Stack spacing={2} justifyContent="flex-end" direction="row">
              <Button variant="outlined" color="primary" onClick={closeEdit}>
                Cancel
              </Button>
              <Button variant="contained" color="primary" type="submit">
                Save
              </Button>
            </Stack>
            <Typography variant="h5">{heading}</Typography>

            <Divider />

            <div>
              <TextField
                fullWidth
                autoComplete="off"
                label="Title"
                {...getFieldProps('title')}
                error={Boolean(touched.title && errors.title)}
                helperText={touched.title && errors.title}
              />
              <FormHelperText>To disable the announcement bar, leave the title empty</FormHelperText>
            </div>

            <Divider />

            <Stack spacing={2}>
              <Typography variant="body1">
                <strong>Position</strong>
              </Typography>
              <div>
                <MultiOptionPicker
                  data={PAGE_POSITIONS}
                  value={values.position}
                  isScrollable
                  onChange={(_, newValue) => setFieldValue('position', newValue)}
                />
                <FormHelperText>
                  Sticky positions will remain at the top of the page at all times.{' '}
                  <strong>This is not visible in preview mode, but this will be applied when deployed.</strong>
                </FormHelperText>
              </div>
            </Stack>

            <Stack spacing={2}>
              <Typography variant="body1">
                <strong>Text Position</strong>
              </Typography>

              <Stack spacing={2}>
                <Typography variant="overline" color="text.secondary">
                  <strong>Horizontal</strong>
                </Typography>

                <MultiOptionPicker
                  data={GENERIC_CONTENT_POSITIONS}
                  value={values.contentPosition}
                  onChange={(_, newValue) => setFieldValue('contentPosition', newValue)}
                />
              </Stack>

              <Stack spacing={2}>
                <Typography variant="overline" color="text.secondary">
                  <strong>Vertical</strong>
                </Typography>

                <Box>
                  <MultiOptionPicker
                    data={GENERIC_CONTENT_VERTICAL_POSITIONS}
                    value={values.contentVerticalPosition}
                    onChange={(_, newValue) => setFieldValue('contentVerticalPosition', newValue)}
                  />
                </Box>
              </Stack>
            </Stack>
          </Stack>
        </EditorConatiner>
      </Form>
    </FormikProvider>
  );
}
