import React from 'react';
import { Icon } from '@iconify/react';
import { Box, Grid, Card, Button, CardHeader, Stack, Typography } from '@material-ui/core';
import { alpha, styled } from '@material-ui/core/styles';
import arrowIosBackFill from '@iconify/icons-eva/arrow-ios-back-fill';
import shoppingCartOutline from '@iconify/icons-eva/shopping-cart-outline';
import { getCartTotal } from '../../utils/cart';
import { fCurrency } from '../../utils/formatNumber';
import { CHECKOUT_PATH, HOME_PATH, SYSTEM_PAGE } from '../../utils/pageTypes';
import { CartProductList } from './CartProductList';
import EmptyContent from '../core/EmptyContent';
import useMobile from '../../hooks/useMobile';
import {
  DEFAULT_CART_EMPTY_BUTTON_TEXT,
  DEFAULT_CART_EMPTY_DESCRIPTION,
  DEFAULT_CART_EMPTY_TITLE
} from '../../utils/constants';
import { isInvalid } from '../../utils/nullable';

const CardStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  borderRadius: theme.shape.borderRadius,
  border: `solid 1px ${alpha(theme.palette.border, 0.32)}`,
  padding: theme.spacing(2)
}));

export const CartCard = ({
  config: { messaging, table },
  products,
  messagingPlacements,
  handleNavigationClick,
  currency,
  ...other
}) => {
  const isMobile = useMobile();
  const {
    showNotes,
    noteTitle,
    showInventoryQuantity,
    showPaymentMessages,
    showBorder,
    showItemsCount,
    buttonViewType
  } = table;
  const isEmptyCart = products.length === 0;
  const totalItems = products.length;
  const cartTotal = getCartTotal(products);

  return (
    <>
      {!isEmptyCart ? (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Stack direction={isMobile ? 'column' : 'row'} {...(isMobile && { spacing: 1 })}>
              <Box sx={{ width: '100%' }}>
                <Button
                  type="button"
                  variant="text"
                  startIcon={<Icon icon={arrowIosBackFill} />}
                  sx={{ whiteSpace: 'nowrap', ...(isMobile && { width: '100%' }) }}
                  onClick={() => handleNavigationClick(SYSTEM_PAGE, HOME_PATH)}
                >
                  Continue Shopping
                </Button>
              </Box>
              <Box sx={{ textAlign: 'end', width: '100%' }}>
                <Button
                  type="button"
                  variant="contained"
                  endIcon={<Icon icon={shoppingCartOutline} />}
                  sx={{ whiteSpace: 'nowrap', ...(isMobile && { width: '100%' }) }}
                  onClick={() => handleNavigationClick(SYSTEM_PAGE, CHECKOUT_PATH)}
                >
                  Checkout
                </Button>
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <CardStyle
              sx={{ ...(isMobile && { padding: (theme) => theme.spacing(3) }), ...(!showBorder && { border: 0 }) }}
            >
              <CardHeader
                title={
                  <Typography variant="h6" sx={{ color: (theme) => theme.palette.primary.main }}>
                    Cart
                    {showItemsCount && (
                      <Typography
                        component="span"
                        sx={{ paddingLeft: (theme) => theme.spacing(1), color: 'text.secondary' }}
                      >
                        ({totalItems} items)
                      </Typography>
                    )}
                  </Typography>
                }
                subheader={
                  <Box
                    sx={{
                      mt: (theme) => theme.spacing(2)
                    }}
                  >
                    <Typography variant="body1">Sub Total</Typography>
                    <Typography variant="h5" sx={{ fontWeight: (theme) => theme.typography.fontWeightBold }}>
                      {fCurrency(cartTotal, currency)}
                    </Typography>
                  </Box>
                }
                sx={{
                  ...(isMobile && { padding: 0, pb: (theme) => theme.spacing(3) })
                }}
              />

              <CartProductList
                showNotes={showNotes}
                noteTitle={noteTitle}
                showInventoryQuantity={showInventoryQuantity}
                buttonViewType={buttonViewType}
                products={products}
                currency={currency}
                handleNavigationClick={handleNavigationClick}
                {...other}
              />
            </CardStyle>
          </Grid>
          {showPaymentMessages && (
            <Grid item xs={12}>
              <Stack spacing={2} direction="column">
                {messagingPlacements}
              </Stack>
            </Grid>
          )}
        </Grid>
      ) : (
        <EmptyContent
          title={isInvalid(messaging?.title) ? DEFAULT_CART_EMPTY_TITLE : messaging?.title}
          description={isInvalid(messaging?.description) ? DEFAULT_CART_EMPTY_DESCRIPTION : messaging?.description}
          buttonText={isInvalid(messaging?.buttonText) ? DEFAULT_CART_EMPTY_BUTTON_TEXT : messaging?.buttonText}
          onButtonClick={() => handleNavigationClick(SYSTEM_PAGE, HOME_PATH)}
        />
      )}
    </>
  );
};
