import { DEFAULT_PALETTE } from '@iamilyas/store-template-library';
import * as Yup from 'yup';
import { styled } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack5';
import { Form, FormikProvider, useFormik } from 'formik';
import {
  Box,
  Typography,
  Stack,
  Divider,
  Button,
  AccordionDetails,
  Accordion,
  AccordionSummary
} from '@material-ui/core';
import { Icon } from '@iconify/react';
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
import { defaultTo } from 'lodash';
import Scrollbar from 'src/components/Scrollbar';
import ColourPicker from '../../components/pickers/ColourPicker';

const EditorConatiner = styled(Box)(({ theme }) => ({
  height: '100%',
  padding: theme.spacing(5)
}));

const AccordionStyle = styled(Accordion)(({ theme }) => ({
  '&.MuiAccordion-root': {
    boxShadow: 'none',
    backgroundColor: 'transparent',
    padding: theme.spacing(0, 2),
    width: '100%',
    borderRadius: theme.shape.borderRadius,
    border: `solid 1px ${theme.palette.divider}`,
    '&:before': {
      backgroundColor: 'unset'
    }
  }
}));

const AccordionSummaryStyle = styled(AccordionSummary)(() => ({
  padding: 0
}));

const AccordionDetailsStyle = styled(AccordionDetails)(({ theme }) => ({
  padding: theme.spacing(1, 0)
}));

const AccordionWrapper = ({ title, children, ...other }) => {
  return (
    <AccordionStyle defaultExpanded={false} key={title} {...other}>
      <AccordionSummaryStyle expandIcon={<Icon icon={arrowIosDownwardFill} width={20} height={20} color="inherit" />}>
        <Typography variant="body2">
          <strong>{title}</strong>
        </Typography>
      </AccordionSummaryStyle>
      <AccordionDetailsStyle>
        <Stack spacing={2} divider={<Divider />}>
          {children}
        </Stack>
      </AccordionDetailsStyle>
    </AccordionStyle>
  );
};

export default function ThemeColoursEditor({ config, heading, closeEdit, handleUpdateComponent }) {
  const { enqueueSnackbar } = useSnackbar();

  const ThemeColoursSchema = Yup.object().shape({
    blackColour: Yup.string().nullable().optional(),
    whiteColour: Yup.string().nullable().optional(),
    primaryColour: Yup.string().nullable().optional(),
    primaryContrastColour: Yup.string().nullable().optional(),
    secondaryColour: Yup.string().nullable().optional(),
    secondaryContrastColour: Yup.string().nullable().optional(),
    infoColour: Yup.string().nullable().optional(),
    infoContrastColour: Yup.string().nullable().optional(),
    errorColour: Yup.string().nullable().optional(),
    errorContrastColour: Yup.string().nullable().optional(),
    textPrimaryColour: Yup.string().nullable().optional(),
    textSecondaryColour: Yup.string().nullable().optional(),
    textDisabledColour: Yup.string().nullable().optional(),
    backgroundCoreColour: Yup.string().nullable().optional(),
    backgroundNeutralColour: Yup.string().nullable().optional(),
    backgroundNeutralContrastColour: Yup.string().nullable().optional(),
    dividerColour: Yup.string().nullable().optional(),
    borderColour: Yup.string().nullable().optional(),
    skeletonColour: Yup.string().nullable().optional()
  });

  const form = useFormik({
    initialValues: {
      blackColour: defaultTo(config?.blackColour, DEFAULT_PALETTE.common.black),
      whiteColour: defaultTo(config?.whiteColour, DEFAULT_PALETTE.common.white),
      primaryColour: defaultTo(config?.primaryColour, DEFAULT_PALETTE.primary.main),
      primaryContrastColour: defaultTo(config?.primaryContrastColour, DEFAULT_PALETTE.primary.contrastText),
      secondaryColour: defaultTo(config?.secondaryColour, DEFAULT_PALETTE.secondary.main),
      secondaryContrastColour: defaultTo(config?.secondaryContrastColour, DEFAULT_PALETTE.secondary.contrastText),
      infoColour: defaultTo(config?.infoColour, DEFAULT_PALETTE.info.main),
      infoContrastColour: defaultTo(config?.infoContrastColour, DEFAULT_PALETTE.info.contrastText),
      errorColour: defaultTo(config?.errorColour, DEFAULT_PALETTE.error.main),
      errorContrastColour: defaultTo(config?.errorContrastColour, DEFAULT_PALETTE.error.contrastText),
      textPrimaryColour: defaultTo(config?.textPrimaryColour, DEFAULT_PALETTE.text.primary),
      textSecondaryColour: defaultTo(config?.textSecondaryColour, DEFAULT_PALETTE.text.secondary),
      textDisabledColour: defaultTo(config?.textDisabledColour, DEFAULT_PALETTE.text.disabled),
      backgroundCoreColour: defaultTo(config?.backgroundCoreColour, DEFAULT_PALETTE.background.paper),
      backgroundNeutralColour: defaultTo(config?.backgroundNeutralColour, DEFAULT_PALETTE.background.neutral),
      backgroundNeutralContrastColour: defaultTo(
        config?.backgroundNeutralContrastColour,
        DEFAULT_PALETTE.background.neutralContrast
      ),
      dividerColour: defaultTo(config?.dividerColour, DEFAULT_PALETTE.divider),
      borderColour: defaultTo(config?.borderColour, DEFAULT_PALETTE.border),
      skeletonColour: defaultTo(config?.skeletonColour, DEFAULT_PALETTE.skeleton)
    },
    validationSchema: ThemeColoursSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        handleUpdateComponent(values);
        enqueueSnackbar('Saved', { variant: 'success' });
        setSubmitting(false);
      } catch (error) {
        console.error(error);
        setSubmitting(false);
      }
    }
  });

  const { initialValues, values, resetForm, handleSubmit, setFieldValue } = form;

  return (
    <Scrollbar>
      <FormikProvider value={form}>
        <Form
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit}
          style={{
            height: '100%'
          }}
        >
          <EditorConatiner>
            <Stack spacing={3} sx={{ height: '100%' }}>
              <Stack spacing={2} justifyContent="flex-end" direction="row">
                <Button variant="outlined" color="primary" onClick={resetForm} disabled={initialValues === values}>
                  Reset
                </Button>
                <Box sx={{ flexGrow: 1 }} />
                <Button variant="outlined" color="primary" onClick={closeEdit}>
                  Cancel
                </Button>
                <Button variant="contained" color="primary" type="submit">
                  Save
                </Button>
              </Stack>
              <Typography variant="h5">{heading}</Typography>

              <Divider />

              <Stack spacing={2} sx={{ pb: (theme) => theme.spacing(8) }}>
                <AccordionWrapper title="Primary">
                  <div>
                    <ColourPicker
                      title="Primary"
                      value={values.primaryColour}
                      handleChangeColour={(value) => setFieldValue('primaryColour', value)}
                      handleClearColour={() => setFieldValue('primaryColour', null)}
                      sx={{ border: 0 }}
                    />
                  </div>
                  <div>
                    <ColourPicker
                      title="Contrast"
                      value={values.primaryContrastColour}
                      handleChangeColour={(value) => setFieldValue('primaryContrastColour', value)}
                      handleClearColour={() => setFieldValue('primaryContrastColour', null)}
                      sx={{ border: 0 }}
                    />
                  </div>
                </AccordionWrapper>
                <AccordionWrapper title="Secondary">
                  <div>
                    <ColourPicker
                      title="Secondary"
                      value={values.secondaryColour}
                      handleChangeColour={(value) => setFieldValue('secondaryColour', value)}
                      handleClearColour={() => setFieldValue('secondaryColour', null)}
                      sx={{ border: 0 }}
                    />
                  </div>
                  <div>
                    <ColourPicker
                      title="Contrast"
                      value={values.secondaryContrastColour}
                      handleChangeColour={(value) => setFieldValue('secondaryContrastColour', value)}
                      handleClearColour={() => setFieldValue('secondaryContrastColour', null)}
                      sx={{ border: 0 }}
                    />
                  </div>
                </AccordionWrapper>
                <AccordionWrapper title="Core">
                  <div>
                    <ColourPicker
                      title="Black"
                      value={values.blackColour}
                      handleChangeColour={(value) => setFieldValue('blackColour', value)}
                      handleClearColour={() => setFieldValue('blackColour', null)}
                      sx={{ border: 0 }}
                    />
                  </div>
                  <div>
                    <ColourPicker
                      title="White"
                      value={values.whiteColour}
                      handleChangeColour={(value) => setFieldValue('whiteColour', value)}
                      handleClearColour={() => setFieldValue('whiteColour', null)}
                      sx={{ border: 0 }}
                    />
                  </div>
                </AccordionWrapper>
                <AccordionWrapper title="Info">
                  <div>
                    <ColourPicker
                      title="Info"
                      value={values.infoColour}
                      handleChangeColour={(value) => setFieldValue('infoColour', value)}
                      handleClearColour={() => setFieldValue('infoColour', null)}
                      sx={{ border: 0 }}
                    />
                  </div>
                  <div>
                    <ColourPicker
                      title="Contrast"
                      value={values.infoContrastColour}
                      handleChangeColour={(value) => setFieldValue('infoContrastColour', value)}
                      handleClearColour={() => setFieldValue('infoContrastColour', null)}
                      sx={{ border: 0 }}
                    />
                  </div>
                </AccordionWrapper>
                <AccordionWrapper title="Error">
                  <div>
                    <ColourPicker
                      title="Error"
                      value={values.errorColour}
                      handleChangeColour={(value) => setFieldValue('errorColour', value)}
                      handleClearColour={() => setFieldValue('errorColour', null)}
                      sx={{ border: 0 }}
                    />
                  </div>
                  <div>
                    <ColourPicker
                      title="Contrast"
                      value={values.errorContrastColour}
                      handleChangeColour={(value) => setFieldValue('errorContrastColour', value)}
                      handleClearColour={() => setFieldValue('errorContrastColour', null)}
                      sx={{ border: 0 }}
                    />
                  </div>
                </AccordionWrapper>
                <AccordionWrapper title="Text">
                  <div>
                    <ColourPicker
                      title="Primary Text"
                      value={values.textPrimaryColour}
                      handleChangeColour={(value) => setFieldValue('textPrimaryColour', value)}
                      handleClearColour={() => setFieldValue('textPrimaryColour', null)}
                      sx={{ border: 0 }}
                    />
                  </div>
                  <div>
                    <ColourPicker
                      title="Secondary Text"
                      value={values.textSecondaryColour}
                      handleChangeColour={(value) => setFieldValue('textSecondaryColour', value)}
                      handleClearColour={() => setFieldValue('textSecondaryColour', null)}
                      sx={{ border: 0 }}
                    />
                  </div>
                  <div>
                    <ColourPicker
                      title="Disabled Text"
                      value={values.textDisabledColour}
                      handleChangeColour={(value) => setFieldValue('textDisabledColour', value)}
                      handleClearColour={() => setFieldValue('textDisabledColour', null)}
                      sx={{ border: 0 }}
                    />
                  </div>
                </AccordionWrapper>
                <AccordionWrapper title="Background">
                  <div>
                    <ColourPicker
                      title="Core Background"
                      value={values.backgroundCoreColour}
                      handleChangeColour={(value) => setFieldValue('backgroundCoreColour', value)}
                      handleClearColour={() => setFieldValue('backgroundCoreColour', null)}
                      sx={{ border: 0 }}
                    />
                  </div>
                  <div>
                    <ColourPicker
                      title="Neutral Background"
                      value={values.backgroundNeutralColour}
                      handleChangeColour={(value) => setFieldValue('backgroundNeutralColour', value)}
                      handleClearColour={() => setFieldValue('backgroundNeutralColour', null)}
                      sx={{ border: 0 }}
                    />
                  </div>
                  <div>
                    <ColourPicker
                      title="Neutral Background Contrast"
                      value={values.backgroundNeutralContrastColour}
                      handleChangeColour={(value) => setFieldValue('backgroundNeutralContrastColour', value)}
                      handleClearColour={() => setFieldValue('backgroundNeutralContrastColour', null)}
                      sx={{ border: 0 }}
                    />
                  </div>
                </AccordionWrapper>
                <AccordionWrapper title="Components">
                  <div>
                    <ColourPicker
                      title="Divider"
                      value={values.dividerColour}
                      handleChangeColour={(value) => setFieldValue('dividerColour', value)}
                      handleClearColour={() => setFieldValue('dividerColour', null)}
                      sx={{ border: 0 }}
                    />
                  </div>
                  <div>
                    <ColourPicker
                      title="Border"
                      value={values.borderColour}
                      handleChangeColour={(value) => setFieldValue('borderColour', value)}
                      handleClearColour={() => setFieldValue('borderColour', null)}
                      sx={{ border: 0 }}
                    />
                  </div>
                  <div>
                    <ColourPicker
                      title="Loading Placeholder"
                      value={values.skeletonColour}
                      handleChangeColour={(value) => setFieldValue('skeletonColour', value)}
                      handleClearColour={() => setFieldValue('skeletonColour', null)}
                      sx={{ border: 0 }}
                    />
                  </div>
                </AccordionWrapper>
              </Stack>
            </Stack>
          </EditorConatiner>
        </Form>
      </FormikProvider>
    </Scrollbar>
  );
}
