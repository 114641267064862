// material
import React from 'react';
import { Stack, Box, Divider, Drawer, IconButton, List, ListItem, ListItemButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { isEmpty } from 'lodash';
import { DRAWER_WIDTH } from '../../../utils/constants';
import { createTabValue } from '../../../utils/pageTypes';
import { NavigationText } from './HeaderText';
import { StoreCurrencyPicker } from '../StoreCurrencyPicker';

const HeaderMobileDrawer = ({
  isPreview,
  containerRef,
  currency,
  currencies,
  currenciesLoading,
  capability,
  currencyConfig,
  currentTabValue,
  appearance,
  navigation,
  open,
  mobileBackgroundColour: backgroundColour,
  mobileTextColour: textColour,
  isCurrencyTopView,
  handleNavigationClick,
  handleDrawerToggle,
  handleChangeCurrency
}) => {
  const { currency: currencyCapability } = capability;

  const StoreCurrencyPickerView = currencyCapability && (
    <Box sx={{ width: '100%' }}>
      <StoreCurrencyPicker
        fullWidth
        capability
        currency={currency}
        options={currencies}
        loading={currenciesLoading}
        primaryColour={textColour}
        secondaryColour={textColour}
        config={{
          ...currencyConfig?.sidebar,
          input: appearance?.input
        }}
        onChangeCurrency={handleChangeCurrency}
      />
    </Box>
  );

  return (
    <Drawer
      container={() => containerRef.current}
      disableAutoFocus
      disableEnforceFocus
      variant="temporary"
      open={open}
      onClose={handleDrawerToggle}
      PaperProps={{
        style: {
          position: 'absolute',
          ...(!isEmpty(backgroundColour) && { backgroundColor: backgroundColour })
        }
      }}
      BackdropProps={{ style: { position: 'absolute' } }}
      ModalProps={{
        style: { position: 'absolute', zIndex: 1099 },
        keepMounted: true
      }}
      sx={{
        '& .MuiDrawer-paper': {
          position: 'relative',
          boxSizing: 'border-box',
          width: DRAWER_WIDTH
        }
      }}
    >
      <Stack
        direction="column"
        spacing={2}
        divider={<Divider />}
        sx={{ ...(!isPreview && { position: 'fixed', width: DRAWER_WIDTH }), p: (theme) => theme.spacing(3) }}
      >
        <Box>
          <IconButton sx={{ color: textColour }} onClick={handleDrawerToggle}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Box>
          {isCurrencyTopView && StoreCurrencyPickerView}

          {navigation.data && (
            <List>
              {navigation.data?.links.map((link, index) => {
                const {
                  name,
                  navigation: { type, path }
                } = link;
                const tabValue = createTabValue(type, path);
                const isSelected = currentTabValue === tabValue;

                return (
                  <ListItem key={index} disablePadding>
                    <ListItemButton
                      onClick={() => {
                        handleDrawerToggle();
                        handleNavigationClick(type, path);
                      }}
                    >
                      <NavigationText textColour={textColour} isActive={isSelected}>
                        {name}
                      </NavigationText>
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          )}
        </Box>

        {!isCurrencyTopView && StoreCurrencyPickerView}
      </Stack>
    </Drawer>
  );
};

export default HeaderMobileDrawer;
