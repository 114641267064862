// material
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'src/redux/store';
import useEditor from 'src/hooks/useEditor';
import { Stack } from '@material-ui/core';
import {
  updateGlobalComponent,
  updateGlobalColoursComponent,
  updateGlobalMessagesComponent
} from 'src/redux/slices/store';
import colourLens from '@iconify/icons-mdi/colour-lens';
import baselineShapeLine from '@iconify/icons-ic/baseline-shape-line';
import squareShadow12Regular from '@iconify/icons-fluent/square-shadow-12-regular';
import calanderView from '@iconify/icons-bxs/calendar';
import roundGridView from '@iconify/icons-ic/round-grid-view';
import borderRadius from '@iconify/icons-bx/border-radius';
import roundTextFields from '@iconify/icons-ic/round-text-fields';
import messageTextOutline from '@iconify/icons-teenyicons/message-text-outline';
import roundShoppingBasket from '@iconify/icons-ic/round-shopping-basket';
import shoppingCartFill from '@iconify/icons-eva/shopping-cart-fill';
import outlineLocalShipping from '@iconify/icons-ic/outline-local-shipping';
import layerGroup from '@iconify/icons-fa-solid/layer-group';
import ProductColoursEditor from './form-components/ProductColoursEditor';
import MultiViewRowComponent from '../components/MultiViewRowComponent';
import { GenericEditorLayout } from '../components/GenericEditorLayout';
import ThemeColoursEditor from './form-components/ThemeColoursEditor';
import BordersShapeEditor from './form-components/GeneralShapesEditor';
import TextFontEditor from './form-components/TextFontEditor';
import ShadowColoursEditor from './form-components/ShadowColoursEditor';
import AppearanceShapeEditor from './form-components/AppearanceShapesEditor';
import ProductsMessagesEditor from './form-components/messages/ProductsMessagesEditor';
import CollectionsMessagesEditor from './form-components/messages/CollectionsMessagesEditor';
import CartMessagesEditor from './form-components/messages/CartMessagesEditor';
import CheckoutMessagesEditor from './form-components/messages/CheckoutMessagesEditor';
import BookingMessagesEditor from './form-components/messages/BookingMessagesEditor';

// ------------------------------------------------------------------ View Components

const ColoursRow = ({
  config,
  handleUpdateThemeColours,
  handleUpdateProductColours,
  handleUpdateShadowColours,
  ...other
}) => {
  return (
    <MultiViewRowComponent
      componentName="Colours"
      title="Colours"
      icon={colourLens}
      rows={[
        {
          name: 'Colours',
          component: (
            <ThemeColoursEditor
              heading="Colours"
              config={config?.palette}
              handleUpdateComponent={handleUpdateThemeColours}
              {...other}
            />
          ),
          icon: colourLens
        },
        {
          name: 'Product',
          component: (
            <ProductColoursEditor
              heading="Product"
              config={config?.colours?.product}
              handleUpdateComponent={handleUpdateProductColours}
              {...other}
            />
          ),
          icon: roundShoppingBasket
        },
        {
          name: 'Shadows',
          component: (
            <ShadowColoursEditor
              heading="Shadows"
              config={config?.colours?.shadows}
              handleUpdateComponent={handleUpdateShadowColours}
              {...other}
            />
          ),
          icon: squareShadow12Regular
        }
      ]}
      {...other}
    />
  );
};

const ShapeRow = ({ config, handleUpdateGlobalShape, handleUpdateGlobalAppearance, ...other }) => {
  return (
    <MultiViewRowComponent
      componentName="Shapes"
      title="Shapes"
      icon={baselineShapeLine}
      rows={[
        {
          name: 'Borders',
          component: (
            <BordersShapeEditor
              config={config.shape}
              heading="Borders"
              handleUpdateComponent={handleUpdateGlobalShape}
              {...other}
            />
          ),
          icon: borderRadius
        },
        {
          name: 'Appearance',
          component: (
            <AppearanceShapeEditor
              heading="Appearance"
              config={config.appearance}
              handleUpdateComponent={handleUpdateGlobalAppearance}
              {...other}
            />
          ),
          icon: roundGridView
        }
      ]}
      {...other}
    />
  );
};

const TextRow = ({ ...other }) => {
  return (
    <MultiViewRowComponent
      componentName="Text"
      title="Text"
      icon={roundTextFields}
      rows={[
        {
          name: 'Font',
          component: <TextFontEditor heading="Font" {...other} />,
          icon: roundTextFields
        }
      ]}
      {...other}
    />
  );
};

const MessagesRow = ({ config, handleUpdateComponent, ...other }) => {
  return (
    <MultiViewRowComponent
      componentName="Messages"
      title="Messages"
      icon={messageTextOutline}
      rows={[
        {
          name: 'Products',
          component: (
            <ProductsMessagesEditor
              heading="Products Messages"
              config={config?.products}
              handleUpdateComponent={(values) => handleUpdateComponent('products', values)}
              {...other}
            />
          ),
          icon: roundShoppingBasket
        },
        {
          name: 'Collections',
          component: (
            <CollectionsMessagesEditor
              heading="Collections Messages"
              config={config?.collections}
              handleUpdateComponent={(values) => handleUpdateComponent('collections', values)}
              {...other}
            />
          ),
          icon: layerGroup
        },
        {
          name: 'Cart',
          component: (
            <CartMessagesEditor
              heading="Cart Messages"
              config={config?.cart}
              handleUpdateComponent={(values) => handleUpdateComponent('cart', values)}
              {...other}
            />
          ),
          icon: shoppingCartFill
        },
        {
          name: 'Booking',
          component: (
            <BookingMessagesEditor
              heading="Booking Messages"
              config={config?.booking}
              handleUpdateComponent={(values) => handleUpdateComponent('booking', values)}
              {...other}
            />
          ),
          icon: calanderView
        },
        {
          name: 'Checkout',
          component: (
            <CheckoutMessagesEditor
              heading="Checkout Messages"
              config={config?.checkout}
              handleUpdateComponent={(values) => handleUpdateComponent('checkout', values)}
              {...other}
            />
          ),
          icon: outlineLocalShipping
        }
      ]}
      {...other}
    />
  );
};

// ------------------------------------------------------------------ Main

export default function ThemePageSidebar() {
  const dispatch = useDispatch();
  const {
    editor: { page, theme }
  } = useSelector((state) => state.store);
  const config = theme?.global;

  const { editDrawer, onUpdateEditorDrawer } = useEditor();

  const handleOpenDrawer = useCallback(
    (editComponent) => onUpdateEditorDrawer({ open: true, component: editComponent }),
    [onUpdateEditorDrawer]
  );

  const handleCloseDrawer = useCallback(
    () => onUpdateEditorDrawer({ open: false, component: null }),
    [onUpdateEditorDrawer]
  );

  const handleUpdateThemeColours = useCallback(
    (values) => {
      dispatch(updateGlobalComponent({ key: 'palette', values }));
    },
    [dispatch]
  );

  const handleUpdateShadowColours = useCallback(
    (values) => {
      dispatch(updateGlobalColoursComponent({ key: 'shadows', values }));
    },
    [dispatch]
  );

  const handleUpdateProductColours = useCallback(
    (values) => {
      dispatch(updateGlobalColoursComponent({ key: 'product', values }));
    },
    [dispatch]
  );

  const handleUpdateGlobalShape = useCallback(
    (values) => {
      dispatch(updateGlobalComponent({ key: 'shape', values }));
    },
    [dispatch]
  );

  const handleUpdateGlobalAppearance = useCallback(
    (values) => {
      dispatch(updateGlobalComponent({ key: 'appearance', values }));
    },
    [dispatch]
  );

  const handleUpdateGlobalTypography = useCallback(
    (values) => {
      dispatch(updateGlobalComponent({ key: 'typography', values }));
    },
    [dispatch]
  );

  const handleUpdateGlobalMessages = useCallback(
    (key, values) => {
      dispatch(updateGlobalMessagesComponent({ key, values }));
    },
    [dispatch]
  );

  const ComponentList = useMemo(() => {
    const common = {
      openEdit: handleOpenDrawer,
      closeEdit: handleCloseDrawer
    };
    return (
      config && (
        <Stack direction="column" spacing={1} sx={{ p: 2 }}>
          <ColoursRow
            config={config.theme}
            handleUpdateThemeColours={handleUpdateThemeColours}
            handleUpdateProductColours={handleUpdateProductColours}
            handleUpdateShadowColours={handleUpdateShadowColours}
            {...common}
          />
          <ShapeRow
            config={config.theme}
            handleUpdateGlobalShape={handleUpdateGlobalShape}
            handleUpdateGlobalAppearance={handleUpdateGlobalAppearance}
            {...common}
          />
          <TextRow config={config.theme.typography} handleUpdateComponent={handleUpdateGlobalTypography} {...common} />
          <MessagesRow config={config.messages} handleUpdateComponent={handleUpdateGlobalMessages} {...common} />
        </Stack>
      )
    );
  }, [
    config,
    handleOpenDrawer,
    handleCloseDrawer,
    handleUpdateThemeColours,
    handleUpdateProductColours,
    handleUpdateShadowColours,
    handleUpdateGlobalShape,
    handleUpdateGlobalAppearance,
    handleUpdateGlobalTypography,
    handleUpdateGlobalMessages
  ]);

  return (
    <GenericEditorLayout page={page} editDrawer={editDrawer} handleCloseDrawer={handleCloseDrawer}>
      {ComponentList}
    </GenericEditorLayout>
  );
}
