import React from 'react';
import { compact, defaultTo, filter, isEmpty, reduce, sum } from 'lodash';
import { Box, Button, Divider, Stack, Typography } from '@material-ui/core';
import {
  Timeline,
  TimelineDot,
  TimelineContent,
  TimelineSeparator,
  TimelineConnector,
  TimelineItem,
  LoadingButton
} from '@material-ui/lab';
import { alpha, styled } from '@material-ui/core/styles';
import { fCurrency, fCurrencyCode } from '../../utils/formatNumber';
import { BookingTextBlock } from './BookingTextBlock';
import { isZero } from '../../utils/money';
import { fDateLong, fTime } from '../../utils/formatTime';
import { SkeletonBookingSummary } from '../core/SkeletonBookingSummary';
import EmptyContent from '../core/EmptyContent';

const BookingPanelRootStyle = styled(Stack)(({ theme }) => ({
  boxShadow: 'none',
  borderRadius: theme.shape.borderRadius,
  border: `solid 1px ${alpha(theme.palette.border, 0.32)}`
}));

const BookingItemRootStyle = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(2)
}));

const TimelineDotStyle = styled(TimelineDot)(({ theme }) => ({
  borderColor: theme.palette.divider
}));

const TimelineItemStyle = styled(TimelineItem)(() => ({
  minHeight: 'unset',
  '&.MuiTimelineItem-root': {
    '&::before': {
      flex: 0,
      padding: 0
    }
  }
}));

const EmptyBookingServicesItem = ({ title }) => (
  <BookingItemRootStyle>
    <Typography variant="body2">{title}</Typography>
  </BookingItemRootStyle>
);

const BookingLocationItem = ({ loading, location }) => {
  if (loading) {
    return (
      <Box sx={{ p: 2 }}>
        <SkeletonBookingSummary />
      </Box>
    );
  }
  if (!location) {
    return <EmptyBookingServicesItem title="Please choose a location" />;
  }
  const { name, address, number } = location;
  return (
    <BookingItemRootStyle spacing={1}>
      <Typography variant="overline" color="text.secondary">
        LOCATION
      </Typography>
      <BookingTextBlock title={name} description={address} supportText={[number]} />
    </BookingItemRootStyle>
  );
};

const BookingSlotItem = ({ loading, slot }) => {
  if (loading) {
    return (
      <Box sx={{ p: 2 }}>
        <SkeletonBookingSummary />
      </Box>
    );
  }
  const { from } = slot;
  return (
    <BookingItemRootStyle spacing={1}>
      <Typography variant="overline" color="text.secondary">
        ARRIVAL
      </Typography>
      <Box>
        <Typography variant="h6">{from ? fDateLong(from) : '-'}</Typography>
        {from && (
          <Typography variant="body2" sx={{ fontSize: (theme) => theme.typography.pxToRem(18) }}>
            {fTime(from)}
          </Typography>
        )}
      </Box>
    </BookingItemRootStyle>
  );
};

const BookingServicesTimeline = ({ loading, currency, services }) => {
  if (loading) {
    return (
      <Box sx={{ p: 2 }}>
        <SkeletonBookingSummary />
      </Box>
    );
  }
  if (isEmpty(services)) {
    return <EmptyBookingServicesItem title="No services added" />;
  }
  const noServicesWithDuration = isEmpty(compact(services.map((o) => o.duration)));
  const totalDuration = reduce(
    defaultTo(services, []).map((o) => defaultTo(o.duration, 0)),
    (a, n) => sum([a, n])
  );
  const showConnector = services.length > 1;
  return (
    <Box>
      <Timeline position="right" sx={{ p: 2 }}>
        <Typography variant="overline" color="text.secondary" sx={{ mb: 1 }}>
          {`${services.length} SERVICES`}
        </Typography>
        {services.map((service, index) => {
          const isLastItem = services.length - 1 === index;
          const { symbol } = currency;
          const { id, name, price, duration: durationInMins } = service;
          const supportText = [];
          if (price) {
            const formattedPrice = fCurrency(price?.amount, symbol);
            supportText.push(formattedPrice);
          }
          if (durationInMins) {
            supportText.push(`${durationInMins} min`);
          }
          return (
            <TimelineItemStyle key={id}>
              {showConnector && (
                <TimelineSeparator>
                  <TimelineDotStyle variant="outlined" />
                  {!isLastItem && <TimelineConnector />}
                </TimelineSeparator>
              )}

              <TimelineContent sx={{ py: 0, ...(!showConnector && { px: 0 }), ...(!isLastItem && { mb: 3 }) }}>
                <BookingItemRootStyle sx={{ p: 0 }}>
                  <BookingTextBlock title={name} supportText={supportText} sx={{ px: 0, pt: 0 }} spacing={0} />
                </BookingItemRootStyle>
              </TimelineContent>
            </TimelineItemStyle>
          );
        })}
        {!!totalDuration && !isZero(totalDuration) && !noServicesWithDuration && (
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ mt: 2 }}
          >{`Total duration: ${totalDuration} min`}</Typography>
        )}
      </Timeline>
    </Box>
  );
};

const BookingSummaryError = () => (
  <EmptyContent
    title="Something Went Wrong"
    description="At this moment we cannot load your booking summary"
    fontSx={{
      fontSize: (theme) => `${theme.typography.pxToRem(14)} !important`
    }}
    sx={{ minHeight: 'unset' }}
  />
);

const BookingSummaryItem = ({ title, value }) => {
  return (
    <Stack spacing={1} direction="row" justifyContent="space-between">
      <Typography variant="body2" color="text.secondary">
        {title}
      </Typography>
      <Typography variant="body1">
        <strong>{value}</strong>
      </Typography>
    </Stack>
  );
};

const BookingSummary = ({ loading, summary: data, hasError }) => {
  if (loading) {
    return (
      <Box sx={{ p: 2 }}>
        <SkeletonBookingSummary />
      </Box>
    );
  }

  const invoice = data?.invoice;
  const services = invoice?.items;
  const currencyCode = invoice?.currency?.code;
  const currencySymbol = invoice?.currency?.symbol;
  if (hasError || !invoice || !services) {
    return <BookingSummaryError />;
  }
  const noServicesWithPrice = isEmpty(compact(services.map((o) => o.total)));
  if (noServicesWithPrice) {
    return null;
  }
  const someServicesWithoutPrice = !isEmpty(filter(services, (o) => !o.total));
  const subTotal = invoice?.subTotal?.amount;
  const tax = invoice?.tax?.amount;
  const total = invoice.total?.amount;

  return (
    <Stack divider={<Divider orientation="horizontal" />}>
      <BookingItemRootStyle spacing={0.5}>
        {subTotal && <BookingSummaryItem title="Sub Total" value={fCurrency(subTotal, currencySymbol)} />}
        {tax && <BookingSummaryItem title="Tax" value={fCurrency(tax, currencySymbol)} />}
        {total && <BookingSummaryItem title="Total" value={fCurrency(total, currencySymbol)} />}
      </BookingItemRootStyle>
      <BookingItemRootStyle>
        <Stack spacing={1} direction="row" justifyContent="space-between">
          <Typography variant="body1" color="text.secondary">
            Due Today
          </Typography>
          <Typography variant="h6">{fCurrencyCode('0.00', currencyCode)}</Typography>
        </Stack>
        {someServicesWithoutPrice && (
          <Typography variant="body2" color="text.secondary" textAlign="end">
            Prices may vary
          </Typography>
        )}
      </BookingItemRootStyle>
    </Stack>
  );
};

const BookingPanelView = ({
  currency,
  isLoading,
  hasError,
  showSummary,
  summary,
  location: locationRoot,
  services: servicesRoot,
  slot: slotRoot
}) => {
  const hasSummary = !!summary && !hasError;
  const summaryServices = defaultTo(summary?.invoice?.items, []).map((service) => ({
    id: service.id,
    name: service.name,
    price: service.total,
    duration: service.duration
  }));
  const services = hasSummary ? summaryServices : servicesRoot;
  const location = hasSummary ? summary?.location : locationRoot;
  const slot = hasSummary ? summary?.slot : slotRoot;
  return (
    <BookingPanelRootStyle divider={<Divider orientation="horizontal" />}>
      <BookingLocationItem loading={isLoading} location={location} />
      <BookingServicesTimeline loading={isLoading} currency={currency} services={services} />
      {slot && <BookingSlotItem loading={isLoading} slot={slot} />}
      {showSummary && <BookingSummary loading={isLoading} summary={summary} hasError={hasError} />}
    </BookingPanelRootStyle>
  );
};

export const BookingPreviewPanel = ({
  isMobile,
  isLastStep,
  isLoading,
  isComplete,
  isSummaryLoading,
  hasError,
  summary,
  shouldDisableNext,
  shouldDisablePrevious,
  handleNextPage,
  handlePreviousPage,
  ...others
}) => {
  return (
    <Stack spacing={2} sx={{ ...(isMobile && { p: (theme) => theme.spacing(0, 2) }) }}>
      <Typography variant="h6">Booking Summary</Typography>
      <BookingPanelView
        showSummary={isLastStep}
        summary={summary}
        isLoading={isSummaryLoading}
        hasError={hasError}
        {...others}
      />
      {!isComplete && (
        <>
          <LoadingButton
            loading={isLoading || isSummaryLoading}
            disabled={shouldDisableNext()}
            color="primary"
            variant="contained"
            onClick={handleNextPage}
          >
            {isLastStep ? 'Complete Booking' : 'Next'}
          </LoadingButton>
          <Button
            disabled={isLoading || isSummaryLoading || shouldDisablePrevious()}
            color="primary"
            variant="outlined"
            onClick={handlePreviousPage}
          >
            Back
          </Button>
        </>
      )}
    </Stack>
  );
};
