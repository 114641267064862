import { styled } from '@material-ui/core/styles';
import { useState } from 'react';
import { Icon } from '@iconify/react';
import linkChain from '@iconify/icons-akar-icons/link-chain';
import { Box, Typography, Stack, Button } from '@material-ui/core';

import { defaultTo, isEmpty } from 'lodash';
import InfoBlock from 'src/components/_admin_v2/InfoBlock';
import SelectNavigationDialog from './SelectNavigationDialog';

const IconContainer = styled(Box)(({ theme }) => ({
  width: 64,
  height: 64,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.grey[200],
  marginRight: theme.spacing(2),
  borderRadius: theme.shape.borderRadiusCircle
}));

export const SelectNavigation = ({ values, options, handleNavigationSelected }) => {
  const navigationOption = options.find((_navigation) => _navigation.id === values.navigationLinkId);
  const name = defaultTo(navigationOption?.name, '');
  const [open, setOpen] = useState(false);

  const handleCloseChooseNavigation = () => {
    setOpen(false);
  };

  const handleChooseNavigation = () => {
    setOpen(true);
  };

  const onOptionSelected = (option) => {
    handleCloseChooseNavigation();
    handleNavigationSelected(option);
  };

  return (
    <>
      <SelectNavigationDialog
        open={open}
        value={values.navigationLinkId}
        options={options}
        onClose={handleCloseChooseNavigation}
        handleSelected={onOptionSelected}
      />
      <Box sx={{ p: (theme) => theme.spacing(3), border: (theme) => `solid 1px ${theme.palette.divider}` }}>
        <Stack direction="row" spacing={1}>
          <div>
            <IconContainer>
              <Icon icon={linkChain} width={20} height={20} />
            </IconContainer>
          </div>

          <Stack spacing={2} sx={{ width: '100%' }}>
            <InfoBlock
              caption="Selected"
              titleObj={
                <Typography variant="body2">
                  <strong>{isEmpty(name) ? 'No Navigation Selected' : name}</strong>
                </Typography>
              }
            />
            <Button fullWidth variant="contained" size="small" onClick={handleChooseNavigation}>
              Choose Navigation
            </Button>
          </Stack>
        </Stack>
      </Box>
    </>
  );
};
