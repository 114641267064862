import { useSnackbar } from 'notistack5';
import { Form, FormikProvider, useFormik } from 'formik';
import { Typography, Stack, Button } from '@material-ui/core';
import { defaultTo } from 'lodash';
import { GENERIC_CONTENT_POSITIONS, NAVIGATION_VIEW_TYPE } from '../../../utils/constantsEditor';
import HeaderOrientationEditorCommon from './HeaderOrientationEditorCommon';
import { EditorConatiner } from '../../../common/EditorConatiner';
import { getEnabledLogoPosition } from '../common/headerUtils';

export default function HeaderOrientationEditor({ heading, config, closeEdit, handleUpdateComponent }) {
  const { enqueueSnackbar } = useSnackbar();

  const showLogo = config?.showLogo;
  const enableNavigation = config?.enableNavigation;
  const logoPosition = config?.logoPosition;
  const logoPositionMobile = config?.logoPositionMobile;
  const navigationPosition = config?.navigationPosition;
  const viewType = config?.viewType;

  const form = useFormik({
    initialValues: {
      navigationPosition: defaultTo(navigationPosition, GENERIC_CONTENT_POSITIONS[0].value),
      logoPosition: defaultTo(getEnabledLogoPosition(showLogo, logoPosition), GENERIC_CONTENT_POSITIONS[0].value),
      logoPositionMobile: defaultTo(logoPositionMobile, GENERIC_CONTENT_POSITIONS[0].value),
      viewType: defaultTo(viewType, NAVIGATION_VIEW_TYPE[0])
    },
    onSubmit: async (values, { setSubmitting }) => {
      try {
        handleUpdateComponent(values);
        enqueueSnackbar('Saved', { variant: 'success' });
        setSubmitting(false);
      } catch (error) {
        console.error(error);
        setSubmitting(false);
      }
    }
  });

  const { handleSubmit } = form;

  return (
    <FormikProvider value={form}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <EditorConatiner>
          <Stack spacing={3}>
            <Stack spacing={2} justifyContent="flex-end" direction="row">
              <Button variant="outlined" color="primary" onClick={closeEdit}>
                Cancel
              </Button>
              <Button variant="contained" color="primary" type="submit">
                Save
              </Button>
            </Stack>
            <Typography variant="h5">{heading}</Typography>

            <HeaderOrientationEditorCommon form={form} showLogo={showLogo} enableNavigation={enableNavigation} />
          </Stack>
        </EditorConatiner>
      </Form>
    </FormikProvider>
  );
}
