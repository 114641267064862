import PropTypes from 'prop-types';
import { Container } from '@material-ui/core';
import useAuth from 'src/hooks/useAuth';
import { defaultTo } from 'lodash';
import { paramCase } from 'change-case';
import { Outlet } from 'react-router';
import EmptyContent from 'src/components/EmptyContent';

// ----------------------------------------------------------------------

export default function RoleBasedGuard({ accessibleRoles }) {
  const { user } = useAuth();
  const currentRole = paramCase(defaultTo(user?.role, ''));

  if (!accessibleRoles.includes(currentRole)) {
    return (
      <Container>
        <EmptyContent
          title="Access Denied"
          description="You do not have permission to access this page"
          img="/static/illustrations/illustration_lock.svg"
        />
      </Container>
    );
  }

  return <Outlet />;
}

RoleBasedGuard.propTypes = {
  accessibleRoles: PropTypes.array // Example ['admin', 'user']
};
