import React, { useMemo } from 'react';
import { Stack } from '@material-ui/core';
import { defaultTo } from 'lodash';
import { styled } from '@material-ui/core/styles';
import useMobile from '../../hooks/useMobile';
import { isInvalidText } from '../../utils/nullable';
import { MOBILE_STACK_SPACING, DESKTOP_CONTAINER_TOP_BOTTOM_PADDING } from '../../utils/constants';
import { getHorizontalPosition } from '../../utils/getPositions';
import { SimpleMarkdownTextBlock } from '../core/SimpleTextBlock';

const Content = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(DESKTOP_CONTAINER_TOP_BOTTOM_PADDING)
}));

function SimpleBodyText({ component, handleNavigationClick }) {
  const isMobile = useMobile();
  const {
    title,
    subtitle,
    caption,
    body,
    showTitle: showHeading,
    titleColour,
    subTitleColour,
    captionColour,
    buttonText,
    buttonColour,
    buttonTextColour,
    backgroundColour,
    contentPosition: contentPositionDesktop,
    mobileContentPosition: contentPositionMobile,
    navigation
  } = component;
  const contentPosition = isMobile ? defaultTo(contentPositionMobile, contentPositionDesktop) : contentPositionDesktop;
  const SimpleBodyTextMemo = useMemo(() => {
    const stackSpacing = isMobile ? MOBILE_STACK_SPACING : 4;

    return (
      <Content
        spacing={stackSpacing}
        direction="column"
        alignItems="center"
        sx={{
          backgroundColor: (theme) =>
            isInvalidText(backgroundColour) ? theme.palette.background.paper : backgroundColour,
          ...(isMobile && {
            padding: (theme) => theme.spacing(3, 3, 8)
          })
        }}
      >
        <SimpleMarkdownTextBlock
          showHeading={showHeading}
          title={title}
          subtitle={subtitle}
          caption={caption}
          body={body}
          position={getHorizontalPosition(contentPosition)}
          titleColour={titleColour}
          subTitleColour={subTitleColour}
          captionColour={captionColour}
          buttonText={buttonText}
          buttonColour={buttonColour}
          buttonTextColour={buttonTextColour}
          onButtonClick={() => handleNavigationClick(navigation?.type, navigation?.path)}
        />
      </Content>
    );
  }, [
    isMobile,
    title,
    subtitle,
    caption,
    body,
    showHeading,
    titleColour,
    subTitleColour,
    captionColour,
    buttonText,
    buttonColour,
    buttonTextColour,
    backgroundColour,
    contentPosition,
    navigation,
    handleNavigationClick
  ]);

  return SimpleBodyTextMemo;
}

export default SimpleBodyText;
