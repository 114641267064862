import { DEFAULT_PRODUCT_COLOURS } from '@iamilyas/store-template-library';
import * as Yup from 'yup';
import { styled } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack5';
import { Form, FormikProvider, useFormik } from 'formik';
import { Box, Typography, Stack, Divider, Button, FormHelperText } from '@material-ui/core';
import { defaultTo } from 'lodash';
import ColourPicker from '../../components/pickers/ColourPicker';

const EditorConatiner = styled(Box)(({ theme }) => ({
  padding: theme.spacing(5)
}));

export default function ProductColoursEditor({ config, heading, closeEdit, handleUpdateComponent }) {
  const { enqueueSnackbar } = useSnackbar();

  const ProductColoursSchema = Yup.object().shape({
    tagBackgroundColour: Yup.string().nullable().optional(),
    tagTextColour: Yup.string().nullable().optional(),
    saleBackgroundColour: Yup.string().nullable().optional(),
    saleTextColour: Yup.string().nullable().optional(),
    outOfStockBackgroundColour: Yup.string().nullable().optional(),
    outOfStockTextColour: Yup.string().nullable().optional()
  });

  const form = useFormik({
    initialValues: {
      saleBackgroundColour: defaultTo(config?.saleBackgroundColour, DEFAULT_PRODUCT_COLOURS.saleBackgroundColour),
      saleTextColour: defaultTo(config?.saleTextColour, DEFAULT_PRODUCT_COLOURS.saleTextColour),
      tagBackgroundColour: defaultTo(config?.tagBackgroundColour, DEFAULT_PRODUCT_COLOURS.tagBackgroundColour),
      tagTextColour: defaultTo(config?.tagTextColour, DEFAULT_PRODUCT_COLOURS.tagTextColour),
      outOfStockBackgroundColour: defaultTo(
        config?.outOfStockBackgroundColour,
        DEFAULT_PRODUCT_COLOURS.outOfStockBackgroundColour
      ),
      outOfStockTextColour: defaultTo(config?.outOfStockTextColour, DEFAULT_PRODUCT_COLOURS.outOfStockTextColour)
    },
    validationSchema: ProductColoursSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        handleUpdateComponent(values);
        enqueueSnackbar('Saved', { variant: 'success' });
        setSubmitting(false);
      } catch (error) {
        console.error(error);
        setSubmitting(false);
      }
    }
  });

  const { initialValues, values, touched, errors, resetForm, handleSubmit, setFieldValue } = form;

  return (
    <FormikProvider value={form}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <EditorConatiner>
          <Stack spacing={3}>
            <Stack spacing={2} justifyContent="flex-end" direction="row">
              <Button variant="outlined" color="primary" onClick={resetForm} disabled={initialValues === values}>
                Reset
              </Button>
              <Box sx={{ flexGrow: 1 }} />
              <Button variant="outlined" color="primary" onClick={closeEdit}>
                Cancel
              </Button>
              <Button variant="contained" color="primary" type="submit">
                Save
              </Button>
            </Stack>
            <Typography variant="h5">{heading}</Typography>

            <Divider />

            <Stack spacing={2}>
              <Typography variant="body1">
                <strong>All Tags</strong>
              </Typography>
              <div>
                <ColourPicker
                  title="Background"
                  value={values.tagBackgroundColour}
                  handleChangeColour={(value) => setFieldValue('tagBackgroundColour', value)}
                  handleClearColour={() => setFieldValue('tagBackgroundColour', null)}
                />
                {touched.tagBackgroundColour && errors.tagBackgroundColour && (
                  <FormHelperText error>{touched.tagBackgroundColour && errors.tagBackgroundColour}</FormHelperText>
                )}
                <FormHelperText>This will set the default background colour of all product tags</FormHelperText>
              </div>
              <div>
                <ColourPicker
                  title="Text"
                  value={values.tagTextColour}
                  handleChangeColour={(value) => setFieldValue('tagTextColour', value)}
                  handleClearColour={() => setFieldValue('tagTextColour', null)}
                />
                {touched.tagTextColour && errors.tagTextColour && (
                  <FormHelperText error>{touched.tagTextColour && errors.tagTextColour}</FormHelperText>
                )}
              </div>
            </Stack>

            <Divider />

            <Stack spacing={2}>
              <Typography variant="body1">
                <strong>Sale Tags</strong>
              </Typography>
              <div>
                <ColourPicker
                  title="Background"
                  value={values.saleBackgroundColour}
                  handleChangeColour={(value) => setFieldValue('saleBackgroundColour', value)}
                  handleClearColour={() => setFieldValue('saleBackgroundColour', null)}
                />
                {touched.saleBackgroundColour && errors.saleBackgroundColour && (
                  <FormHelperText error>{touched.saleBackgroundColour && errors.saleBackgroundColour}</FormHelperText>
                )}
                <FormHelperText>This will set the sale tag background colour</FormHelperText>
              </div>
              <div>
                <ColourPicker
                  title="Text"
                  value={values.saleTextColour}
                  handleChangeColour={(value) => setFieldValue('saleTextColour', value)}
                  handleClearColour={() => setFieldValue('saleTextColour', null)}
                />
                {touched.saleTextColour && errors.saleTextColour && (
                  <FormHelperText error>{touched.saleTextColour && errors.saleTextColour}</FormHelperText>
                )}
              </div>
            </Stack>

            <Divider />

            <Stack spacing={2}>
              <Typography variant="body1">
                <strong>Out Of Stock Tags</strong>
              </Typography>
              <div>
                <ColourPicker
                  title="Background"
                  value={values.outOfStockBackgroundColour}
                  handleChangeColour={(value) => setFieldValue('outOfStockBackgroundColour', value)}
                  handleClearColour={() => setFieldValue('outOfStockBackgroundColour', null)}
                />
                {touched.outOfStockBackgroundColour && errors.outOfStockBackgroundColour && (
                  <FormHelperText error>
                    {touched.outOfStockBackgroundColour && errors.outOfStockBackgroundColour}
                  </FormHelperText>
                )}
                <FormHelperText>This will set the sale tag background colour</FormHelperText>
              </div>
              <div>
                <ColourPicker
                  title="Text"
                  value={values.outOfStockTextColour}
                  handleChangeColour={(value) => setFieldValue('outOfStockTextColour', value)}
                  handleClearColour={() => setFieldValue('outOfStockTextColour', null)}
                />
                {touched.outOfStockTextColour && errors.outOfStockTextColour && (
                  <FormHelperText error>{touched.outOfStockTextColour && errors.outOfStockTextColour}</FormHelperText>
                )}
              </div>
            </Stack>
          </Stack>
        </EditorConatiner>
      </Form>
    </FormikProvider>
  );
}
