import React from 'react';
import { alpha, styled } from '@material-ui/core/styles';
import { Icon } from '@iconify/react';
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
// material
import { Box, Typography, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
// utils
import { isEmpty } from 'lodash';
import { getTextAlignPosition } from '../../../utils/getPositions';
import Markdown from '../Markdown';
import {
  MAX_DROPDOWN_WIDTH_LARGE,
  PRODUCTS_ITEM_DESCRIPTION_VIEW_TYPE,
  PRODUCT_FOOTER_DESCRIPTION_TITLE
} from '../../../utils/constants';
import { defaultTo } from '../../../utils/nullable';

const AccordionStyle = styled(Accordion)(({ theme }) => ({
  '&.MuiAccordion-root': {
    border: `1px solid ${alpha(theme.palette.border, 0.56)}`,
    padding: theme.spacing(0.5, 1, 0.5, 2.5),
    maxWidth: MAX_DROPDOWN_WIDTH_LARGE,
    width: '100%',
    boxShadow: 'none'
  }
}));

const ProductDescriptionWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  border: `1px solid ${alpha(theme.palette.border, 0.56)}`,
  borderRadius: theme.shape.textfieldBorderRadius,
  padding: theme.spacing(2.5, 2),
  maxWidth: MAX_DROPDOWN_WIDTH_LARGE,
  boxShadow: 'none',
  width: '100%'
}));

export const ProductDescription = ({
  position,
  descriptionHeaderBorder,
  descriptionHeaderViewType,
  descriptionHeaderBackgroundColour,
  descriptionHeaderTextColour,
  descriptionTitle,
  description
}) => {
  if (descriptionHeaderViewType === PRODUCTS_ITEM_DESCRIPTION_VIEW_TYPE[1]) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: getTextAlignPosition(position),
          width: '100%'
        }}
      >
        <AccordionStyle
          disableGutters
          elevation={0}
          sx={{
            '&.MuiAccordion-root': {
              ...(!descriptionHeaderBorder && { border: 0 }),
              ...(!isEmpty(descriptionHeaderBackgroundColour) && {
                backgroundColor: descriptionHeaderBackgroundColour
              }),
              ...(!isEmpty(descriptionHeaderTextColour) && { color: descriptionHeaderTextColour })
            }
          }}
        >
          <AccordionSummary
            expandIcon={
              <Icon
                icon={arrowIosDownwardFill}
                width={20}
                height={20}
                color={!isEmpty(descriptionHeaderTextColour) ? descriptionHeaderTextColour : 'inherit'}
              />
            }
            sx={{ color: (theme) => theme.palette.primary.main, px: 0 }}
          >
            <Typography
              variant="subtitle1"
              sx={{
                color: !isEmpty(descriptionHeaderTextColour) ? descriptionHeaderTextColour : 'text.primary',
                fontWeight: (theme) => theme.typography.fontWeightMedium
              }}
            >
              {defaultTo(descriptionTitle, PRODUCT_FOOTER_DESCRIPTION_TITLE)}
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ px: 0, pt: (theme) => theme.spacing(3) }}>
            <Markdown children={description} />
          </AccordionDetails>
        </AccordionStyle>
      </Box>
    );
  }
  return (
    <ProductDescriptionWrapper
      sx={{
        justifyContent: getTextAlignPosition(position),
        ...(!descriptionHeaderBorder && { border: 0 }),
        ...(!descriptionHeaderBorder && isEmpty(descriptionHeaderBackgroundColour) && { p: 0 }),
        ...(!isEmpty(descriptionHeaderBackgroundColour) && {
          backgroundColor: descriptionHeaderBackgroundColour
        }),
        ...(!isEmpty(descriptionHeaderTextColour) && { color: descriptionHeaderTextColour })
      }}
    >
      <Markdown children={description} />
    </ProductDescriptionWrapper>
  );
};
