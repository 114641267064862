import * as Yup from 'yup';
import { styled } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack5';
import { Form, FormikProvider, useFormik } from 'formik';
import {
  Box,
  Typography,
  Stack,
  Divider,
  Button,
  FormHelperText,
  FormControlLabel,
  Switch,
  Collapse,
  TextField
} from '@material-ui/core';
import { defaultTo } from 'lodash';
import { REQUIRED_OPTION_MESSAGE } from '../../utils/constants';
import {
  CURRENCY_BUTTON_FILLED,
  CURRENCY_TYPE,
  CURRENCY_VERTICAL_POSITIONS,
  GENERIC_CONTENT_POSITIONS
} from '../../utils/constantsEditor';
import MultiOptionPicker from '../../components/pickers/MultiOptionPicker';
import { CurrencyViewPicker } from '../../components/pickers/CurrencyViewPicker';

const EditorConatiner = styled(Box)(({ theme }) => ({
  padding: theme.spacing(5)
}));

export default function CurrencyEditor({
  isMobileView,
  config,
  heading,
  subHeading,
  closeEdit,
  handleUpdateComponent
}) {
  const { enqueueSnackbar } = useSnackbar();
  const {
    enabled,
    type,
    title,
    position,
    verticalPosition,
    textPosition,
    showTitle,
    showCurrencySymbol,
    buttonsFilled
  } = config;

  const BodySchema = Yup.object().shape({
    enabled: Yup.boolean().required(REQUIRED_OPTION_MESSAGE),
    type: Yup.string().required(REQUIRED_OPTION_MESSAGE),
    position: Yup.string().required(REQUIRED_OPTION_MESSAGE),
    verticalPosition: Yup.string().required(REQUIRED_OPTION_MESSAGE),
    textPosition: Yup.string().required(REQUIRED_OPTION_MESSAGE),
    showCurrencySymbol: Yup.boolean().required(REQUIRED_OPTION_MESSAGE),
    showTitle: Yup.boolean().required(REQUIRED_OPTION_MESSAGE),
    buttonsFilled: Yup.string().required(REQUIRED_OPTION_MESSAGE)
  });

  const form = useFormik({
    initialValues: {
      enabled: defaultTo(enabled, true),
      type: defaultTo(type, CURRENCY_TYPE[0].value),
      title: defaultTo(title, ''),
      position: defaultTo(position, GENERIC_CONTENT_POSITIONS[0].value),
      verticalPosition: defaultTo(verticalPosition, CURRENCY_VERTICAL_POSITIONS[0].value),
      textPosition: defaultTo(textPosition, GENERIC_CONTENT_POSITIONS[0].value),
      showCurrencySymbol: defaultTo(showCurrencySymbol, true),
      showTitle: defaultTo(showTitle, true),
      buttonsFilled: defaultTo(buttonsFilled, CURRENCY_BUTTON_FILLED[0].value)
    },
    validationSchema: BodySchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        handleUpdateComponent(values);
        enqueueSnackbar('Saved', { variant: 'success' });
        setSubmitting(false);
      } catch (error) {
        console.error(error);
        setSubmitting(false);
      }
    }
  });

  const { values, errors, touched, handleSubmit, setFieldValue, getFieldProps } = form;

  return (
    <FormikProvider value={form}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <EditorConatiner>
          <Stack spacing={3}>
            <Stack spacing={2} justifyContent="flex-end" direction="row">
              <Button variant="outlined" color="primary" onClick={closeEdit}>
                Cancel
              </Button>
              <Button variant="contained" color="primary" type="submit">
                Save
              </Button>
            </Stack>
            <Box>
              <Typography variant="h5">{heading}</Typography>
              <FormHelperText>{subHeading}</FormHelperText>
            </Box>

            <Divider />

            <div>
              <FormControlLabel
                label="Enable"
                control={<Switch {...getFieldProps('enabled')} checked={values.enabled} />}
              />
              {errors.enabled && <FormHelperText error>{errors.enabled}</FormHelperText>}
              <FormHelperText>
                By disabling the currency selector, customers will not be able to convert prices on your store from your
                store currency.
              </FormHelperText>
            </div>

            <Collapse in={values.enabled}>
              <Stack spacing={3}>
                <Stack spacing={2}>
                  <Typography variant="body1">
                    <strong>View Type</strong>
                  </Typography>

                  <CurrencyViewPicker value={values.type} onChange={(value) => setFieldValue('type', value)} />
                </Stack>

                <Collapse in={values.type !== CURRENCY_TYPE[0].value}>
                  <div>
                    <MultiOptionPicker
                      data={CURRENCY_BUTTON_FILLED}
                      value={values.buttonsFilled}
                      onChange={(_, newValue) => setFieldValue('buttonsFilled', newValue)}
                    />
                    {touched.buttonsFilled && errors.buttonsFilled && (
                      <FormHelperText error>{touched.buttonsFilled && errors.buttonsFilled}</FormHelperText>
                    )}
                  </div>
                </Collapse>

                <Divider />

                <Stack spacing={2}>
                  <Typography variant="body1">
                    <strong>Heading</strong>
                  </Typography>

                  <Box>
                    <TextField fullWidth autoComplete="off" label="Title" {...getFieldProps('title')} />
                    <FormHelperText>This will default to an appropriate title if left empty</FormHelperText>
                  </Box>

                  <Box>
                    <FormControlLabel
                      label="Show Title"
                      control={<Switch checked={values.showTitle} {...getFieldProps('showTitle')} />}
                    />
                    {touched.showTitle && errors.showTitle && (
                      <FormHelperText error>{touched.showTitle && errors.showTitle}</FormHelperText>
                    )}
                  </Box>
                </Stack>

                <Divider />

                <Box>
                  <Stack spacing={3}>
                    {!isMobileView && (
                      <Stack spacing={2}>
                        <Typography variant="body1">
                          <strong>Position</strong>
                        </Typography>
                        <MultiOptionPicker
                          data={GENERIC_CONTENT_POSITIONS}
                          value={values.position}
                          onChange={(_, newValue) => setFieldValue('position', newValue)}
                        />
                        {touched.position && errors.position && (
                          <FormHelperText error>{touched.position && errors.position}</FormHelperText>
                        )}
                      </Stack>
                    )}
                    <Stack spacing={2}>
                      <Typography variant="body1">
                        <strong>Vertical Position</strong>
                      </Typography>
                      <MultiOptionPicker
                        data={CURRENCY_VERTICAL_POSITIONS}
                        value={values.verticalPosition}
                        onChange={(_, newValue) => setFieldValue('verticalPosition', newValue)}
                      />
                      {touched.verticalPosition && errors.verticalPosition && (
                        <FormHelperText error>{touched.verticalPosition && errors.verticalPosition}</FormHelperText>
                      )}
                    </Stack>
                  </Stack>

                  <Collapse in={values.type === CURRENCY_TYPE[0].value}>
                    <Divider sx={{ my: 3 }} />
                    <Stack spacing={2}>
                      <Typography variant="body1">
                        <strong>Text Position</strong>
                      </Typography>
                      <MultiOptionPicker
                        data={GENERIC_CONTENT_POSITIONS}
                        value={values.textPosition}
                        onChange={(_, newValue) => setFieldValue('textPosition', newValue)}
                      />
                      <FormHelperText>This will align all text in view, including the dropdown menu</FormHelperText>
                    </Stack>
                  </Collapse>
                </Box>

                <Divider />

                <Stack spacing={2}>
                  <Typography variant="body1">
                    <strong>Options</strong>
                  </Typography>

                  <Box>
                    <FormControlLabel
                      label="Show Currency Symbol"
                      control={<Switch checked={values.showCurrencySymbol} {...getFieldProps('showCurrencySymbol')} />}
                    />
                    <FormHelperText>
                      When displaying the currency options, configure whether to show both the currency name along with
                      it's symbol
                    </FormHelperText>
                    {touched.showCurrencySymbol && errors.showCurrencySymbol && (
                      <FormHelperText error>{touched.showCurrencySymbol && errors.showCurrencySymbol}</FormHelperText>
                    )}
                  </Box>
                </Stack>
              </Stack>
            </Collapse>
          </Stack>
        </EditorConatiner>
      </Form>
    </FormikProvider>
  );
}
