import { useRef, useState } from 'react';
import { BOOKING_SUCCESS_STATUS, BOOKING_UNKNOWN_FAILURE_STATUS } from '@iamilyas/store-template-library';
import { alpha, styled } from '@material-ui/core/styles';
import { Stack, Button, Typography, Menu, MenuItem, Box } from '@material-ui/core';
import { Icon } from '@iconify/react';
import dotsHorizontal from '@iconify/icons-mdi/dots-horizontal';
import checkmarkCircle2Fill from '@iconify/icons-eva/checkmark-circle-2-fill';
import baselineError from '@iconify/icons-ic/baseline-error';
import hourglassOutline from '@iconify/icons-ion/hourglass-outline';

const RootStyle = styled(Stack)(({ theme }) => ({
  border: `solid 1px ${alpha(theme.palette.primary.main, 0.24)}`,
  borderRadius: theme.shape.borderRadiusSm,
  padding: theme.spacing(2)
}));

const ChooseButton = styled(Button)(() => ({
  width: '100%',
  height: 35
}));

export const BOOKING_IN_PROGRESS_STAGE_VALUE = 'IN_PROGRESS';
export const BOOKING_LOADING_BOOKING_SUMMARY_STAGE_VALUE = 'LOADING_BOOKING_SUMMARY';
export const BOOKING_BOOKING_SUMMARY_COMPLETE_STAGE_VALUE = 'BOOKING_SUMMARY_COMPLETE';
export const BOOKING_LOADING_BOOKING_SUBMIT_STAGE_VALUE = 'LOADING_BOOKING_SUBMIT';

export const BOOKING_STAGE_OPTIONS = [
  {
    name: 'In Progress',
    value: BOOKING_IN_PROGRESS_STAGE_VALUE,
    icon: dotsHorizontal,
    description: 'Customer is progressing through the booking journey.'
  },
  {
    name: 'Loading Summary',
    value: BOOKING_LOADING_BOOKING_SUMMARY_STAGE_VALUE,
    icon: hourglassOutline,
    description: 'Loading the customers payment summary.'
  },
  {
    name: 'Submitted Booking',
    value: BOOKING_LOADING_BOOKING_SUBMIT_STAGE_VALUE,
    icon: hourglassOutline,
    description:
      'Booking is submitted and a customer is waiting to be presented with their next steps. This can be either a success or failure.'
  },
  {
    name: 'Success',
    value: BOOKING_SUCCESS_STATUS,
    icon: checkmarkCircle2Fill,
    description: 'Booking is successful.'
  },
  {
    name: 'Failure',
    value: BOOKING_UNKNOWN_FAILURE_STATUS,
    icon: baselineError,
    description: 'An uncommon scenario. All errors will display this page.'
  }
];

export const BookingStageSelector = ({ onSelectOption, sx }) => {
  const [value, setValue] = useState(BOOKING_STAGE_OPTIONS[0]);
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <RootStyle direction="column" spacing={2} {...sx}>
      <Stack spacing={1}>
        <Typography variant="subtitle2" color="secondary.contrastText">
          Booking Preview
        </Typography>
        <Box sx={{ display: 'flex', color: 'secondary.contrastText' }}>
          <Icon icon={value.icon} width={20} height={20} sx={{ alignSelf: 'center', color: 'inherit' }} />
          <Typography variant="subtitle2" sx={{ marginLeft: 1, color: 'secondary.contrastText' }}>
            {value.name}
          </Typography>
        </Box>
        <Typography variant="caption" sx={{ color: 'secondary.contrastText' }}>
          {value.description}
        </Typography>
      </Stack>
      <ChooseButton
        ref={anchorRef}
        fullWidth
        variant="outlined"
        size="small"
        onClick={handleOpen}
        sx={{ position: 'relative' }}
      >
        Choose Booking Journey
      </ChooseButton>
      <Menu
        open={open}
        anchorEl={anchorRef.current}
        onClose={handleClose}
        PaperProps={{
          sx: { mt: 2, width: 230, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        {BOOKING_STAGE_OPTIONS.map((stage) => {
          const { name, value, icon } = stage;
          return (
            <MenuItem
              key={value}
              onClick={() => {
                setValue(stage);
                handleClose();
                onSelectOption(value);
              }}
            >
              <Icon icon={icon} width={20} height={20} />
              <Typography variant="body2" sx={{ ml: 2 }}>
                {name}
              </Typography>
            </MenuItem>
          );
        })}
      </Menu>
    </RootStyle>
  );
};
