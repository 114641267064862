import { Box, Typography, IconButton, Stack, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import { alpha, styled } from '@material-ui/core/styles';
import { Icon } from '@iconify/react';
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
import { isEmpty } from 'lodash';
import chevronUp from '@iconify/icons-akar-icons/chevron-up';
import chevronDown from '@iconify/icons-akar-icons/chevron-down';

const ThumbImgStyle = styled('img')(({ theme }) => ({
  width: 34,
  height: 34,
  objectFit: 'cover',
  marginRight: theme.spacing(1),
  borderRadius: theme.shape.borderRadiusCircle
}));

const IconContainerStyle = styled(Box)(({ theme }) => ({
  width: 34,
  height: 20,
  marginRight: 5,
  textAlign: 'center',
  color: theme.palette.secondary.contrastText
}));

const PlaceholderImage = styled(Box)(({ theme }) => ({
  width: 34,
  height: 34,
  backgroundColor: theme.palette.grey[300],
  borderRadius: theme.shape.borderRadiusMd,
  marginRight: theme.spacing(1)
}));

const Dot = styled(Box)(({ theme }) => ({
  width: '5px',
  height: '5px',
  backgroundColor: theme.palette.success.main,
  borderRadius: theme.shape.borderRadiusCircle,
  alignSelf: 'center'
}));

const AccordionStyle = styled(Accordion)(({ theme }) => ({
  '&.MuiAccordion-root': {
    boxShadow: 'none',
    backgroundColor: 'transparent',
    color: theme.palette.secondary.contrastText,
    border: `1px solid ${alpha(theme.palette.primary.main, 0.24)}`
  }
}));

export const GenericAccordion = ({ name, icon, iconRotated = false, image, active = false, children }) => {
  return (
    <AccordionStyle>
      <AccordionSummary expandIcon={<Icon icon={arrowIosDownwardFill} width={20} height={20} />}>
        <Stack direction="row" spacing={1}>
          {icon ? <RowIcon icon={icon} {...(iconRotated && { rotate: 2 })} /> : <RowImage img={image} />}
          <Stack direction="row" spacing={2}>
            <Typography noWrap variant="body2" sx={{ alignSelf: 'center' }}>
              {name}
            </Typography>
            {active && <Dot />}
          </Stack>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </AccordionStyle>
  );
};

export const RowImage = ({ img }) => (isEmpty(img) ? <PlaceholderImage /> : <ThumbImgStyle src={img} />);

export const RowIcon = ({ icon, ...other }) => (
  <IconContainerStyle>
    <Icon icon={icon} color="inherit" width={20} height={20} {...other} />
  </IconContainerStyle>
);

export const OrderSection = ({ handleMoveUp, handleMoveDown }) => (
  <Stack
    direction="row"
    spacing={2}
    sx={{
      height: 30,
      my: 1,
      justifyContent: 'center',
      backgroundColor: (theme) => theme.palette.secondary.main,
      borderRadius: (theme) => theme.shape.borderRadiusSm
    }}
  >
    <Box sx={{ display: 'flex' }}>
      <IconButton variant="outlined" onClick={handleMoveUp} color="primary" sx={{ width: '30px' }}>
        <Icon icon={chevronUp} />
      </IconButton>
    </Box>
    <Box sx={{ display: 'flex' }}>
      <IconButton variant="outlined" onClick={handleMoveDown} color="primary" sx={{ width: '30px' }}>
        <Icon icon={chevronDown} />
      </IconButton>
    </Box>
  </Stack>
);
