import { Box, Typography, Stack, CardActionArea } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { Icon } from '@iconify/react';
import textboxLight from '@iconify/icons-ph/textbox-light';
import textboxDuotone from '@iconify/icons-ph/textbox-duotone';
import formatLetterMatches from '@iconify/icons-mdi/format-letter-matches';

const variants = () => {
  const arr = [
    {
      key: 'STANDARD',
      name: 'Standard',
      component: <StandardVariant />
    },
    {
      key: 'FILLED',
      name: 'Filled',
      component: <FilledVariant />
    },
    {
      key: 'OUTLINED',
      name: 'Outlined',
      component: <OutlinedVariant />
    }
  ];
  return arr;
};

const FilledVariant = () => (
  <Box sx={{ justifyContent: 'center' }}>
    <Icon fontSize={40} icon={textboxDuotone} />
  </Box>
);

const StandardVariant = () => (
  <Box sx={{ justifyContent: 'center' }}>
    <Icon fontSize={40} icon={formatLetterMatches} />
  </Box>
);

const OutlinedVariant = () => (
  <Box sx={{ justifyContent: 'center' }}>
    <Icon fontSize={40} icon={textboxLight} />
  </Box>
);

const StyledCardActionArea = styled(CardActionArea)(({ theme }) => ({
  borderRadius: theme.shape.borderRadiusSm,
  padding: theme.spacing(3),
  height: '100%'
}));

const ShapeOption = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.grey[200],
  border: `2px solid ${theme.palette.divider}`,
  width: '100%',
  borderRadius: theme.shape.borderRadiusSm,
  padding: 0
}));

export const InputVariantPicker = ({ value, onChangeShape }) => {
  return (
    <Stack spacing={2} direction="row">
      {variants().map((variant, index) => {
        const { key, name, component } = variant;
        return (
          <ShapeOption
            key={index}
            sx={{ ...(key === value && { border: (theme) => `2px solid ${theme.palette.primary.main}` }) }}
          >
            <StyledCardActionArea onClick={() => onChangeShape(key)}>
              <Box sx={{ textAlign: 'center', width: '100%', color: (theme) => theme.palette.primary.main }}>
                {component && <Box sx={{ width: '100%' }}>{component}</Box>}
                <Typography variant="subtitle2" sx={{ color: 'text.primary', mt: (theme) => theme.spacing(1) }}>
                  {name}
                </Typography>
              </Box>
            </StyledCardActionArea>
          </ShapeOption>
        );
      })}
    </Stack>
  );
};
