import { Suspense, lazy, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'src/redux/store';
import { defaultTo, find } from 'lodash';
import {
  getEditorAvailablePages,
  getNavigationOptions,
  getPagesOptions,
  getProductGroupOptions,
  getProductOptions,
  getStoreMetadata,
  getStoreTheme
} from 'src/redux/slices/store';
import { styled } from '@material-ui/core/styles';
import { Box, Divider } from '@material-ui/core';
import LoadingScreen from 'src/components/LoadingScreen';
import Page from 'src/components/Page';
import LoadingScreenFazer from 'src/components/LoadingScreenFazer';
import { getCurrencyOptions } from 'src/redux/slices/tenant';
import {
  THEME,
  CART,
  COLLECTION,
  HOME,
  LAYOUT,
  PRODUCTS,
  PRODUCT,
  CHECKOUT,
  BOOKING,
  CONTACT,
  CUSTOM,
  COLLECTION_LIST,
  ERROR_404,
  ERROR_500
} from './utils/editorPages';
import { EditorHeader } from './components/EditorHeader';

const ACTIVE_PRODUCT_STATUS = 'ACTIVE';
const ACTIVE_PRODUCT_GROUP_STATUS = 'ACTIVE';

const LoadingStyled = styled(Box)(({ theme }) => ({
  padding: theme.spacing(30, 0, 0),
  justifyContent: 'center'
}));

export const isDepsLoading = (loading) => {
  const {
    storeMetadataIsLoading,
    storeThemeLoading,
    productOptionsLoading,
    productGroupOptionsLoading,
    linkPageOptionsLoading,
    editorPagesLoading,
    navigationOptionsLoading
  } = loading;
  return (
    storeMetadataIsLoading ||
    storeThemeLoading ||
    productOptionsLoading ||
    productGroupOptionsLoading ||
    linkPageOptionsLoading ||
    editorPagesLoading ||
    navigationOptionsLoading
  );
};

const Loadable = (Component) => (props) => {
  return (
    <Suspense
      fallback={
        <LoadingStyled>
          <LoadingScreenFazer />
        </LoadingStyled>
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function StoreEditor() {
  const dispatch = useDispatch();
  const {
    loading,
    editor: { previousPage, page },
    editorPages
  } = useSelector((state) => state.store);
  const selected = find(editorPages, (o) => o.value === page.value) || HOME;
  const isLoading = isDepsLoading(loading);

  useEffect(() => {
    // THEME
    dispatch(getStoreMetadata());
    dispatch(getStoreTheme());
    // OPTIONS
    dispatch(getProductOptions(ACTIVE_PRODUCT_STATUS));
    dispatch(getProductGroupOptions(ACTIVE_PRODUCT_GROUP_STATUS));
    dispatch(getPagesOptions());
    dispatch(getNavigationOptions());
    dispatch(getCurrencyOptions());
    dispatch(getEditorAvailablePages());
  }, [dispatch]);

  const HomePageMemo = useMemo(() => Loadable(lazy(() => import('./home/HomePageEditor'))), []);
  const ProductPageMemo = useMemo(() => Loadable(lazy(() => import('./product/ProductPageEditor'))), []);
  const ProductsPageMemo = useMemo(() => Loadable(lazy(() => import('./products/ProductsPageEditor'))), []);
  const CollectionsPageMemo = useMemo(() => Loadable(lazy(() => import('./collections/CollectionListPageEditor'))), []);
  const CollectionPageMemo = useMemo(() => Loadable(lazy(() => import('./collection/CollectionPageEditor'))), []);
  const CartPageMemo = useMemo(() => Loadable(lazy(() => import('./cart/CartPageEditor'))), []);
  const ContactPageMemo = useMemo(() => Loadable(lazy(() => import('./contact/ContactPageEditor'))), []);
  const CustomPageMemo = useMemo(() => Loadable(lazy(() => import('./custom/CustomPageEditor'))), []);
  const Error404PageMemo = useMemo(() => Loadable(lazy(() => import('./error/Error404PageEditor'))), []);
  const Error500PageMemo = useMemo(() => Loadable(lazy(() => import('./error/Error500PageEditor'))), []);
  const CheckoutMemo = useMemo(() => Loadable(lazy(() => import('./checkout/CheckoutPageEditor'))), []);
  const BookingMemo = useMemo(() => Loadable(lazy(() => import('./booking/BookingPageEditor'))), []);

  const handleGetEditorPage = (option) => {
    return (
      <>
        {[HOME.value, LAYOUT.value, THEME.value].includes(option.value) && <HomePageMemo />}
        {option.value === PRODUCTS.value && <ProductsPageMemo />}
        {option.value === COLLECTION_LIST.value && <CollectionsPageMemo />}
        {option.value === PRODUCT.value && <ProductPageMemo />}
        {option.value === COLLECTION.value && <CollectionPageMemo />}
        {option.value === CART.value && <CartPageMemo />}
        {option.value === CHECKOUT.value && <CheckoutMemo />}
        {option.value === BOOKING.value && <BookingMemo />}
        {option.value === CONTACT.value && <ContactPageMemo />}
        {option.value === ERROR_404.value && <Error404PageMemo />}
        {option.value === ERROR_500.value && <Error500PageMemo />}
        {option.type === CUSTOM.type && <CustomPageMemo />}
      </>
    );
  };

  return (
    <Page title="Store Builder | Shoppy Monkey" sx={{ overflow: 'auto', height: '100%' }}>
      {isLoading ? (
        <Box sx={{ height: 400, justifyContent: 'center', flexDirection: 'column' }}>
          <LoadingScreen />
        </Box>
      ) : (
        <>
          <EditorHeader page={selected} />
          <Divider />
          {selected.value === LAYOUT.value || selected.value === THEME.value
            ? handleGetEditorPage(defaultTo(previousPage, selected))
            : handleGetEditorPage(selected)}
        </>
      )}
    </Page>
  );
}
