// import styled from '@emotion/styled';
import { Box, Button, Typography, Stack } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { defaultTo, isEmpty } from 'lodash';
import { GenericAccordion, OrderSection, RowIcon, RowImage } from './AccordianStyled';

const RowStyle = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0)
}));

const MultiViewRowComponent = ({
  id,
  componentName,
  headerRow,
  headerActive,
  rows,
  icon,
  title = '',
  image = '',
  openEdit,
  handleMoveUp = () => {},
  handleMoveDown = () => {},
  disableOrder = true
}) => {
  const componentTitle = isEmpty(title) ? 'Section' : title;

  const ComponentRow = ({ row }) => {
    const { icon: rowIcon, image, name, component } = row;
    return (
      <Button
        fullWidth
        variant="text"
        color="inherit"
        disableElevation
        disableFocusRipple
        disableTouchRipple
        disableRipple
        sx={{ justifyContent: 'flex-start' }}
        onClick={() => openEdit(component)}
      >
        <Stack direction="row" spacing={1} sx={{ width: '100%' }}>
          {rowIcon ? <RowIcon icon={rowIcon} /> : <RowImage img={image} />}
          <Typography noWrap variant="body2" sx={{ alignSelf: 'center', fontWeight: 200 }}>
            {name || componentTitle}
          </Typography>
        </Stack>
      </Button>
    );
  };

  return (
    <RowStyle>
      <GenericAccordion name={componentName} icon={icon} image={image} active={headerActive}>
        {headerRow && <ComponentRow row={headerRow} />}
        {!disableOrder && (
          <OrderSection handleMoveUp={() => handleMoveUp(id)} handleMoveDown={() => handleMoveDown(id)} />
        )}
        <Stack direction="column" spacing={2}>
          {rows.map((row, index) => (
            <ComponentRow key={defaultTo(row.id, index)} row={row} />
          ))}
        </Stack>
      </GenericAccordion>
    </RowStyle>
  );
};

export default MultiViewRowComponent;
