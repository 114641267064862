import { Icon } from '@iconify/react';
import { Box, Stack, Divider, Link, Button } from '@material-ui/core';
import InfoBlock from 'src/components/_admin_v2/InfoBlock';
import useCollapseDrawer from 'src/hooks/useCollapseDrawer';
import AdminSidebarWrapper from 'src/layouts/admin/AdminSidebarWrapper';
import expandArrows from '@iconify/icons-bi/arrows-expand-vertical';
import { MHidden } from 'src/components/@material-extend';
import { useSelector } from '../../../../redux/store';
import EditorDrawer from './EditorDrawer';
import PageSelector from './PageSelector';

export const GenericEditorLayout = ({ editDrawer, handleCloseDrawer, headerComponents, children }) => {
  const { isCollapse, collapseClick, onToggleCollapse } = useCollapseDrawer();
  const { tenant } = useSelector((state) => state.tenant);
  const storeUrl = tenant?.storeUrl;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <EditorDrawer isOpenSidebar={editDrawer.open} onCloseSidebar={handleCloseDrawer}>
        {editDrawer.component || <div />}
      </EditorDrawer>

      {headerComponents}

      <AdminSidebarWrapper
        isCollapse={isCollapse}
        showOnCollapse={false}
        collapseClick={collapseClick}
        onToggleCollapse={onToggleCollapse}
        headerSx={{
          px: 2
        }}
        sx={{
          pt: 3,
          pb: 2
        }}
      >
        <Stack direction="column" divider={<Divider />} spacing={1}>
          <Box sx={{ p: 2 }}>
            <InfoBlock
              caption="Choose Section"
              colour="secondary.contrastText"
              body={<PageSelector sx={{ pt: 1 }} />}
            />
          </Box>

          {!isCollapse && children}
        </Stack>
      </AdminSidebarWrapper>

      {isCollapse && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mt: 2
          }}
        >
          <Box
            sx={{
              position: 'relative',
              background: (theme) => theme.palette.secondary.lighter,
              borderRadius: (theme) => theme.shape.borderRadiusCircle,
              height: 36,
              width: 36
            }}
          >
            <Box sx={{ position: 'absolute', left: 7, top: 7, color: 'secondary.main' }}>
              <Icon icon={expandArrows} fontSize={22} color="inherit" />
            </Box>
          </Box>
        </Box>
      )}

      <Box sx={{ flexGrow: 1 }} />

      {Boolean(storeUrl && !isCollapse) && (
        <MHidden width="smUp">
          <Box sx={{ px: 2, pb: 2, color: 'white' }}>
            <Button fullWidth LinkComponent={Link} color="inherit" variant="outlined" href={storeUrl} target="_blank">
              Visit Store
            </Button>
          </Box>
        </MHidden>
      )}
    </Box>
  );
};
