import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import {
  Button,
  Stack,
  Box,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  Typography,
  TableContainer,
  IconButton
} from '@material-ui/core';
import { alpha, styled } from '@material-ui/core/styles';
import { defaultTo, isEmpty } from 'lodash';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import plusFill from '@iconify/icons-eva/plus-fill';
import minusFill from '@iconify/icons-eva/minus-fill';
import missingImageDefault from '../../static/placeholder/product_image.jpg';
import { CART_BUTTON_VIEW_TYPE } from '../../utils/constants';
import { fCurrency } from '../../utils/formatNumber';
import { MLazyLoadImage } from '../core/@react-lazy-load-image-component-extend';
import { MIconButton } from '../core/@material-extend';
import Scrollbar from '../core/Scrollbar';
import { PRODUCT_PAGE } from '../../utils/pageTypes';
import useMobile from '../../hooks/useMobile';
import { CartVariantView } from './CartVariantView';
import { CartNotesView } from './CartNoteView';

// ----------------------------------------------------------------------

const CartIncrementerRootStyle = styled(Box)(() => ({
  width: 96,
  textAlign: 'center'
}));

const QuantityControls = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(0.5),
  padding: theme.spacing(0.5, 0.75),
  borderRadius: theme.shape.textfieldBorderRadius,
  border: `solid 1px ${alpha(theme.palette.border, 0.32)}`,
  minWidth: 100
}));

const Image = styled(MLazyLoadImage)(({ theme }) => ({
  width: 64,
  height: 64,
  marginRight: theme.spacing(2)
}));

const Title = styled(Typography)(() => ({
  maxWidth: 240,
  cursor: 'pointer'
}));

// ----------------------------------------------------------------------

CartIncrementer.propTypes = {
  showInventoryQuantity: PropTypes.bool,
  available: PropTypes.number,
  quantity: PropTypes.number,
  onIncrease: PropTypes.func,
  onDecrease: PropTypes.func
};

function CartIncrementer({
  showInventoryQuantity = true,
  available,
  quantity,
  isInfiniteStock,
  onIncrease,
  onDecrease,
  rootSx,
  sx
}) {
  return (
    <CartIncrementerRootStyle sx={{ ...rootSx }}>
      <QuantityControls sx={{ ...sx }}>
        <MIconButton size="small" color="inherit" onClick={onDecrease} disabled={quantity <= 1}>
          <Icon icon={minusFill} width={16} height={16} />
        </MIconButton>
        {quantity}
        <MIconButton
          size="small"
          color="inherit"
          onClick={onIncrease}
          {...(!isInfiniteStock && { disabled: quantity >= available })}
        >
          <Icon icon={plusFill} width={16} height={16} />
        </MIconButton>
      </QuantityControls>
      {showInventoryQuantity && !isInfiniteStock && (
        <Typography variant="caption" sx={{ color: 'text.secondary' }}>
          available: {available}
        </Typography>
      )}
    </CartIncrementerRootStyle>
  );
}

const ProductsTable = ({
  showNotes = true,
  noteTitle,
  showInventoryQuantity,
  buttonViewType,
  currency,
  products,
  onDelete,
  onIncreaseQuantity,
  onDecreaseQuantity,
  handleNavigationClick
}) => (
  <Scrollbar>
    <TableContainer sx={{ overflowY: 'hidden', minWidth: 720, mt: 3 }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Product</TableCell>
            <TableCell align="left">Price</TableCell>
            <TableCell align="left">Quantity</TableCell>
            <TableCell align="right">Total</TableCell>
            <TableCell align="right" />
          </TableRow>
        </TableHead>

        <TableBody>
          {products.map((product, index) => {
            const {
              id,
              variantId,
              name,
              resource,
              isInfiniteStock,
              price,
              size,
              colour,
              image,
              quantity,
              available,
              note
            } = product;
            const priceInMajorUnit = price.amount;
            return (
              <TableRow key={index}>
                <TableCell sx={{ minWidth: 250, maxWidth: 400 }}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'start'
                    }}
                  >
                    {isEmpty(image) ? (
                      <Image alt="Product Image" source={missingImageDefault} sx={{ minWidth: 64, maxWidth: 240 }} />
                    ) : (
                      <Image alt={defaultTo(name, 'Product Image')} src={image} sx={{ minWidth: 64, maxWidth: 240 }} />
                    )}
                    <Box>
                      <Title
                        noWrap
                        variant="subtitle2"
                        onClick={() => {
                          if (resource) {
                            handleNavigationClick(PRODUCT_PAGE, resource);
                          }
                        }}
                      >
                        {name}
                      </Title>
                      <CartVariantView colour={colour} size={size} />
                      {showNotes && note && <CartNotesView title={noteTitle} note={note} />}
                    </Box>
                  </Box>
                </TableCell>

                <TableCell align="left">{fCurrency(priceInMajorUnit, currency)}</TableCell>

                <TableCell align="left">
                  <CartIncrementer
                    showInventoryQuantity={showInventoryQuantity}
                    isInfiniteStock={isInfiniteStock}
                    quantity={quantity}
                    available={available}
                    onDecrease={() => onDecreaseQuantity(id, variantId)}
                    onIncrease={() => onIncreaseQuantity(id, variantId)}
                    rootSx={{ width: '100%' }}
                    sx={{ height: 45 }}
                  />
                </TableCell>

                <TableCell align="right">{fCurrency(priceInMajorUnit * quantity, currency)}</TableCell>

                <TableCell align="center">
                  {buttonViewType === CART_BUTTON_VIEW_TYPE[0] ? (
                    <Button variant="text" onClick={() => onDelete(id, variantId)}>
                      Remove
                    </Button>
                  ) : (
                    <IconButton color="primary" onClick={() => onDelete(id, variantId)}>
                      {buttonViewType === CART_BUTTON_VIEW_TYPE[1] ? (
                        <CloseIcon width={20} height={20} color="inherit" />
                      ) : (
                        <DeleteIcon width={20} height={20} color="inherit" />
                      )}
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  </Scrollbar>
);

const ProductTextTypography = ({ component, sx, children }) => (
  <Typography
    variant="subtitle2"
    {...(component && { component })}
    sx={{
      fontWeight: (theme) => theme.typography.fontWeightRegular,
      color: (theme) => theme.palette.text.secondary,
      ...sx
    }}
  >
    {children}
  </Typography>
);

const MobileProductsView = ({
  showNotes = true,
  noteTitle,
  showInventoryQuantity,
  currency,
  products,
  onDelete,
  onIncreaseQuantity,
  onDecreaseQuantity,
  handleNavigationClick
}) => {
  return (
    <Scrollbar>
      <Stack direction="column" spacing={4}>
        {products.map((product) => {
          const { id, variantId, name, resource, price, size, colour, image, quantity, available, note } = product;
          const priceInMajorUnit = price.amount;
          const key = `${id}-${variantId}`;
          return (
            <Stack key={key} direction="row" spacing={1}>
              {isEmpty(image) ? (
                <Image alt="Product Image" source={missingImageDefault} sx={{ width: 80, height: 80, minWidth: 80 }} />
              ) : (
                <Image
                  alt={defaultTo(name, 'Product Image')}
                  src={image}
                  sx={{ width: 80, height: 80, minWidth: 80 }}
                />
              )}
              <Stack direction="column" spacing={0} sx={{ width: '100%' }}>
                <Typography
                  variant="subtitle2"
                  sx={{ fontWeight: (theme) => theme.typography.fontWeightBold }}
                  onClick={() => {
                    if (resource) {
                      handleNavigationClick(PRODUCT_PAGE, resource);
                    }
                  }}
                >
                  {name}
                </Typography>
                {size && (
                  <ProductTextTypography>
                    Size:&nbsp;
                    <ProductTextTypography component="span" sx={{ color: 'text.primary' }}>
                      {size}
                    </ProductTextTypography>
                  </ProductTextTypography>
                )}
                {colour && (
                  <ProductTextTypography>
                    Colour:&nbsp;
                    <ProductTextTypography component="span" sx={{ color: 'text.primary' }}>
                      {colour}
                    </ProductTextTypography>
                  </ProductTextTypography>
                )}
                <ProductTextTypography>{fCurrency(priceInMajorUnit * quantity, currency)}</ProductTextTypography>

                {showNotes && note && <CartNotesView isMobile title={noteTitle} note={note} />}

                <Stack
                  direction="column"
                  spacing={2}
                  sx={{
                    p: (theme) => theme.spacing(2, 0)
                  }}
                >
                  <div>
                    <CartIncrementer
                      showInventoryQuantity={showInventoryQuantity}
                      quantity={quantity}
                      available={available}
                      onDecrease={() => onDecreaseQuantity(id, variantId)}
                      onIncrease={() => onIncreaseQuantity(id, variantId)}
                      rootSx={{ width: '100%', maxWidth: 300 }}
                      sx={{ height: 45 }}
                    />
                  </div>
                  <div>
                    <Button
                      fullWidth
                      variant="outlined"
                      color="primary"
                      sx={{ maxWidth: 300, minHeight: 45, py: 0 }}
                      onClick={() => onDelete(id, variantId)}
                    >
                      Remove
                    </Button>
                  </div>
                </Stack>
              </Stack>
            </Stack>
          );
        })}
      </Stack>
    </Scrollbar>
  );
};

CartProductList.propTypes = {
  showInventoryQuantity: PropTypes.bool,
  currency: PropTypes.string.isRequired,
  products: PropTypes.array.isRequired,
  onDelete: PropTypes.func.isRequired,
  onDecreaseQuantity: PropTypes.func.isRequired,
  onIncreaseQuantity: PropTypes.func.isRequired,
  handleNavigationClick: PropTypes.func.isRequired
};

export function CartProductList(props) {
  const isMobile = useMobile();
  return isMobile ? <MobileProductsView {...props} /> : <ProductsTable {...props} />;
}
