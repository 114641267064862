import { createContext, useCallback, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { getTenant } from 'src/redux/slices/tenant';
import { useDispatch } from 'src/redux/store';
// utils
import axios from '../utils/axios';
import { isValidToken, setSession } from '../utils/jwt';

const getUserWithFullName = (user) => {
  if (user) {
    const fullName = `${user.firstName} ${user.lastName}`;
    user.name = fullName;
    return user;
  }
  return user;
};

// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      // user: getUserWithFullName(user)
      user
    };
  },
  UPDATE: (state, action) => {
    const user = action.payload;
    return {
      ...state,
      user: getUserWithFullName(user)
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      user: getUserWithFullName(user)
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null
  })
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  isUsernameUnique: () => Promise.resolve()
});

function AuthProvider({ children }) {
  const [state, _dispatch] = useReducer(reducer, initialState);
  const dispatch = useDispatch();

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);

          const response = await axios.get('/manage/account');
          const user = response.data;

          // GET TENANT - BLOCKING
          await getTenant(dispatch);

          _dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user: getUserWithFullName(user)
            }
          });
        } else {
          _dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        _dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialize();
  }, [dispatch]);

  const login = async (username, password, rememberMe) => {
    const response = await axios.post('/website/account/login', {
      username,
      password,
      rememberMe
    });
    const { jwt, user } = response.data;
    setSession(jwt);

    // GET TENANT - BLOCKING
    await getTenant(dispatch);

    _dispatch({
      type: 'LOGIN',
      payload: {
        user
      }
    });
  };

  const isUsernameUnique = useCallback(async (username) => {
    const response = await axios.get(`/website/sign-up/${username}/validate`);
    return response.data;
  }, []);

  const logout = async () => {
    setSession(null);
    _dispatch({ type: 'LOGOUT' });

    // LOGOUT ROOT REDUCER AND RESET STATES. SEE ROOT REDUCER FOR MORE INFO
    dispatch({ type: 'LOGOUT' });
  };

  const updateUserGeneral = async (values) => {
    const { firstName, lastName, username, email, number } = values;
    const response = await axios.put('/manage/account/general', {
      firstName,
      lastName,
      username,
      email,
      number
    });

    _dispatch({
      type: 'UPDATE',
      payload: response.data
    });
  };

  const updateUserPassword = async (values) => {
    const { oldPassword, newPassword } = values;
    await axios.put('/manage/account/password', {
      oldPassword,
      newPassword
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        updateUserGeneral,
        updateUserPassword,
        isUsernameUnique
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };

AuthProvider.propTypes = {
  children: PropTypes.node
};
