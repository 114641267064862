import PropTypes from 'prop-types';
// material
import { visuallyHidden } from '@material-ui/utils';
import { Box, Checkbox, TableRow, TableCell, TableHead, TableSortLabel, Typography } from '@material-ui/core';
import RoleBasedComponent from 'src/guards/RoleBasedComponent';

// ----------------------------------------------------------------------

export default function SortableListHead({
  order,
  orderBy,
  rowCount,
  headLabel,
  numSelected = 0,
  onRequestSort,
  onSelectAllClick,
  showCheckbox = true,
  enableSorting = true
}) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <RoleBasedComponent>
          {showCheckbox && (
            <TableCell padding="checkbox">
              <Checkbox
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={onSelectAllClick}
              />
            </TableCell>
          )}
        </RoleBasedComponent>
        {headLabel.map((headCell) => {
          const { id: headCellId, alignRight, sortable, ...others } = headCell;
          const sortDirection = orderBy === headCellId ? order : false;
          const sortDirectionLabel = orderBy === headCellId ? order : 'asc';
          return (
            <TableCell
              key={headCellId}
              align={alignRight ? 'right' : 'left'}
              sortDirection={enableSorting && sortDirection}
              {...others}
            >
              {enableSorting && sortable ? (
                <TableSortLabel
                  active={orderBy === headCellId}
                  direction={sortDirectionLabel}
                  sx={{ width: '100%', justifyContent: 'space-between' }}
                  onClick={createSortHandler(headCellId)}
                >
                  {headCell.label}
                  {orderBy === headCellId ? (
                    <Box sx={{ ...visuallyHidden }}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</Box>
                  ) : null}
                </TableSortLabel>
              ) : (
                <Typography variant="inherit">{headCell.label}</Typography>
              )}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

SortableListHead.propTypes = {
  order: PropTypes.oneOf(['asc', 'desc']),
  orderBy: PropTypes.any,
  rowCount: PropTypes.number,
  headLabel: PropTypes.array,
  numSelected: PropTypes.number,
  onRequestSort: PropTypes.func,
  onSelectAllClick: PropTypes.func,
  showCheckbox: PropTypes.bool,
  enableSorting: PropTypes.bool
};
