import { Icon } from '@iconify/react';
import { IconButton, Link, Tooltip } from '@material-ui/core';
import settings2Fill from '@iconify/icons-eva/settings-2-fill';
import eyeFill from '@iconify/icons-eva/eye-fill';
import SvgIconStyle from '../../components/SvgIconStyle';
import { PATH_DASHBOARD } from '../../routes/paths';

// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

export const ICONS = {
  cart: getIcon('ic_cart'),
  user: getIcon('ic_user'),
  ecommerce: getIcon('ic_ecommerce'),
  booking: getIcon('ic_calendar'),
  analytics: getIcon('ic_analytics'),
  report: getIcon('ic_reports'),
  discount: getIcon('ic_discount'),
  messages: getIcon('ic_message'),
  support: getIcon('ic_support')
};

const getSidebarConfig = (storeUrl) => {
  return [
    // MANAGEMENT
    // ----------------------------------------------------------------------
    {
      subheader: 'management',
      items: [
        {
          title: 'orders',
          path: PATH_DASHBOARD.order.root,
          icon: ICONS.cart
        },
        {
          title: 'products',
          path: PATH_DASHBOARD.product.root,
          icon: ICONS.ecommerce,
          children: [
            { title: 'all products', path: PATH_DASHBOARD.product.root },
            { title: 'inventory', path: PATH_DASHBOARD.product.inventory },
            {
              title: 'collections',
              path: PATH_DASHBOARD.product.groups
            }
          ]
        },
        {
          title: 'analytics',
          path: PATH_DASHBOARD.reports.overview,
          icon: ICONS.analytics,
          children: [
            { title: 'overview', path: PATH_DASHBOARD.reports.overview },
            { title: 'reports', path: PATH_DASHBOARD.reports.root }
          ]
        },
        {
          title: 'bookings',
          path: PATH_DASHBOARD.booking.root,
          icon: ICONS.booking,
          children: [
            {
              title: 'all bookings',
              path: PATH_DASHBOARD.booking.bookings
            },
            {
              title: 'manage',
              path: PATH_DASHBOARD.booking.bookingDefinitions
            }
          ]
        },
        {
          title: 'customers',
          path: PATH_DASHBOARD.customer.root,
          icon: ICONS.user,
          children: [
            {
              title: 'all customers',
              path: PATH_DASHBOARD.customer.customers
            },
            {
              title: 'messages',
              path: PATH_DASHBOARD.messages.root
            }
          ]
        },
        {
          title: 'discounts',
          path: PATH_DASHBOARD.discount.root,
          icon: ICONS.discount
        }
      ]
    },

    // APP
    // ----------------------------------------------------------------------
    {
      subheader: 'store',
      items: [
        {
          title: 'online store',
          path: PATH_DASHBOARD.store.root,
          icon: ICONS.ecommerce,
          ...(storeUrl && {
            info: (
              <Tooltip title="Visit Store" enterDelay={0}>
                <Link component={IconButton} color="primary" href={storeUrl} target="_blank">
                  <Icon fontSize={20} icon={eyeFill} />
                </Link>
              </Tooltip>
            )
          }),
          children: [
            { title: 'manage design', path: PATH_DASHBOARD.store.root },
            { title: 'pages', path: PATH_DASHBOARD.store.pages },
            { title: 'navigations', path: PATH_DASHBOARD.store.links }
          ]
        },
        {
          title: 'settings',
          path: PATH_DASHBOARD.setting.root,
          icon: <Icon fontSize={20} icon={settings2Fill} />
        }
      ]
    }
  ];
};

export default getSidebarConfig;
