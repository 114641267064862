import React from 'react';
import { Box, Button, Typography, Stack } from '@material-ui/core';
import { alpha } from '@material-ui/core/styles';
import { motion } from 'framer-motion';
import { defaultTo, isEmpty } from 'lodash';
import { isInvalidText } from '../../utils/nullable';
import { varFadeInRight, varFadeInUp, MotionInView } from './animate';
import { createCustomShadowFromColor } from '../../theme/shadows';
import Markdown from './Markdown';

const SimpleTextBlockButton = ({ buttonText, buttonColour, buttonTextColour, onButtonClick }) => (
  <Button
    variant="contained"
    onClick={onButtonClick}
    sx={{
      ...(!isInvalidText(buttonTextColour) && { color: buttonTextColour }),
      ...(!isInvalidText(buttonColour) && {
        backgroundColor: buttonColour,
        boxShadow: createCustomShadowFromColor(buttonColour),
        '&.MuiButton-root:hover': {
          backgroundColor: buttonColour
        },

        '&.MuiButton-loading': {
          minWidth: '100px',
          backgroundColor: alpha(buttonColour, 0.24)
        },
        '&.Mui-disabled': {
          minWidth: '100px',
          backgroundColor: alpha(buttonColour, 0.24)
        }
      })
    }}
  >
    {buttonText}
  </Button>
);

const SimpleTextBlockInner = ({
  title,
  subtitle,
  caption,
  body,
  titleColour,
  subTitleColour,
  captionColour,
  bodyColour
}) => {
  return (
    <Stack spacing={1}>
      {!isEmpty(caption) && (
        <Typography
          variant="overline"
          sx={{
            textTransform: 'uppercase',
            color: (theme) => defaultTo(captionColour, theme.palette.text.secondary)
          }}
        >
          {caption}
        </Typography>
      )}
      {!isEmpty(title) && (
        <Typography variant="h3" sx={{ color: (theme) => defaultTo(titleColour, theme.palette.text.primary) }}>
          {title}
        </Typography>
      )}
      {!isEmpty(subtitle) && (
        <Typography variant="h6" sx={{ color: (theme) => defaultTo(subTitleColour, theme.palette.text.primary) }}>
          {subtitle}
        </Typography>
      )}
      {!isEmpty(body) && (
        <Typography variant="body2" sx={{ color: (theme) => defaultTo(bodyColour, theme.palette.text.primary) }}>
          {body}
        </Typography>
      )}
    </Stack>
  );
};
export const CarouselTextBlock = (props) => {
  const { buttonText, position } = props;
  return (
    <Stack spacing={3} sx={{ textAlign: position }}>
      <motion.div variants={varFadeInRight}>
        <SimpleTextBlockInner {...props} />
      </motion.div>

      {!isEmpty(buttonText) && (
        <motion.div variants={varFadeInRight}>
          <SimpleTextBlockButton {...props} />
        </motion.div>
      )}
    </Stack>
  );
};

export const SimpleTextBlock = (props) => {
  const { verticalAnimation: verticalAnimationRoot, buttonText, position } = props;
  const verticalAnimation = defaultTo(verticalAnimationRoot, true);
  return (
    <MotionInView variants={verticalAnimation ? varFadeInUp : varFadeInRight}>
      <Stack spacing={3} sx={{ textAlign: position }}>
        <SimpleTextBlockInner {...props} />
        {!isEmpty(buttonText) && (
          <Box>
            <SimpleTextBlockButton {...props} />
          </Box>
        )}
      </Stack>
    </MotionInView>
  );
};

export const SimpleMarkdownTextBlock = ({
  verticalAnimation: verticalAnimationRoot,
  showHeading: showHeadingRoot,
  title,
  subtitle,
  caption,
  body,
  position,
  titleColour,
  subTitleColour,
  captionColour,
  buttonText,
  buttonColour,
  buttonTextColour,
  onButtonClick
}) => {
  const verticalAnimation = defaultTo(verticalAnimationRoot, true);
  const showHeading = defaultTo(showHeadingRoot, true);
  return (
    <Stack spacing={1} sx={{ textAlign: position, width: '100%' }}>
      <MotionInView variants={verticalAnimation ? varFadeInUp : varFadeInRight}>
        {showHeading && (
          <SimpleTextBlockInner
            title={title}
            subtitle={subtitle}
            caption={caption}
            titleColour={titleColour}
            subTitleColour={subTitleColour}
            captionColour={captionColour}
          />
        )}
      </MotionInView>
      {!isEmpty(body) && (
        <Box sx={{ width: '100%' }}>
          <Markdown children={body} />
        </Box>
      )}
      {!isEmpty(buttonText) && (
        <MotionInView variants={verticalAnimation ? varFadeInUp : varFadeInRight}>
          <Box sx={{ justifyContent: position, mt: (theme) => theme.spacing(2) }}>
            <SimpleTextBlockButton
              buttonText={buttonText}
              buttonColour={buttonColour}
              buttonTextColour={buttonTextColour}
              onButtonClick={onButtonClick}
            />
          </Box>
        </MotionInView>
      )}
    </Stack>
  );
};
