import * as Yup from 'yup';
import { styled } from '@material-ui/core/styles';
import { DEFAULT_FONT_PRIMARY, DEFAULT_FONT_SIZES } from '@iamilyas/store-template-library';
import { useSnackbar } from 'notistack5';
import { Form, FormikProvider, useFormik } from 'formik';
import {
  Box,
  Typography,
  Stack,
  Divider,
  Button,
  FormHelperText,
  TextField,
  MenuItem,
  Slider
} from '@material-ui/core';
import { paramCase } from 'change-case';
import { defaultTo } from 'lodash';
import { AVAILABLE_FONTS } from '../../utils/typography';
import {
  SLIDER_HEADER_FONT_SIZE_MIN,
  SLIDER_HEADER_FONT_SIZE_MAX,
  SLIDER_TEXT_FONT_SIZE_MIN,
  SLIDER_TEXT_FONT_SIZE_MAX
} from '../../utils/constants';

const EditorConatiner = styled(Box)(({ theme }) => ({
  padding: theme.spacing(5)
}));

const SliderWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  border: `solid 1px ${theme.palette.divider}`
}));

const TextExampleView = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.grey[200],
  border: `2px solid ${theme.palette.divider}`,
  width: '100%',
  borderRadius: theme.shape.borderRadiusSm,
  padding: theme.spacing(3),
  textAlign: 'center'
}));

const ExampleTypography = styled('p')(() => ({
  fontSize: '30px'
}));

// These are the font weights used by the template library
const ExampleFontView = ({ font }) => {
  return (
    <TextExampleView>
      <Stack spacing={2} direction="column">
        <ExampleTypography sx={{ fontWeight: 400, fontFamily: font }}>{font} Light</ExampleTypography>
        <ExampleTypography sx={{ fontWeight: 600, fontFamily: font }}>{font} Medium</ExampleTypography>
        <ExampleTypography sx={{ fontWeight: 700, fontFamily: font }}>{font} Bold</ExampleTypography>
      </Stack>
    </TextExampleView>
  );
};

const getFontFamily = (fontFamily) =>
  fontFamily && AVAILABLE_FONTS.find((_font) => paramCase(_font) === paramCase(fontFamily));

export default function TextFontEditor({ config, heading, closeEdit, handleUpdateComponent }) {
  const { enqueueSnackbar } = useSnackbar();
  const mappedFontFamily = getFontFamily(config?.fontFamily);

  const TextFontSchema = Yup.object().shape({});

  const form = useFormik({
    initialValues: {
      fontFamily: defaultTo(mappedFontFamily, DEFAULT_FONT_PRIMARY),
      sizes: {
        h1: defaultTo(config?.sizes?.h1, DEFAULT_FONT_SIZES.h1),
        h2: defaultTo(config?.sizes?.h2, DEFAULT_FONT_SIZES.h2),
        h3: defaultTo(config?.sizes?.h3, DEFAULT_FONT_SIZES.h3),
        h4: defaultTo(config?.sizes?.h4, DEFAULT_FONT_SIZES.h4),
        h5: defaultTo(config?.sizes?.h5, DEFAULT_FONT_SIZES.h5),
        h6: defaultTo(config?.sizes?.h6, DEFAULT_FONT_SIZES.h6),
        textLarge: defaultTo(config?.sizes?.textLarge, DEFAULT_FONT_SIZES.textLarge),
        textMedium: defaultTo(config?.sizes?.textMedium, DEFAULT_FONT_SIZES.textMedium),
        textSmall: defaultTo(config?.sizes?.textSmall, DEFAULT_FONT_SIZES.textSmall)
      }
    },
    validationSchema: TextFontSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        handleUpdateComponent(values);
        enqueueSnackbar('Saved', { variant: 'success' });
        setSubmitting(false);
      } catch (error) {
        console.error(error);
        setSubmitting(false);
      }
    }
  });

  const { initialValues, values, touched, errors, getFieldProps, resetForm, handleSubmit } = form;

  return (
    <FormikProvider value={form}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <EditorConatiner>
          <Stack spacing={3}>
            <Stack spacing={2} justifyContent="flex-end" direction="row">
              <Button variant="outlined" color="primary" onClick={resetForm} disabled={initialValues === values}>
                Reset
              </Button>
              <Box sx={{ flexGrow: 1 }} />
              <Button variant="outlined" color="primary" onClick={closeEdit}>
                Cancel
              </Button>
              <Button variant="contained" color="primary" type="submit">
                Save
              </Button>
            </Stack>
            <Typography variant="h5">{heading}</Typography>

            <Divider />

            <ExampleFontView font={values.fontFamily} />

            <TextField select {...getFieldProps('fontFamily')}>
              {AVAILABLE_FONTS.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>

            <Stack spacing={2}>
              <Typography variant="body1">
                <strong>Header Sizes</strong>
              </Typography>

              <SliderWrapper>
                <Typography variant="body2" sx={{ mb: 1 }}>
                  Header 1
                </Typography>
                <Stack direction="row">
                  <Typography variant="body2">{SLIDER_HEADER_FONT_SIZE_MIN}</Typography>
                  <Slider
                    defaultValue={SLIDER_HEADER_FONT_SIZE_MIN}
                    {...getFieldProps('sizes.h1')}
                    valueLabelDisplay="auto"
                    step={1}
                    marks
                    min={SLIDER_HEADER_FONT_SIZE_MIN}
                    max={SLIDER_HEADER_FONT_SIZE_MAX}
                    sx={{ ml: 2, mr: 2 }}
                  />
                  <Typography variant="body2">{SLIDER_HEADER_FONT_SIZE_MAX}</Typography>
                </Stack>
                {touched.sizes?.h1 && errors.sizes?.h1 && (
                  <FormHelperText error>{touched.sizes?.h1 && errors.sizes?.h1}</FormHelperText>
                )}
              </SliderWrapper>

              <SliderWrapper>
                <Typography variant="body2" sx={{ mb: 1 }}>
                  Header 2
                </Typography>
                <Stack direction="row">
                  <Typography variant="body2">{SLIDER_HEADER_FONT_SIZE_MIN}</Typography>
                  <Slider
                    defaultValue={SLIDER_HEADER_FONT_SIZE_MIN}
                    {...getFieldProps('sizes.h2')}
                    valueLabelDisplay="auto"
                    step={1}
                    marks
                    min={SLIDER_HEADER_FONT_SIZE_MIN}
                    max={SLIDER_HEADER_FONT_SIZE_MAX}
                    sx={{ ml: 2, mr: 2 }}
                  />
                  <Typography variant="body2">{SLIDER_HEADER_FONT_SIZE_MAX}</Typography>
                </Stack>
                {touched.sizes?.h2 && errors.sizes?.h2 && (
                  <FormHelperText error>{touched.sizes?.h2 && errors.sizes?.h2}</FormHelperText>
                )}
              </SliderWrapper>

              <SliderWrapper>
                <Typography variant="body2" sx={{ mb: 1 }}>
                  Header 3
                </Typography>
                <Stack direction="row">
                  <Typography variant="body2">{SLIDER_HEADER_FONT_SIZE_MIN}</Typography>
                  <Slider
                    defaultValue={SLIDER_HEADER_FONT_SIZE_MIN}
                    {...getFieldProps('sizes.h3')}
                    valueLabelDisplay="auto"
                    step={1}
                    marks
                    min={SLIDER_HEADER_FONT_SIZE_MIN}
                    max={SLIDER_HEADER_FONT_SIZE_MAX}
                    sx={{ ml: 2, mr: 2 }}
                  />
                  <Typography variant="body2">{SLIDER_HEADER_FONT_SIZE_MAX}</Typography>
                </Stack>
                {touched.sizes?.h3 && errors.sizes?.h3 && (
                  <FormHelperText error>{touched.sizes?.h3 && errors.sizes?.h3}</FormHelperText>
                )}
              </SliderWrapper>

              <SliderWrapper>
                <Typography variant="body2" sx={{ mb: 1 }}>
                  Header 4
                </Typography>
                <Stack direction="row">
                  <Typography variant="body2">{SLIDER_HEADER_FONT_SIZE_MIN}</Typography>
                  <Slider
                    defaultValue={SLIDER_HEADER_FONT_SIZE_MIN}
                    {...getFieldProps('sizes.h4')}
                    valueLabelDisplay="auto"
                    step={1}
                    marks
                    min={SLIDER_HEADER_FONT_SIZE_MIN}
                    max={SLIDER_HEADER_FONT_SIZE_MAX}
                    sx={{ ml: 2, mr: 2 }}
                  />
                  <Typography variant="body2">{SLIDER_HEADER_FONT_SIZE_MAX}</Typography>
                </Stack>
                {touched.sizes?.h4 && errors.sizes?.h4 && (
                  <FormHelperText error>{touched.sizes?.h4 && errors.sizes?.h4}</FormHelperText>
                )}
              </SliderWrapper>

              <SliderWrapper>
                <Typography variant="body2" sx={{ mb: 1 }}>
                  Header 5
                </Typography>
                <Stack direction="row">
                  <Typography variant="body2">{SLIDER_HEADER_FONT_SIZE_MIN}</Typography>
                  <Slider
                    defaultValue={SLIDER_HEADER_FONT_SIZE_MIN}
                    {...getFieldProps('sizes.h5')}
                    valueLabelDisplay="auto"
                    step={1}
                    marks
                    min={SLIDER_HEADER_FONT_SIZE_MIN}
                    max={SLIDER_HEADER_FONT_SIZE_MAX}
                    sx={{ ml: 2, mr: 2 }}
                  />
                  <Typography variant="body2">{SLIDER_HEADER_FONT_SIZE_MAX}</Typography>
                </Stack>
                {touched.sizes?.h5 && errors.sizes?.h5 && (
                  <FormHelperText error>{touched.sizes?.h5 && errors.sizes?.h5}</FormHelperText>
                )}
              </SliderWrapper>

              <SliderWrapper>
                <Typography variant="body2" sx={{ mb: 1 }}>
                  Header 6
                </Typography>
                <Stack direction="row">
                  <Typography variant="body2">{SLIDER_HEADER_FONT_SIZE_MIN}</Typography>
                  <Slider
                    defaultValue={SLIDER_HEADER_FONT_SIZE_MIN}
                    {...getFieldProps('sizes.h6')}
                    valueLabelDisplay="auto"
                    step={1}
                    marks
                    min={SLIDER_HEADER_FONT_SIZE_MIN}
                    max={SLIDER_HEADER_FONT_SIZE_MAX}
                    sx={{ ml: 2, mr: 2 }}
                  />
                  <Typography variant="body2">{SLIDER_HEADER_FONT_SIZE_MAX}</Typography>
                </Stack>
                {touched.sizes?.h6 && errors.sizes?.h6 && (
                  <FormHelperText error>{touched.sizes?.h6 && errors.sizes?.h6}</FormHelperText>
                )}
              </SliderWrapper>
            </Stack>

            <Stack spacing={2}>
              <Typography variant="body1">
                <strong>Text Sizes</strong>
              </Typography>

              <SliderWrapper>
                <Typography variant="body2" sx={{ mb: 1 }}>
                  Text Large
                </Typography>
                <Stack direction="row">
                  <Typography variant="body2">{SLIDER_TEXT_FONT_SIZE_MIN}</Typography>
                  <Slider
                    defaultValue={SLIDER_TEXT_FONT_SIZE_MIN}
                    {...getFieldProps('sizes.textLarge')}
                    valueLabelDisplay="auto"
                    step={1}
                    marks
                    min={SLIDER_TEXT_FONT_SIZE_MIN}
                    max={SLIDER_TEXT_FONT_SIZE_MAX}
                    sx={{ ml: 2, mr: 2 }}
                  />
                  <Typography variant="body2">{SLIDER_TEXT_FONT_SIZE_MAX}</Typography>
                </Stack>
                {touched.sizes?.textLarge && errors.sizes?.textLarge && (
                  <FormHelperText error>{touched.sizes?.textLarge && errors.sizes?.textLarge}</FormHelperText>
                )}
              </SliderWrapper>

              <SliderWrapper>
                <Typography variant="body2" sx={{ mb: 1 }}>
                  Text Medium
                </Typography>
                <Stack direction="row">
                  <Typography variant="body2">{SLIDER_TEXT_FONT_SIZE_MIN}</Typography>
                  <Slider
                    defaultValue={SLIDER_TEXT_FONT_SIZE_MIN}
                    {...getFieldProps('sizes.textMedium')}
                    valueLabelDisplay="auto"
                    step={1}
                    marks
                    min={SLIDER_TEXT_FONT_SIZE_MIN}
                    max={SLIDER_TEXT_FONT_SIZE_MAX}
                    sx={{ ml: 2, mr: 2 }}
                  />
                  <Typography variant="body2">{SLIDER_TEXT_FONT_SIZE_MAX}</Typography>
                </Stack>
                {touched.sizes?.textMedium && errors.sizes?.textMedium && (
                  <FormHelperText error>{touched.sizes?.textMedium && errors.sizes?.textMedium}</FormHelperText>
                )}
              </SliderWrapper>

              <SliderWrapper>
                <Typography variant="body2" sx={{ mb: 1 }}>
                  Text Small
                </Typography>
                <Stack direction="row">
                  <Typography variant="body2">{SLIDER_TEXT_FONT_SIZE_MIN}</Typography>
                  <Slider
                    defaultValue={SLIDER_TEXT_FONT_SIZE_MIN}
                    {...getFieldProps('sizes.textSmall')}
                    valueLabelDisplay="auto"
                    step={1}
                    marks
                    min={SLIDER_TEXT_FONT_SIZE_MIN}
                    max={SLIDER_TEXT_FONT_SIZE_MAX}
                    sx={{ ml: 2, mr: 2 }}
                  />
                  <Typography variant="body2">{SLIDER_TEXT_FONT_SIZE_MAX}</Typography>
                </Stack>
                {touched.sizes?.textSmall && errors.sizes?.textSmall && (
                  <FormHelperText error>{touched.sizes?.textSmall && errors.sizes?.textSmall}</FormHelperText>
                )}
              </SliderWrapper>
            </Stack>
          </Stack>
        </EditorConatiner>
      </Form>
    </FormikProvider>
  );
}
