import React from 'react';
import { Stack, Skeleton } from '@material-ui/core';

export const TitleHalfWidth = ({ sx }) => <Skeleton variant="text" sx={{ height: 35, width: '50%', ...sx }} />;

const TitleFullWidth = ({ sx }) => <Skeleton variant="text" sx={{ height: 35, ...sx }} />;

export const SkeletonBookingSummary = (props) => (
  <Stack spacing={0} direction="column" {...props}>
    <TitleHalfWidth />
    <TitleFullWidth />
  </Stack>
);
