import React from 'react';
import { Box } from '@material-ui/core';
import { Logo } from '../global/Logo';

export const BookingHeading = ({ logo, handleNavigationClick }) => {
  return (
    <Box>
      <Logo
        logo={logo?.desktopLogo}
        mobileLogo={logo?.mobileLogo}
        handleNavigationClick={handleNavigationClick}
        containerSx={{ flexGrow: 0 }}
      />
    </Box>
  );
};
