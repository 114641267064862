import { Box, Typography, Stack, CardActionArea } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';

const shapes = (extended) => {
  const arr = [
    {
      key: 'ROUNDED',
      name: 'Round',
      shapeComponent: <RoundedShape />
    },
    {
      key: 'SQUARED',
      name: 'Square',
      shapeComponent: <SquareShape />
    }
  ];
  if (extended) {
    arr.push({
      key: 'OVAL',
      name: 'Oval',
      shapeComponent: <OvalShape />
    });
  }
  return arr;
};

const SquareShape = styled(Box)(({ theme }) => ({
  display: 'inline-block',
  borderRadius: 0,
  height: 40,
  width: 40,
  border: `2px dashed ${theme.palette.primary.main}`
}));

const RoundedShape = styled(Box)(({ theme }) => ({
  display: 'inline-block',
  borderRadius: 0,
  height: 40,
  width: 40,
  border: `2px dashed ${theme.palette.primary.main}`,
  borderTopRightRadius: '90%'
}));

const OvalShape = styled(Box)(({ theme }) => ({
  display: 'inline-block',
  borderRadius: 25,
  height: 25,
  width: 60,
  border: `2px dashed ${theme.palette.primary.main}`
}));

const StyledCardActionArea = styled(CardActionArea)(({ theme }) => ({
  borderRadius: theme.shape.borderRadiusSm,
  padding: theme.spacing(3)
}));

const ShapeOption = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.grey[200],
  border: `2px solid ${theme.palette.divider}`,
  width: '100%',
  borderRadius: theme.shape.borderRadiusSm,
  padding: 0
}));

export const ShapePicker = ({ extended = false, value, onChangeShape }) => {
  return (
    <Stack spacing={2} direction="row">
      {shapes(extended).map((shape, index) => {
        const { key, name, shapeComponent } = shape;
        return (
          <ShapeOption
            key={index}
            sx={{ ...(key === value && { border: (theme) => `2px solid ${theme.palette.primary.main}` }) }}
          >
            <StyledCardActionArea onClick={() => onChangeShape(key)} sx={{ height: '100%' }}>
              <Box sx={{ textAlign: 'center', width: '100%', color: (theme) => theme.palette.primary.main }}>
                {shapeComponent && <Box sx={{ width: '100%' }}>{shapeComponent}</Box>}
                <Typography variant="subtitle2" sx={{ color: 'text.primary', mt: (theme) => theme.spacing(1) }}>
                  {name}
                </Typography>
              </Box>
            </StyledCardActionArea>
          </ShapeOption>
        );
      })}
    </Stack>
  );
};
