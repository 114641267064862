import { Box, Typography, Stack, Divider, FormHelperText } from '@material-ui/core';
import { OrientationViewPicker } from '../../../components/pickers/OrientationViewPicker';
import { FooterNavigationViewPicker } from '../../../components/pickers/FooterNavigationViewPicker';
import { GENERIC_CONTENT_POSITIONS } from '../../../utils/constantsEditor';
import MultiOptionPicker from '../../../components/pickers/MultiOptionPicker';

export default function FooterOrientationEditorCommon({ form }) {
  const { values, errors, touched, setFieldValue } = form;

  return (
    <>
      <Divider />

      <Typography variant="body1">
        <strong>View Direction</strong>
      </Typography>

      <Stack spacing={2}>
        <Typography variant="overline" color="text.secondary">
          <strong>Desktop</strong>
        </Typography>

        <Box>
          <OrientationViewPicker
            value={values.switchDesktopOrientation}
            onChange={(newValue) => setFieldValue('switchDesktopOrientation', newValue)}
            sx={{ mb: (theme) => theme.spacing(2) }}
          />
        </Box>
      </Stack>

      <Divider />

      <Typography variant="body1">
        <strong>Navigation View</strong>
      </Typography>

      <Stack spacing={2}>
        <Typography variant="overline" color="text.secondary">
          <strong>Mobile</strong>
        </Typography>

        <Box>
          <FooterNavigationViewPicker
            value={values.mobileNavigationItemView}
            onChange={(newValue) => setFieldValue('mobileNavigationItemView', newValue)}
            sx={{ mb: (theme) => theme.spacing(2) }}
          />
          {errors.mobileNavigationItemView && <FormHelperText error>{errors.mobileNavigationItemView}</FormHelperText>}
        </Box>
      </Stack>

      <Divider />

      <Stack spacing={2}>
        <Typography variant="body1">
          <strong>Navigation Position</strong>
        </Typography>

        <Stack spacing={2}>
          <Typography variant="overline" color="text.secondary">
            <strong>Desktop</strong>
          </Typography>
          <MultiOptionPicker
            data={GENERIC_CONTENT_POSITIONS}
            value={values.navigationPosition}
            onChange={(_, newValue) => setFieldValue('navigationPosition', newValue)}
          />
        </Stack>
      </Stack>

      <Stack spacing={2}>
        <Typography variant="body1">
          <strong>Text Position</strong>
        </Typography>

        <Stack spacing={2}>
          <Typography variant="overline" color="text.secondary">
            <strong>Desktop</strong>
          </Typography>
          <MultiOptionPicker
            data={GENERIC_CONTENT_POSITIONS}
            value={values.textPosition}
            onChange={(_, newValue) => setFieldValue('textPosition', newValue)}
          />
          {touched.textPosition && errors.textPosition && (
            <FormHelperText error>{touched.textPosition && errors.textPosition}</FormHelperText>
          )}
        </Stack>

        <Stack spacing={2}>
          <Typography variant="overline" color="text.secondary">
            <strong>Mobile</strong>
          </Typography>
          <MultiOptionPicker
            data={GENERIC_CONTENT_POSITIONS}
            value={values.mobileTextPosition}
            onChange={(_, newValue) => setFieldValue('mobileTextPosition', newValue)}
          />
          {touched.mobileTextPosition && errors.mobileTextPosition && (
            <FormHelperText error>{touched.mobileTextPosition && errors.mobileTextPosition}</FormHelperText>
          )}
          <FormHelperText>For mobile, all text and components inside the footer be vertically adjusted</FormHelperText>
        </Stack>
      </Stack>
    </>
  );
}
