import React from 'react';
import { defaultTo, isEmpty } from 'lodash';
import { Box, Stack, Typography } from '@material-ui/core';
import { DEFAULT_PRODUCT_NOTE_TITLE, MAXIMUM_NOTE_WORD_LENGTH } from '../../../utils/constants';
import MTextField from '../@material-extend/MTextField';
import { getTextAlignPosition } from '../../../utils/getPositions';

const WordCounter = ({ value }) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'end' }}>
      <Typography variant="caption" sx={{ color: 'text.secondary' }}>
        Maximum {MAXIMUM_NOTE_WORD_LENGTH} characters - {value.length} characters
      </Typography>
    </Box>
  );
};

export const ProductNote = ({ disabled, defaultNote, title, shape, position, getFieldProps, touched, errors }) => {
  const fieldProps = getFieldProps('note');
  return (
    <Stack spacing={1} sx={{ width: '100%', ...(position && { alignItems: getTextAlignPosition(position) }) }}>
      <MTextField
        shape={shape}
        disabled={disabled}
        fullWidth
        multiline
        minRows={3}
        maxRows={15}
        autoComplete="off"
        label={defaultTo(title, DEFAULT_PRODUCT_NOTE_TITLE)}
        {...fieldProps}
        {...(isEmpty(fieldProps.value) && { value: defaultTo(defaultNote, fieldProps.value) })}
        error={Boolean(touched.note && errors.note)}
        helperText={touched.note && errors.note}
      />
      <WordCounter value={getFieldProps('note').value} />
    </Stack>
  );
};
