import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import {
  BOOKING_SUCCESS_STATUS,
  BOOKING_UNKNOWN_FAILURE_STATUS,
  DEFAULT_BOOKING_LOADING_SUBMIT_MESSAGE,
  DEFAULT_BOOKING_LOADING_SUMMARY_MESSAGE
} from '../../utils/constants';
import { BookingLocationsPage } from './pages/BookingLocationsPage';
import { BookingServicesPage } from './pages/BookingServicesPage';
import { BookingAvailabilityPage } from './pages/BookingAvailabilityPage';
import { BookingInformationPage } from './pages/BookingInformationPage';
import { BookingSuccessPage } from './pages/complete/BookingSuccessPage';
import { BookingFailurePage } from './pages/complete/BookingFailurePage';
import { getActiveStep } from '../../utils/bookingUtils';
import { Loader } from '../core/Loader';

const RootStyle = styled(Box)(({ theme }) => ({
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1)
}));

export const BookingPage = ({ page, loading, isComplete, status, config, ...others }) => {
  const loadingSummary = config?.general?.bookingSummaryLoadingMessage;
  const loadingSubmit = config?.general?.submitBookingLoadingMessage;
  const { summary: isSummaryLoading, booking: isSubmitting } = loading;
  const summaryMessage = loadingSummary || DEFAULT_BOOKING_LOADING_SUMMARY_MESSAGE;
  const submitMessage = loadingSubmit || DEFAULT_BOOKING_LOADING_SUBMIT_MESSAGE;

  if (isSummaryLoading) {
    return <Loader message={summaryMessage} sx={{ height: '200px' }} />;
  }

  if (isSubmitting) {
    return <Loader message={submitMessage} sx={{ height: '200px' }} />;
  }

  if (isComplete) {
    return (
      <RootStyle>
        {status === BOOKING_SUCCESS_STATUS && <BookingSuccessPage config={config} />}
        {status === BOOKING_UNKNOWN_FAILURE_STATUS && <BookingFailurePage config={config} />}
      </RootStyle>
    );
  }

  const pageName = getActiveStep(page);

  return (
    <>
      {pageName && (
        <Typography variant="h6" color="primary" sx={{ textAlign: 'start', px: 1, mb: 2 }}>
          {pageName}
        </Typography>
      )}
      <RootStyle>
        {page === 0 && <BookingLocationsPage {...others} />}
        {page === 1 && <BookingServicesPage {...others} />}
        {page === 2 && <BookingAvailabilityPage {...others} />}
        {page === 3 && <BookingInformationPage config={config} {...others} />}
      </RootStyle>
    </>
  );
};
