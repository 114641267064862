import { useSnackbar } from 'notistack5';
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import { Typography, Stack, Button } from '@material-ui/core';
import { defaultTo } from 'lodash';
import { REQUIRED_OPTION_MESSAGE } from '../../../utils/constants';
import FooterEditorCommon from './FooterEditorCommon';
import { EditorConatiner } from '../../../common/EditorConatiner';

export default function FooterEditor({ heading, config, closeEdit, options, handleUpdateComponent }) {
  const { enqueueSnackbar } = useSnackbar();
  const showTitle = config?.showTitle;
  const showLogo = config?.showLogo;
  const enableNavigation = config?.enableNavigation;
  const title = config?.title;
  const description = config?.description;
  const navigationLinkIds = config?.navigationLinkIds;
  const showAddress = config?.showAddress;
  const addressHeadingText = config?.addressHeadingText;
  const showEmail = config?.showEmail;
  const emailHeadingText = config?.emailHeadingText;
  const showRegistrationNo = config?.showRegistrationNo;
  const registrationHeadingText = config?.registrationHeadingText;
  const showStoreNameFooter = config?.showStoreNameFooter;
  const additionalNotes = config?.additionalNotes;
  const showPaymentMethods = config?.showPaymentMethods;
  const socialLinks = config?.socialLinks;

  const FooterSchema = Yup.object().shape({
    showTitle: Yup.boolean().required(REQUIRED_OPTION_MESSAGE),
    showLogo: Yup.boolean().required(REQUIRED_OPTION_MESSAGE),
    enableNavigation: Yup.boolean().required(REQUIRED_OPTION_MESSAGE),
    showAddress: Yup.boolean().required(REQUIRED_OPTION_MESSAGE),
    showEmail: Yup.boolean().required(REQUIRED_OPTION_MESSAGE),
    showRegistrationNo: Yup.boolean().required(REQUIRED_OPTION_MESSAGE),
    showStoreNameFooter: Yup.boolean().required(REQUIRED_OPTION_MESSAGE),
    showPaymentMethods: Yup.boolean().required(REQUIRED_OPTION_MESSAGE),
    navigationLinkIds: Yup.array()
      .test('Maximum Size', 'Navigations size cannot be greater than 5', (val) => val.length <= 5)
      .required(REQUIRED_OPTION_MESSAGE)
  });

  const form = useFormik({
    initialValues: {
      showTitle: defaultTo(showTitle, true),
      showLogo: defaultTo(showLogo, true),
      enableNavigation: defaultTo(enableNavigation, true),
      title: defaultTo(title, ''),
      description: defaultTo(description, ''),
      navigationLinkIds: defaultTo(navigationLinkIds, []),
      showAddress: defaultTo(showAddress, true),
      addressHeadingText: defaultTo(addressHeadingText, ''),
      showEmail: defaultTo(showEmail, true),
      emailHeadingText: defaultTo(emailHeadingText, ''),
      showRegistrationNo: defaultTo(showRegistrationNo, true),
      registrationHeadingText: defaultTo(registrationHeadingText, ''),
      showStoreNameFooter: defaultTo(showStoreNameFooter, true),
      additionalNotes: defaultTo(additionalNotes, ''),
      showPaymentMethods: defaultTo(showPaymentMethods, true),
      socialLinks: defaultTo(socialLinks, [])
    },
    validationSchema: FooterSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        handleUpdateComponent({
          ...config,
          ...values
        });
        enqueueSnackbar('Saved', { variant: 'success' });
        setSubmitting(false);
      } catch (error) {
        console.error(error);
        setSubmitting(false);
      }
    }
  });

  const { handleSubmit } = form;

  return (
    <FormikProvider value={form}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <EditorConatiner>
          <Stack spacing={3}>
            <Stack spacing={2} justifyContent="flex-end" direction="row">
              <Button variant="outlined" color="primary" onClick={closeEdit}>
                Cancel
              </Button>
              <Button variant="contained" color="primary" type="submit">
                Save
              </Button>
            </Stack>
            <Typography variant="h5">{heading}</Typography>

            <FooterEditorCommon form={form} options={options} />
          </Stack>
        </EditorConatiner>
      </Form>
    </FormikProvider>
  );
}
