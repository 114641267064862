import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { useRef, useState } from 'react';
import editFill from '@iconify/icons-eva/edit-fill';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText, Tooltip } from '@material-ui/core';
import RoleBasedComponent from 'src/guards/RoleBasedComponent';

// ----------------------------------------------------------------------

export default function ListMoreMenu({ onDelete, onEdit, showEdit = true }) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Tooltip title="More">
        <IconButton
          ref={ref}
          onClick={(e) => {
            e.stopPropagation();
            setIsOpen(true);
          }}
        >
          <Icon icon={moreVerticalFill} width={20} height={20} />
        </IconButton>
      </Tooltip>
      <Menu
        onClick={(e) => {
          setIsOpen(false);
          e.stopPropagation();
        }}
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {showEdit && (
          <MenuItem onClick={onEdit} sx={{ color: 'text.secondary' }}>
            <ListItemIcon sx={{ mr: 1 }}>
              <Icon icon={editFill} width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="Edit" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        )}

        <RoleBasedComponent>
          <MenuItem onClick={onDelete} sx={{ color: 'text.secondary' }}>
            <ListItemIcon sx={{ mr: 1 }}>
              <Icon icon={trash2Outline} width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="Delete" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        </RoleBasedComponent>
      </Menu>
    </>
  );
}

ListMoreMenu.propTypes = {
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  showEdit: PropTypes.bool
};
