import React from 'react';
import { styled } from '@material-ui/core/styles';
import { Box, Stack } from '@material-ui/core';
import * as AspectRatioPrimitive from '@radix-ui/react-aspect-ratio';
import { defaultTo } from 'lodash';
import { getHorizontalPosition, getImagePosition, getVerticalPosition } from '../../../utils/getPositions';
import { MLazyLoadImage } from '../../core/@react-lazy-load-image-component-extend';
import placeholderImage from '../../../static/placeholder/Image_and_text.jpg';
import LayoutOrientation from '../../core/LayoutOrientation';
import { getSelectedImage } from '../../../utils/getSelectedImage';
import { SimpleMarkdownTextBlock } from '../../core/SimpleTextBlock';
import { createOverlayWithSpread } from '../../../utils/createOverlay';
import { DEFAULT_IMAGE_HEIGHT, IMAGE_AND_TEXT_VIEW_TYPE } from '../../../utils/constants';
import { isInvalidText } from '../../../utils/nullable';

export const AspectRatio = AspectRatioPrimitive;

const HEIGHT_CONSTRAINT_STYLE = {
  minHeight: '400px',
  maxHeight: '850px'
};

const ComponentContainer = styled(Box)(() => ({
  position: 'relative',
  overflowY: 'hidden'
}));

const ImageWrapper = styled(Box)(() => ({
  position: 'relative',
  ...HEIGHT_CONSTRAINT_STYLE
}));

const ImageContainer = styled(MLazyLoadImage)(() => ({
  width: '100%',
  height: '100%',
  ...HEIGHT_CONSTRAINT_STYLE
}));

const BackgroundOverlay = styled(Box)(() => ({
  top: 0,
  zIndex: 2,
  width: '100%',
  height: '100%',
  position: 'absolute'
}));

const Content = styled(Box)(({ theme }) => ({
  top: 0,
  bottom: 0,
  zIndex: 3,
  width: '100%',
  position: 'absolute',
  padding: theme.spacing(10)
}));

const ImageAndTextCommon = ({ verticalAnimation, isMobile, component, handleNavigationClick }) => {
  const {
    title,
    contentPosition: contentPositionDesktop,
    mobileContentPosition: contentPositionMobile,
    viewType: viewTypeRoot,
    showOverlay,
    overlayDirection,
    overlaySpread,
    overlayColourFirst: overlayColourFirstRoot,
    overlayColourSecond: overlayColourSecondRoot,
    mobileFixedImageHeight,
    mobileFixedImageHeightValue,
    mobileFixedImageHeightPosition,
    fixedImageHeight,
    fixedImageHeightValue,
    fixedImageHeightPosition,
    desktopImage,
    mobileImage,
    navigation
  } = component;
  const contentPosition = isMobile ? defaultTo(contentPositionMobile, contentPositionDesktop) : contentPositionDesktop;
  const viewType = defaultTo(viewTypeRoot, IMAGE_AND_TEXT_VIEW_TYPE[0]);
  const fixedHeight = isMobile ? mobileFixedImageHeight : fixedImageHeight;
  const imageHeight = isMobile ? mobileFixedImageHeightValue : fixedImageHeightValue;
  const imageHeightPosition = isMobile ? mobileFixedImageHeightPosition : fixedImageHeightPosition;

  const img = getSelectedImage(isMobile, desktopImage, mobileImage);

  return (
    <ComponentContainer>
      <Stack spacing={0} direction="column">
        <ImageWrapper>
          {fixedHeight ? (
            <ImageContainer
              alt={defaultTo(title, '')}
              src={img || placeholderImage}
              imgSx={{
                height: defaultTo(imageHeight, DEFAULT_IMAGE_HEIGHT),
                borderRadius: 0,
                objectPosition: getImagePosition(imageHeightPosition)
              }}
            />
          ) : (
            <AspectRatio.Root ratio={isMobile ? 3 / 4 : 16 / 9}>
              <ImageContainer alt={defaultTo(title, '')} src={img || placeholderImage} imgSx={{ borderRadius: 0 }} />
            </AspectRatio.Root>
          )}
          <BackgroundOverlay
            sx={{
              ...(showOverlay && {
                backgroundImage: (theme) =>
                  createOverlayWithSpread(
                    overlayDirection,
                    overlaySpread,
                    isInvalidText(overlayColourFirstRoot) ? theme.palette.grey[900] : overlayColourFirstRoot,
                    isInvalidText(overlayColourSecondRoot) ? theme.palette.grey[900] : overlayColourSecondRoot
                  )
              })
            }}
          />
          {viewType === IMAGE_AND_TEXT_VIEW_TYPE[0] && (
            <Content sx={{ ...(isMobile && { padding: (theme) => theme.spacing(3, 3, 8) }) }}>
              <LayoutOrientation
                verticalPosition={getVerticalPosition(contentPosition)}
                horizontalPosition={getHorizontalPosition(contentPosition)}
              >
                <SimpleMarkdownTextBlock
                  {...component}
                  verticalAnimation={verticalAnimation}
                  position={getHorizontalPosition(contentPosition)}
                  onButtonClick={() => handleNavigationClick(navigation?.type, navigation?.path)}
                />
              </LayoutOrientation>
            </Content>
          )}
        </ImageWrapper>
        {viewType === IMAGE_AND_TEXT_VIEW_TYPE[1] && (
          <Box>
            <Content sx={{ position: 'relative', ...(isMobile && { padding: (theme) => theme.spacing(3, 3, 8) }) }}>
              <LayoutOrientation
                verticalPosition={getVerticalPosition(contentPosition)}
                horizontalPosition={getHorizontalPosition(contentPosition)}
              >
                <SimpleMarkdownTextBlock
                  {...component}
                  verticalAnimation={verticalAnimation}
                  position={getHorizontalPosition(contentPosition)}
                  onButtonClick={() => handleNavigationClick(navigation?.type, navigation?.path)}
                />
              </LayoutOrientation>
            </Content>
          </Box>
        )}
      </Stack>
    </ComponentContainer>
  );
};

export default ImageAndTextCommon;
