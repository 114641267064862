import { styled } from '@material-ui/core/styles';
import { Icon } from '@iconify/react';
import { Box, Tab } from '@material-ui/core';
import { TabContext, TabList } from '@material-ui/lab';

const RootStyle = styled(Box)(({ theme }) => ({
  border: `solid 1px ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadiusSm,
  padding: theme.spacing(2)
}));

export default function MultiOptionPicker({ data, value, isScrollable = false, onChange }) {
  return (
    <RootStyle>
      <TabContext value={value}>
        <TabList onChange={onChange} {...(isScrollable ? { variant: 'scrollable' } : { centered: true })}>
          {data.map((option) => (
            <Tab key={option.value} icon={<Icon icon={option.icon} />} label={option.label} value={option.value} />
          ))}
        </TabList>
      </TabContext>
    </RootStyle>
  );
}
