// COLLECTION LIST
export const SLIDER_DESKTOP_COLLECTION_LIST_PER_ROW_MIN = 2;
export const SLIDER_DESKTOP_COLLECTION_LIST_PER_ROW_MAX = 6;
export const SLIDER_MOBILE_COLLECTION_LIST_PER_ROW_MIN = 1;
export const SLIDER_MOBILE_COLLECTION_LIST_PER_ROW_MAX = 4;

// GALLERY IMAGES
export const SLIDER_DESKTOP_GALLERY_PER_ROW_MIN = 2;
export const SLIDER_DESKTOP_GALLERY_PER_ROW_MAX = 6;
export const SLIDER_MOBILE_GALLERY_PER_ROW_MIN = 1;
export const SLIDER_MOBILE_GALLERY_PER_ROW_MAX = 2;
export const SLIDER_GALLERY_SPACING_MIN = 0;
export const SLIDER_GALLERY_SPACING_MAX = 8;
export const MAX_GALLERY_IMAGES_SECTIONS = 12;

// PRODUCTS
export const DEFAULT_PRODUCTS_DISPLAY = 6;
export const SLIDER_PRODUCTS_DISPLAY_MIN = 2;
export const SLIDER_PRODUCTS_DISPLAY_MAX = 12;
export const SLIDER_DESKTOP_PRODUCTS_PER_ROW_MIN = 2;
export const SLIDER_DESKTOP_PRODUCTS_PER_ROW_MAX = 6;
export const SLIDER_MOBILE_PRODUCTS_PER_ROW_MIN = 1;
export const SLIDER_MOBILE_PRODUCTS_PER_ROW_MAX = 2;

// RECENTLY VIEWED PRODUCTS
export const SLIDER_NUM_OF_PRODUCTS_ROW_MIN = 1;
export const SLIDER_NUM_OF_PRODUCTS_ROW_MAX = 8;

// COLLECTIONS
export const DEFAULT_COLLECTIONS_DISPLAY = 6;
export const SLIDER_COLLECTIONS_DISPLAY_MIN = 2;
export const SLIDER_COLLECTIONS_DISPLAY_MAX = 12;
export const SLIDER_DESKTOP_COLLECTIONS_PER_ROW_MIN = 2;
export const SLIDER_DESKTOP_COLLECTIONS_PER_ROW_MAX = 6;
export const SLIDER_MOBILE_COLLECTIONS_PER_ROW_MIN = 1;
export const SLIDER_MOBILE_COLLECTIONS_PER_ROW_MAX = 2;

export const DEFAULT_RECENTLY_VIEWED_SIZE = 4;
export const DEFAULT_INVENTORY_AVAILABLE = false;
export const DEFAULT_PRODUCT_DESCRIPTION_TAB = true;
export const DEFAULT_PRODUCT_SHIPPING_TAB = true;
export const DEFAULT_PRODUCT_RETURNS_TAB = true;
export const DEFAULT_SHOW_EMAIL_COMMUNICATION_CHECKBOX = true;
export const DEFAULT_SHOW_ANNOUNCEMENT_BAR_PROMINENT_TEXT = false;
export const DEFAULT_SHOW_NAVIGATION_SELECTION_DIVIDER = false;
export const DEFAULT_SHOW_HEADER_DIVIDER = true;
export const DEFAULT_ENABLE_SEARCH_ACTION = false;
export const MAX_COMPONENT_SECTION_SIZE = 5;
export const INITIAL_PAGE_VALUE = 0;
export const LAZY_PAGE_SIZE = 30;
export const UPLOAD_SQUARE_RECOMMENDED_SIZE_MESSAGE = '900px x 900px recommended dimensions';
export const UPLOAD_WIDERSCREEN_RECOMMENDED_SIZE_MESSAGE = '1800 x 800px recommended dimensions';
export const UPLOAD_MOBILE_SUPPORT_MESSAGE = 'If no image is selected, the desktop image will be cropped.';
export const UPLOAD_MOBILE_RECOMMENDED_SIZE_MESSAGE =
  '750 x 1100px recommended dimensions. If no image is selected, the desktop image will be cropped.';
export const UPLOAD_GROUPED_IMAGE_RECOMMENDED_SIZE_MESSAGE = '750 x 1100px recommended dimensions.';

// Fonts

export const SLIDER_HEADER_FONT_SIZE_MIN = 5;
export const SLIDER_HEADER_FONT_SIZE_MAX = 60;
export const SLIDER_TEXT_FONT_SIZE_MIN = 5;
export const SLIDER_TEXT_FONT_SIZE_MAX = 30;
export const COMPANY_NAME_FOOTER_TITLE = 'Shoppy Monkey. © Copyright 2023';
export const REQUIRED_OPTION_MESSAGE = 'Please select an option';

// EDITOR
export const EDITOR_DEFAULT_CAPABILITY = {
  currency: true
};
