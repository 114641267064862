import { Icon } from '@iconify/react';
import { Grid, Box, Typography, Stack, CardActionArea } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';

const views = () => [
  {
    key: 'STANDARD',
    name: 'Standard',
    component: <StandardView />
  },
  {
    key: 'STANDARD_STICKY',
    name: 'Sticky',
    component: <StandardStickyView />
  },
  {
    key: 'OVERLAP',
    name: 'Overlap',
    component: <OverlapView />
  },
  {
    key: 'OVERLAP_STICKY',
    name: 'Hybrid',
    component: <OverlapStickyView />
  }
];

const OverlapStickyView = () => (
  <Stack direction="column" justifyContent="center" sx={{ height: 40 }}>
    <Stack
      spacing={0}
      direction="column"
      alignItems="center"
      sx={{
        border: (theme) => `2px solid ${theme.palette.primary.main}`,
        borderRadius: 0.5,
        height: '60px'
      }}
    >
      <Box
        sx={{
          height: 2,
          width: '100%',
          backgroundColor: (theme) => theme.palette.primary.main,
          mt: '6px'
        }}
      />
      <Box sx={{ justifyContent: 'center' }}>
        <Icon icon={arrowIosDownwardFill} fontSize={20} />
      </Box>
    </Stack>
  </Stack>
);

const StandardStickyView = () => (
  <Stack direction="column" justifyContent="center" sx={{ height: 40 }}>
    <Stack spacing={0.75} direction="column">
      <Box
        sx={{
          height: 2,
          width: '90%',
          backgroundColor: (theme) => theme.palette.primary.main,
          mt: '6px',
          alignSelf: 'center'
        }}
      />
      <Box
        sx={{
          border: (theme) => `2px solid ${theme.palette.primary.main}`,
          borderRadius: 0.5,
          width: '100%',
          height: '20px'
        }}
      >
        <Box sx={{ justifyContent: 'center' }}>
          <Icon icon={arrowIosDownwardFill} fontSize={20} />
        </Box>
      </Box>
    </Stack>
  </Stack>
);

const OverlapView = () => (
  <Stack direction="column" justifyContent="center" sx={{ height: 40 }}>
    <Stack
      spacing={1}
      direction="column"
      alignItems="center"
      sx={{
        border: (theme) => `2px solid ${theme.palette.primary.main}`,
        borderRadius: 0.5,
        height: '60px'
      }}
    >
      <Box
        sx={{
          height: 2,
          width: '100%',
          backgroundColor: (theme) => theme.palette.primary.main,
          mt: '6px'
        }}
      />
    </Stack>
  </Stack>
);

const StandardView = () => (
  <Stack direction="column" justifyContent="center" sx={{ height: 40 }}>
    <Stack spacing={0.75} direction="column">
      <Box
        sx={{
          height: 2,
          width: '90%',
          backgroundColor: (theme) => theme.palette.primary.main,
          mt: '6px',
          alignSelf: 'center'
        }}
      />
      <Box
        sx={{
          border: (theme) => `2px solid ${theme.palette.primary.main}`,
          borderRadius: 0.5,
          width: '100%',
          height: '20px'
        }}
      />
    </Stack>
  </Stack>
);

const StyledCardActionArea = styled(CardActionArea)(({ theme }) => ({
  borderRadius: theme.shape.borderRadiusSm,
  padding: theme.spacing(3)
}));

const ShapeOption = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.grey[200],
  border: `2px solid ${theme.palette.divider}`,
  width: '100%',
  borderRadius: theme.shape.borderRadiusSm,
  padding: 0
}));

export const HeaderStylePicker = ({ value, onChange }) => {
  return (
    <Box>
      <Grid container spacing={2} direction="row">
        {views().map((view) => {
          const { key, name, component } = view;
          return (
            <Grid key={key} item sm={4}>
              <ShapeOption
                sx={{ ...(key === value && { border: (theme) => `2px solid ${theme.palette.primary.main}` }) }}
              >
                <StyledCardActionArea onClick={() => onChange(key)} sx={{ height: '100%' }}>
                  <Box sx={{ textAlign: 'center', width: '100%', color: (theme) => theme.palette.primary.main }}>
                    {component && <Box sx={{ width: '100%' }}>{component}</Box>}
                    <Typography variant="subtitle2" sx={{ color: 'text.primary', mt: (theme) => theme.spacing(1) }}>
                      {name}
                    </Typography>
                  </Box>
                </StyledCardActionArea>
              </ShapeOption>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};
