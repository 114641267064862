import React from 'react';
import { alpha, styled } from '@material-ui/core/styles';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import minusFill from '@iconify/icons-eva/minus-fill';
import { useField } from 'formik';
// material
import { Box, Typography } from '@material-ui/core';
import { MIconButton } from '../@material-extend';

const IncrementerStyle = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1.5, 0.75),
  border: `1px solid ${alpha(theme.palette.border, 0.32)}`,
  lineHeight: 0,
  borderRadius: theme.shape.textfieldBorderRadius,
  display: 'flex',
  flexDirection: 'column',
  width: 200
}));

export const ProductIncrementer = (props) => {
  const [field, , helpers] = useField(props);
  // eslint-disable-next-line react/prop-types
  const { isInfiniteStock, available } = props;
  const { value } = field;
  const { setValue } = helpers;

  const incrementQuantity = () => {
    setValue(value + 1);
  };
  const decrementQuantity = () => {
    setValue(value - 1);
  };

  return (
    <IncrementerStyle>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', color: 'text.primary' }}>
        <MIconButton size="small" color="inherit" disabled={value <= 1} onClick={decrementQuantity}>
          <Icon icon={minusFill} width={16} height={16} />
        </MIconButton>
        <Typography
          variant="body2"
          component="span"
          sx={{
            color: 'inherit',
            width: 40,
            textAlign: 'center',
            display: 'inline-block'
          }}
        >
          {value}
        </Typography>
        <MIconButton
          size="small"
          color="inherit"
          {...(!isInfiniteStock && { disabled: value >= available })}
          onClick={incrementQuantity}
        >
          <Icon icon={plusFill} width={16} height={16} />
        </MIconButton>
      </Box>
    </IncrementerStyle>
  );
};
