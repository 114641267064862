import React, { useCallback, useMemo, useRef } from 'react';
import { Icon } from '@iconify/react';
import { LoadingButton } from '@material-ui/lab';
import { Stack, Box, Stepper, Step, StepLabel, Button } from '@material-ui/core';
import { defaultTo, find, isEmpty, isUndefined } from 'lodash';
import { paramCase } from 'change-case';
import shoppingCartOutline from '@iconify/icons-eva/shopping-cart-outline';
import useMobile from '../../hooks/useMobile';
import { HOME_PATH, SYSTEM_PAGE } from '../../utils/pageTypes';
import { QontoConnector, QontoStepIcon } from './StepComponent';
import { InformationPage } from './InformationPage';
import { ShippingPage } from './ShippingPage';
import { PaymentPage } from './PaymentPage';
import EmptyContent from '../core/EmptyContent';
import {
  DEFAULT_CART_EMPTY_TITLE,
  DEFAULT_CART_EMPTY_DESCRIPTION,
  DEFAULT_CART_EMPTY_BUTTON_TEXT,
  DEFAULT_PAYMENT_BUTTON_MESSAGE,
  MOBILE_PAYMENT_BUTTON_MESSAGE,
  DEFAULT_CHECKOUT_BACK_HOME_BUTTON_TITLE
} from '../../utils/constants';
import { isInvalid } from '../../utils/nullable';
import Scrollbar from '../core/Scrollbar';

const isExternalPaymentOption = (value, paymentOptions) => {
  const found = find(
    paymentOptions,
    (option) => paramCase(defaultTo(option.key, '')) === paramCase(defaultTo(value, ''))
  );
  return paramCase(defaultTo(found?.type, '')) === 'external';
};

export const CheckoutPanel = ({
  form,
  currency,
  loading,
  config,
  discountStore,
  loadingDiscount,
  isCartEmpty,
  hasCheckoutError,
  steps,
  shippingCountries,
  shippingOptions,
  paymentMethods,
  activeStep,
  isFirstStep,
  isLastStep,
  onNextStep,
  onBack,
  onClearDiscount,
  handleDiscountCheck,
  handleChangePaymentOption,
  handleUpdateStripeContext,
  handleNavigationClick
}) => {
  const containerRef = useRef(null);
  const appearance = config?.global?.theme?.appearance;
  const checkout = config?.checkout;
  const messages = config?.global?.messages?.checkout;
  const messaging = config?.global?.messages?.cart;
  const { showCheckoutProgress, homeButtonTitle } = checkout?.general;

  const {
    isSubmitting,
    values: { payment }
  } = form;

  // If any of the options are loading then consider them all loading
  const isPaymentsLoading = useCallback(() => {
    const optionFound = find(defaultTo(paymentMethods, []), (option) => {
      return option.enabled && option.loading;
    });
    return !isUndefined(optionFound);
  }, [paymentMethods]);

  const getLoading = useCallback(() => {
    return {
      isSubmitting,
      options: {
        countries: loading.countries,
        shipping: loading.shipping,
        payments: loading.payments || isPaymentsLoading()
      }
    };
  }, [loading, isSubmitting, isPaymentsLoading]);

  const StepperView = useMemo(() => {
    return (
      <Scrollbar sx={{ minHeight: 80 }}>
        <Stepper alternativeLabel activeStep={activeStep} connector={<QontoConnector />}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Scrollbar>
    );
  }, [activeStep, steps]);

  const StepContent = useMemo(() => {
    const common = {
      form,
      loading: getLoading(),
      currency,
      hasCheckoutError,
      handleNavigationClick
    };
    switch (activeStep) {
      case 0:
        return (
          <InformationPage
            config={{
              ...appearance,
              ...checkout
            }}
            shippingCountries={shippingCountries}
            paymentMethods={paymentMethods}
            {...common}
          />
        );
      case 1:
        return (
          <ShippingPage
            config={{
              messaging: messages?.shipping,
              ...checkout
            }}
            shippingOptions={shippingOptions}
            {...common}
          />
        );
      case 2:
        return (
          <PaymentPage
            config={{
              messaging: messages?.payments,
              ...appearance,
              ...checkout
            }}
            loadingDiscount={loadingDiscount}
            discountStore={discountStore}
            paymentMethods={paymentMethods}
            handleDiscountCheck={handleDiscountCheck}
            onClearDiscount={onClearDiscount}
            handleChangePaymentOption={(...args) => handleChangePaymentOption(...args, containerRef.current)}
            handleUpdateStripeContext={handleUpdateStripeContext}
            {...common}
          />
        );
      default:
        return null;
    }
  }, [
    form,
    getLoading,
    loadingDiscount,
    appearance,
    checkout,
    messages,
    currency,
    activeStep,
    discountStore,
    paymentMethods,
    shippingCountries,
    shippingOptions,
    onClearDiscount,
    handleDiscountCheck,
    hasCheckoutError,
    handleNavigationClick,
    handleChangePaymentOption,
    handleUpdateStripeContext
  ]);

  return !isCartEmpty ? (
    <>
      <Stack spacing={7}>
        {defaultTo(showCheckoutProgress, true) && StepperView}
        {StepContent}
      </Stack>
      <ActionButtons
        config={{ homeButtonTitle }}
        loading={getLoading()}
        hasCheckoutError={hasCheckoutError}
        containerRef={containerRef}
        selectedPayment={payment}
        paymentOptions={paymentMethods}
        isFirstStep={isFirstStep}
        isLastStep={isLastStep}
        onNextStep={onNextStep}
        onBack={onBack}
        handleNavigationClick={handleNavigationClick}
      />
    </>
  ) : (
    <Box sx={{ textAlign: 'center' }}>
      <EmptyContent
        title={isInvalid(messaging?.title) ? DEFAULT_CART_EMPTY_TITLE : messaging?.title}
        description={isInvalid(messaging?.description) ? DEFAULT_CART_EMPTY_DESCRIPTION : messaging?.description}
        buttonText={isInvalid(messaging?.buttonText) ? DEFAULT_CART_EMPTY_BUTTON_TEXT : messaging?.buttonText}
        onButtonClick={() => handleNavigationClick(SYSTEM_PAGE, HOME_PATH)}
      />
    </Box>
  );
};

const ActionButtons = ({
  config,
  loading,
  hasCheckoutError,
  containerRef,
  selectedPayment,
  paymentOptions,
  isFirstStep,
  isLastStep,
  onNextStep,
  onBack,
  handleNavigationClick
}) => {
  const { homeButtonTitle } = config;
  const isMobile = useMobile();
  const { enable } = hasCheckoutError;
  const missingPaymentOptions = isLastStep && isEmpty(paymentOptions);
  const isExternal = isExternalPaymentOption(selectedPayment, paymentOptions);
  const mobileStyles = {
    ...(isMobile && { width: '100%' })
  };
  const desktopStyles = {
    ...(!isMobile && { mr: 1 })
  };
  const {
    isSubmitting,
    options: { countries, shipping, payments }
  } = loading;
  const optionsLoading = countries || shipping || payments;
  const payButtonMessage = isMobile ? MOBILE_PAYMENT_BUTTON_MESSAGE : DEFAULT_PAYMENT_BUTTON_MESSAGE;
  const internalButton = (
    <LoadingButton
      disabled={(isExternal && enable) || optionsLoading || missingPaymentOptions}
      loading={isSubmitting || optionsLoading}
      variant="contained"
      onClick={onNextStep}
      sx={{ ...desktopStyles, ...mobileStyles }}
    >
      {isLastStep ? payButtonMessage : 'Next'}
    </LoadingButton>
  );
  const showExternalButton = isLastStep && isExternal && !enable;

  return (
    <Stack
      direction={isMobile ? 'column' : 'row'}
      spacing={0}
      justifyContent="space-between"
      flexDirection={isMobile ? 'column-reverse' : 'initial'}
    >
      <Box sx={{ textAlign: 'left', ...(isMobile && { marginTop: (theme) => theme.spacing(5) }) }}>
        <Button
          disabled={optionsLoading || isSubmitting}
          variant="text"
          endIcon={<Icon icon={shoppingCartOutline} />}
          onClick={() => handleNavigationClick(SYSTEM_PAGE, HOME_PATH)}
          sx={{ ...mobileStyles }}
        >
          {homeButtonTitle || DEFAULT_CHECKOUT_BACK_HOME_BUTTON_TITLE}
        </Button>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: isMobile ? 'column-reverse' : 'row',
          textAlign: 'right',
          ...(!isMobile && { maxHeight: '48px' })
        }}
      >
        <Button
          variant="outlined"
          disabled={isFirstStep || optionsLoading || missingPaymentOptions || isSubmitting}
          onClick={onBack}
          sx={{ ...desktopStyles, ...mobileStyles }}
        >
          Back
        </Button>
        <Box
          ref={containerRef}
          sx={{
            ...(Boolean(showExternalButton && !isMobile) && { minWidth: 200 }),
            ...mobileStyles,
            ...(isMobile && { marginBottom: (theme) => theme.spacing(2) })
          }}
        >
          {!showExternalButton && internalButton}
        </Box>
      </Box>
    </Stack>
  );
};
