import Slider from 'react-slick';
import { findIndex } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState, useRef, useEffect } from 'react';
// material
import { alpha, styled } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import * as AspectRatioPrimitive from '@radix-ui/react-aspect-ratio';
import LightboxModal from './LightboxModal';
import { CarouselControlsArrowsIndex } from '../carousel';
import { MLazyLoadImage } from '../@react-lazy-load-image-component-extend';
import useMobile from '../../../hooks/useMobile';
import { getImagePosition } from '../../../utils/getPositions';

// ----------------------------------------------------------------------

export const AspectRatio = AspectRatioPrimitive;

const THUMB_SIZE = 64;

const RootStyle = styled('div')(({ theme }) => ({
  '& .slick-slide': {
    float: theme.direction === 'rtl' ? 'right' : 'left',
    '&:focus': { outline: 'none' }
  }
}));

const ThumbWrapperStyle = styled('div')(({ theme }) => ({
  cursor: 'pointer',
  width: THUMB_SIZE,
  overflow: 'hidden',
  height: THUMB_SIZE,
  position: 'relative',
  margin: theme.spacing(0, 1),
  borderRadius: theme.shape.borderRadiusSm,
  '&:hover': {
    opacity: 0.72,
    transition: theme.transitions.create('opacity')
  },
  '& .isActive': {
    top: 0,
    zIndex: 9,
    opacity: 0,
    width: '100%',
    height: '100%',
    position: 'absolute',
    borderRadius: theme.shape.borderRadiusSm,
    border: `solid 3px ${theme.palette.primary.main}`,
    backgroundColor: alpha(theme.palette.grey[900], 0.48)
  }
}));

const LargeImgStyle = styled(MLazyLoadImage)({
  top: 0,
  width: '100%',
  height: '100%',
  position: 'absolute'
});

const ThumbImgStyle = styled(MLazyLoadImage)({
  top: 0,
  width: '100%',
  height: '100%',
  position: 'absolute'
});

// ----------------------------------------------------------------------

LargeItem.propTypes = {
  item: PropTypes.string,
  onOpenLightbox: PropTypes.func
};

function LargeItem({ name, item, fixedHeight, fixedHeightValue, fixedHeightPosition, onOpenLightbox }) {
  const isMobile = useMobile();
  const props = {
    alt: name,
    src: item,
    loaderSx: {
      minHeight: isMobile ? '400px' : '700px'
    },
    onClick: () => onOpenLightbox(item)
  };

  return fixedHeight ? (
    <Box sx={{ cursor: 'zoom-in', maxHeight: fixedHeightValue, width: '100%' }}>
      <MLazyLoadImage
        {...props}
        imgSx={{
          maxHeight: fixedHeightValue,
          width: '100%',
          objectFit: 'cover',
          objectPosition: getImagePosition(fixedHeightPosition)
        }}
      />
    </Box>
  ) : (
    <AspectRatio.Root ratio={4 / 5}>
      <Box sx={{ cursor: 'zoom-in' }}>
        <LargeImgStyle {...props} imgSx={{ height: '100%', width: '100%' }} />
      </Box>
    </AspectRatio.Root>
  );
}

ThumbnailItem.propTypes = {
  item: PropTypes.string
};

function ThumbnailItem({ item }) {
  return (
    <ThumbWrapperStyle>
      <Box className="isActive" />
      <ThumbImgStyle alt="thumb image" src={item} />
    </ThumbWrapperStyle>
  );
}

function ProductHeroCarousel({ isMobile, config, name, images }) {
  const {
    mobileFixedImageHeight,
    mobileFixedImageHeightValue,
    mobileFixedImageHeightPosition,
    fixedImageHeight,
    fixedImageHeightValue,
    fixedImageHeightPosition,
    imageThumbnailsPosition,
    showImageThumbnails,
    showImageControls = false
  } = config;
  const fixedHeight = isMobile ? mobileFixedImageHeight : fixedImageHeight;
  const imageHeight = isMobile ? mobileFixedImageHeightValue : fixedImageHeightValue;
  const imageHeightPosition = isMobile ? mobileFixedImageHeightPosition : fixedImageHeightPosition;

  const [openLightbox, setOpenLightbox] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const slider1 = useRef(null);
  const slider2 = useRef(null);

  const showSliderControl = showImageThumbnails ? images.length > 1 : false;

  const imagesLightbox = images.map((_image) => _image);

  const handleOpenLightbox = (url) => {
    const selectedImage = findIndex(imagesLightbox, (index) => index === url);
    setOpenLightbox(true);
    setSelectedImage(selectedImage);
  };

  const settings1 = {
    dots: false,
    arrows: false,
    slidesToShow: 1,
    draggable: false,
    slidesToScroll: 1,
    adaptiveHeight: true,
    beforeChange: (current, next) => setCurrentIndex(next)
  };

  const settings2 = {
    dots: false,
    arrows: false,
    centerMode: true,
    swipeToSlide: true,
    focusOnSelect: true,
    variableWidth: true,
    centerPadding: '0px',
    slidesToShow: images.length > 3 ? 3 : images.length
  };

  useEffect(() => {
    setNav1(slider1.current);
    setNav2(slider2.current);
  }, [currentIndex]);

  const handlePrevious = () => {
    slider2.current.slickPrev();
  };

  const handleNext = () => {
    slider2.current.slickNext();
  };

  return (
    <RootStyle>
      <Box>
        <Box
          sx={{
            zIndex: 0,
            overflow: 'hidden',
            position: 'relative',
            height: '100%'
          }}
        >
          <Slider {...settings1} asNavFor={nav2} ref={slider1}>
            {images.map((item) => (
              <LargeItem
                key={item}
                name={name}
                fixedHeight={fixedHeight}
                fixedHeightValue={imageHeight}
                fixedHeightPosition={imageHeightPosition}
                item={item}
                onOpenLightbox={handleOpenLightbox}
              />
            ))}
          </Slider>
          <CarouselControlsArrowsIndex
            index={currentIndex}
            total={images.length}
            onNext={handleNext}
            onPrevious={handlePrevious}
            sx={{ ...(!showImageControls && { display: 'none' }) }}
          />
        </Box>
      </Box>

      <Box
        sx={{
          ...(!showSliderControl && { display: 'none' }),
          my: 3,
          ...(imageThumbnailsPosition === 'LEFT' && { mr: 'auto' }),
          ...(imageThumbnailsPosition === 'RIGHT' && { ml: 'auto' }),
          ...(imageThumbnailsPosition === 'CENTER' && { mx: 'auto' }),
          '& .slick-current .isActive': { opacity: 1 },
          ...(images.length === 1 && { maxWidth: THUMB_SIZE * 1 + 16 }),
          ...(images.length === 2 && { maxWidth: THUMB_SIZE * 2 + 32 }),
          ...(images.length === 3 && { maxWidth: THUMB_SIZE * 3 + 48 }),
          ...(images.length === 4 && { maxWidth: THUMB_SIZE * 3 + 48 }),
          ...(images.length >= 5 && { maxWidth: THUMB_SIZE * 6 }),
          ...(images.length > 2 && {
            position: 'relative',
            '&:before, &:after': {
              top: 0,
              zIndex: 9,
              content: "''",
              height: '100%',
              position: 'absolute'
            }
          })
        }}
      >
        <Slider {...settings2} asNavFor={nav1} ref={slider2}>
          {images.map((item) => (
            <ThumbnailItem key={item} item={item} />
          ))}
        </Slider>
      </Box>

      <LightboxModal
        images={imagesLightbox}
        photoIndex={selectedImage}
        setPhotoIndex={setSelectedImage}
        isOpen={openLightbox}
        onClose={() => setOpenLightbox(false)}
      />
    </RootStyle>
  );
}

export default ProductHeroCarousel;
