import { styled } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack5';
import { Form, FormikProvider, useFormik } from 'formik';
import { Box, Typography, Stack, Divider, Button, TextField, FormHelperText } from '@material-ui/core';
import { defaultTo } from 'lodash';

const EditorConatiner = styled(Box)(({ theme }) => ({
  padding: theme.spacing(5)
}));

export default function BookingMessagesEditor({ config, heading, closeEdit, handleUpdateComponent }) {
  const { enqueueSnackbar } = useSnackbar();

  const form = useFormik({
    initialValues: {
      locations: {
        title: defaultTo(config?.locations?.title, ''),
        description: defaultTo(config?.locations?.description, ''),
        buttonText: defaultTo(config?.locations?.buttonText, '')
      },
      services: {
        title: defaultTo(config?.services?.title, ''),
        description: defaultTo(config?.services?.description, '')
      }
    },

    onSubmit: async (values, { setSubmitting }) => {
      try {
        handleUpdateComponent(values);
        enqueueSnackbar('Saved', { variant: 'success' });
        setSubmitting(false);
      } catch (error) {
        console.error(error);
        setSubmitting(false);
      }
    }
  });

  const { initialValues, values, getFieldProps, resetForm, handleSubmit } = form;

  return (
    <FormikProvider value={form}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <EditorConatiner>
          <Stack spacing={3}>
            <Stack spacing={2} justifyContent="flex-end" direction="row">
              <Button variant="outlined" color="primary" onClick={resetForm} disabled={initialValues === values}>
                Reset
              </Button>
              <Box sx={{ flexGrow: 1 }} />
              <Button variant="outlined" color="primary" onClick={closeEdit}>
                Cancel
              </Button>
              <Button variant="contained" color="primary" type="submit">
                Save
              </Button>
            </Stack>
            <Typography variant="h5">{heading}</Typography>

            <Divider />

            <Stack spacing={2}>
              <div>
                <Typography variant="body1">
                  <strong>Empty Locations</strong>
                </Typography>
                <Typography
                  variant="subtitle2"
                  color="text.secondary"
                  sx={{ fontWeight: (theme) => theme.typography.fontWeightRegular, my: (theme) => theme.spacing(1) }}
                >
                  This is displayed when a booking definition does not have any locations available. This will also
                  appear if you assign a booking definition that is not active.
                </Typography>
              </div>
              <TextField fullWidth autoComplete="off" label="Title" {...getFieldProps('locations.title')} />
              <TextField
                rows={5}
                multiline
                fullWidth
                autoComplete="off"
                label="Description"
                {...getFieldProps('locations.description')}
              />
              <TextField fullWidth autoComplete="off" label="Button Text" {...getFieldProps('locations.buttonText')} />
              <FormHelperText>
                This button will navigate a customer back to the homepage. Please choose an appropriate message to
                communicate this action
              </FormHelperText>
            </Stack>

            <Divider />

            <Stack spacing={2}>
              <div>
                <Typography variant="body1">
                  <strong>Empty Services</strong>
                </Typography>
                <Typography
                  variant="subtitle2"
                  color="text.secondary"
                  sx={{ fontWeight: (theme) => theme.typography.fontWeightRegular, my: (theme) => theme.spacing(1) }}
                >
                  This is displayed when a booking definition location doesn't have any services available
                </Typography>
              </div>
              <TextField fullWidth autoComplete="off" label="Title" {...getFieldProps('services.title')} />
              <TextField
                rows={5}
                multiline
                fullWidth
                autoComplete="off"
                label="Description"
                {...getFieldProps('services.description')}
              />
            </Stack>
          </Stack>
        </EditorConatiner>
      </Form>
    </FormikProvider>
  );
}
