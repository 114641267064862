import PropTypes from 'prop-types';
import { styled } from '@material-ui/core/styles';
import { Drawer } from '@material-ui/core';
import Scrollbar from 'src/components/Scrollbar';

// ----------------------------------------------------------------------

const DRAWER_WIDTH_SM = 350;
const DRAWER_WIDTH_LG = 500;
const DRAWER_WIDTH = {
  xs: DRAWER_WIDTH_SM,
  sm: DRAWER_WIDTH_SM,
  md: DRAWER_WIDTH_SM,
  lg: DRAWER_WIDTH_LG
};

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.complex
    })
  }
}));

export default function EditorDrawer({ children, isOpenSidebar, onCloseSidebar }) {
  return (
    <RootStyle
      sx={{
        width: { ...DRAWER_WIDTH }
      }}
    >
      <Drawer
        anchor="right"
        open={isOpenSidebar}
        onClose={onCloseSidebar}
        PaperProps={{
          sx: {
            width: { ...DRAWER_WIDTH }
          }
        }}
      >
        <Scrollbar
          sx={{
            height: 1,
            '& .simplebar-content': {
              height: 1,
              display: 'flex',
              flexDirection: 'column'
            }
          }}
        >
          {children}
        </Scrollbar>
      </Drawer>
    </RootStyle>
  );
}

EditorDrawer.propTypes = {
  children: PropTypes.object,
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func
};
