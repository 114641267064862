import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Stack,
  MenuItem
} from '@material-ui/core';
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import { defaultTo, isEmpty } from 'lodash';

export default function GenericSelectDialog({
  title,
  value,
  label,
  requiredOptionMessage,
  options,
  open,
  onClose,
  handleSelected,
  fallbackComponent
}) {
  const GenericSelectedSchema = Yup.object().shape({
    option: Yup.mixed().required(requiredOptionMessage)
  });

  const formik = useFormik({
    enableReinitialize: false,
    initialValues: {
      option: defaultTo(value, options[0]?.id)
    },

    validationSchema: GenericSelectedSchema,
    onSubmit: async (values) => {
      const { option } = values;
      handleSelected(option);
      onClose();
    }
  });

  const { isSubmitting, handleSubmit, getFieldProps, touched, errors } = formik;

  return (
    <Dialog fullWidth open={open} onClose={onClose} scroll="body">
      <FormikProvider value={formik}>
        <Form autoComplete="off" onSubmit={handleSubmit}>
          <DialogTitle sx={{ pb: 2 }}>{title}</DialogTitle>
          <DialogContent dividers>
            {isEmpty(options) ? (
              fallbackComponent
            ) : (
              <Stack direction="column" spacing={2}>
                <TextField
                  fullWidth
                  label={label}
                  select
                  {...getFieldProps('option')}
                  error={Boolean(touched.option && errors.option)}
                  helperText={touched.option && errors.option}
                >
                  {options.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Stack>
            )}
          </DialogContent>
          <DialogActions>
            <Button disabled={isSubmitting} onClick={onClose}>
              Cancel
            </Button>
            <Button type="submit" variant="contained">
              Okay
            </Button>
          </DialogActions>
        </Form>
      </FormikProvider>
    </Dialog>
  );
}
