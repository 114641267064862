import { useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Typography,
  TableContainer,
  TablePagination,
  TableRow,
  TableCell,
  TableBody,
  Table,
  OutlinedInput,
  InputAdornment,
  Switch,
  FormHelperText
} from '@material-ui/core';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import searchFill from '@iconify/icons-eva/search-fill';
import { Icon } from '@iconify/react';
import { paramCase } from 'change-case';
import { isEmpty, lowerCase, defaultTo } from 'lodash';
import SearchNotFound from 'src/components/SearchNotFound';

const getPagedData = (data, page, rowsPerPage, search) => {
  const from = page * rowsPerPage;
  const to = page * rowsPerPage + rowsPerPage;
  const filterd = data.filter((_productGroup) => paramCase(_productGroup.name).includes(lowerCase(search)));
  return filterd.slice(from, to);
};

const initial = {
  page: 0,
  rowsPerPage: 10,
  search: ''
};

export default function GenericSelectListDialog({
  title,
  values: selectedValues,
  options,
  open,
  onClose,
  handleSelected,
  fallbackComponent
}) {
  const [table, setTable] = useState(initial);

  const { page, rowsPerPage, search } = table;
  const pagedData = getPagedData(options, page, rowsPerPage, search);

  const handleClose = () => {
    setTable(initial);
    onClose();
  };

  const handleChangePage = (_, newPage) => {
    setTable((prevState) => {
      return { ...prevState, page: newPage };
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setTable((prevState) => {
      return { ...prevState, page: 0, rowsPerPage: parseInt(event.target.value, 10) };
    });
  };

  const onSearchChange = (event) => {
    setTable((prevState) => {
      return { ...prevState, page: 0, search: event.target.value };
    });
  };

  const GenericSelectListSchema = Yup.object().shape({
    // selected: Yup.array().min(1, 'Please select at least one option')
  });

  const formik = useFormik({
    enableReinitialize: false,
    initialValues: {
      selected: defaultTo(selectedValues, [])
    },

    validationSchema: GenericSelectListSchema,
    onSubmit: async (values) => {
      const { selected } = values;
      handleSelected(selected);
      handleClose();
    }
  });

  const { values, touched, errors, isSubmitting, handleSubmit, setFieldValue } = formik;

  const handleChange = (resource) => {
    const selectedIndex = values.selected.indexOf(resource);
    let newSelected = [];
    if (selectedIndex === -1) {
      // Already selected
      newSelected = newSelected.concat(values.selected, resource);
    } else {
      // Not already selected
      const filteredList = values.selected.filter((_selected) => _selected !== resource);
      newSelected = newSelected.concat(filteredList);
    }
    setFieldValue('selected', newSelected, true);
  };

  return (
    <Dialog fullWidth open={open} onClose={onClose} scroll="body">
      <DialogTitle sx={{ pb: 2 }}>{title}</DialogTitle>
      <Box sx={{ p: (theme) => theme.spacing(3) }}>
        <OutlinedInput
          fullWidth
          onChange={onSearchChange}
          placeholder="Search"
          startAdornment={
            <InputAdornment position="start">
              <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
            </InputAdornment>
          }
        />
      </Box>
      <DialogContent dividers>
        <Box sx={{ p: (theme) => theme.spacing(2, 0, 2), border: (theme) => `solid 1px ${theme.palette.divider}` }}>
          {isEmpty(pagedData) && isEmpty(search) ? (
            fallbackComponent
          ) : (
            <TableContainer>
              <Table>
                <TableBody>
                  {pagedData.map((data) => {
                    const { id, name } = data;
                    const isItemSelected = values.selected.indexOf(id) !== -1;
                    return (
                      <TableRow key={id}>
                        <TableCell align="left" padding="checkbox">
                          <Switch checked={isItemSelected} onChange={() => handleChange(id)} />
                        </TableCell>
                        <TableCell align="left" sx={{ p: (theme) => theme.spacing(2, 0) }}>
                          <Typography variant="body2" noWrap sx={{ alignSelf: 'center' }}>
                            {name}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          {isEmpty(pagedData) && !isEmpty(search) && (
            <TableBody>
              <TableRow>
                <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                  <SearchNotFound searchQuery={search} />
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Box>
        {touched.selected && errors.selected && (
          <Box sx={{ p: (theme) => theme.spacing(0, 2) }}>
            <FormHelperText error>{errors.selected}</FormHelperText>
          </Box>
        )}
      </DialogContent>
      <Box sx={{ pr: (theme) => theme.spacing(2) }}>
        <TablePagination
          showFirstButton
          showLastButton
          rowsPerPageOptions={[5, 10, 20, 50]}
          component="div"
          count={options.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
      <DialogActions>
        <Button disabled={isSubmitting} onClick={handleClose}>
          Cancel
        </Button>
        <Button type="submit" variant="contained" onClick={handleSubmit}>
          Okay
        </Button>
      </DialogActions>
    </Dialog>
  );
}
