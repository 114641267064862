import React from 'react';
import { compact, defaultTo, isEmpty } from 'lodash';
import { Divider, Stack, Typography } from '@material-ui/core';

export const BookingTextBlock = ({ title, description, supportText, activeComponent, sx, ...other }) => (
  <Stack
    spacing={0.5}
    direction="column"
    sx={{
      justifyContent: 'start',
      alignItems: 'start',
      textAlign: 'start',
      width: '100%',
      ...sx
    }}
    {...other}
  >
    <Typography variant="body1">
      <strong>{title}</strong>
    </Typography>
    {description && (
      <Typography variant="body2" color="text.secondary">
        {description}
      </Typography>
    )}
    {!isEmpty(supportText) && (
      <Stack spacing={2} direction="row" divider={<Divider flexItem orientation="vertical" />}>
        {compact(defaultTo(supportText, [])).map((text, index) => (
          <Typography key={index} variant="body2">
            {text}
          </Typography>
        ))}
      </Stack>
    )}
    {activeComponent}
  </Stack>
);
