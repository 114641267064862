import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { styled } from '@material-ui/core/styles';
import { Icon } from '@iconify/react';
import checkmark24Filled from '@iconify/icons-fluent/checkmark-24-filled';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { Box, Link, Stack, Button, Divider, Typography, FormHelperText } from '@material-ui/core';
// utils
import { isEmpty, uniq, compact, head, filter, orderBy, isEqual } from 'lodash';
import { paramCase } from 'change-case';
import { getAddToCartIcon } from '../../../utils/getProductIcons';
import { getTextAlignPosition } from '../../../utils/getPositions';
import { DEFAULT_PRODUCT_COLOURS } from '../../../theme/palette';
import { PRODUCT_PAGE } from '../../../utils/pageTypes';
import useMobile from '../../../hooks/useMobile';
import { ProductVariantSelector } from './ProductVariantSelector';
import {
  MAX_DROPDOWN_WIDTH_LARGE,
  MAXIMUM_NOTE_WORD_LENGTH,
  MOBILE_CONTAINER_TOP_BOTTOM_PADDING,
  PRODUCT_VARIANT_VIEW_TYPE
} from '../../../utils/constants';
import { defaultTo } from '../../../utils/nullable';
import { ProductDescription } from './ProductDescription';
import { ProductInformation } from './ProductInformation';
import { ProductIncrementer } from './ProductIncrementer';
import { ProductNote } from './ProductNote';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 3),
  [theme.breakpoints.up(1368)]: {
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8)
  }
}));

export default function ProductSumary({
  isWidget,
  isWideView,
  currency,
  product,
  messagingPlacements = [],
  handleProductChange = () => {},
  handleNavigationClick = () => {},
  handleIsAddedToCart = () => {},
  handleAddToCart = () => {},
  handleRemoveFromCart = () => {},
  handleBuyNow = async () => {},
  config
}) {
  const {
    showProductColours,
    showProductTag = true,
    showSku = false,
    showDescription = true,
    showNotes = true,
    noteTitle,
    descriptionHeaderBorder,
    descriptionHeaderViewType,
    descriptionHeaderBackgroundColour,
    descriptionHeaderTextColour,
    showInventoryQuantity = false,
    inventoryQuantityThreshold = 0,
    showPaymentMessages = true,
    position,
    showSpacingTop,
    variantsViewType,
    descriptionTitle,
    showDescriptionTab = false,
    addToCartButtonEnabled,
    addToCartButtonIcon,
    appearance
  } = config;
  const isMobile = useMobile();
  const state = useRef(null);

  // CONFIG
  const shape = appearance?.input;
  const showDescriptionInSummary = isWidget ? showDescription : Boolean(showDescription && !showDescriptionTab);
  const tagBgColour = config?.tagBackgroundColour || DEFAULT_PRODUCT_COLOURS.tagBackgroundColour;
  const tagTextColour = config?.tagTextColour || DEFAULT_PRODUCT_COLOURS.tagTextColour;
  const saleBgColour = config?.saleBackgroundColour || DEFAULT_PRODUCT_COLOURS.saleBackgroundColour;
  const saleTextColour = config?.saleTextColour || DEFAULT_PRODUCT_COLOURS.saleTextColour;
  const outOfStockBgColour = config?.outOfStockBackgroundColour || DEFAULT_PRODUCT_COLOURS.outOfStockBackgroundColour;
  const outOfStockTextColour = config?.outOfStockTextColour || DEFAULT_PRODUCT_COLOURS.outOfStockTextColour;

  const {
    id,
    name,
    description,
    image,
    resource,
    price,
    priceSale,
    status,
    isInfiniteStock,
    sku,
    variants,
    quantity,
    colours: availableColours
  } = product;
  const priceSaleInMajorUnit = priceSale?.amount;

  const shouldShowQuantityIndicator = useCallback(
    (selectedAvailable) => {
      if (!showInventoryQuantity || isInfiniteStock) {
        return false;
      }
      const configuredAlwaysShow = inventoryQuantityThreshold === 0;
      if (configuredAlwaysShow) {
        return true;
      }
      return selectedAvailable <= inventoryQuantityThreshold;
    },
    [inventoryQuantityThreshold, showInventoryQuantity, isInfiniteStock]
  );

  // The job of getVariantColours / getVariantSizes is to find the colours / size for the corresponding change. This is to avoid
  // listing variant combinations that do not exist. The calculation requires all other props except the prop being returned.
  const getVariantColours = useCallback(
    () => orderBy(uniq(compact(variants.map((_variant) => _variant.colour))), [], ['desc']),
    [variants]
  );

  const getVariantSizes = useCallback(
    (colour) => {
      const filtered = colour ? filter(variants, { colour }) : variants;
      return orderBy(uniq(compact(filtered.map((_variant) => _variant.size))), [], ['desc']);
    },
    [variants]
  );

  const getProductVariant = useCallback(
    (colour, size) => {
      return variants.find(
        (_variant) =>
          paramCase(defaultTo(_variant.colour, '')) === paramCase(defaultTo(colour, '')) &&
          paramCase(defaultTo(_variant.size, '')) === paramCase(defaultTo(size, ''))
      );
    },
    [variants]
  );

  const getSelectedSku = useCallback((variant) => (variant ? variant?.sku : sku), [sku]);

  const getSelectedAvailable = useCallback((variant) => (variant ? variant?.quantity : quantity), [quantity]);

  // Note - Returns price object and not the amount. See object spec for usage
  const getSelectedPrice = useCallback((variant) => (variant ? variant?.price : price), [price]);

  const getProduct = useCallback(
    (values) => {
      const { purchaseQuantity, size, colour, note } = values;
      const variant = getProductVariant(colour, size);
      return {
        id,
        name,
        resource,
        image,
        isInfiniteStock,
        quantity: purchaseQuantity,
        variantId: variant?.id,
        sku: getSelectedSku(variant),
        price: getSelectedPrice(variant),
        available: getSelectedAvailable(variant),
        size: variant?.size,
        colour: variant?.colour,
        ...(!isEmpty(note) && { note })
      };
    },
    [
      id,
      name,
      image,
      resource,
      isInfiniteStock,
      getSelectedSku,
      getSelectedPrice,
      getSelectedAvailable,
      getProductVariant
    ]
  );

  const getFormikConfig = useCallback(() => {
    const initialColour = head(getVariantColours());
    const colour = isEmpty(variants) ? null : initialColour;
    return {
      enableReinitialize: true,
      initialValues: {
        available: quantity,
        // Variant
        colour,
        size: isEmpty(variants) ? null : head(getVariantSizes(initialColour)),
        // Order quantity
        purchaseQuantity: 1,
        note: ''
      },
      validationSchema: Yup.object().shape({
        note: Yup.string()
          .max(MAXIMUM_NOTE_WORD_LENGTH, `Cannot be more than ${MAXIMUM_NOTE_WORD_LENGTH} words`)
          .nullable()
          .optional()
      }),
      onSubmit: async (values, { setSubmitting }) => {
        try {
          setSubmitting(false);
          const orderProduct = getProduct(values);
          await handleBuyNow(orderProduct);
        } catch (error) {
          setSubmitting(false);
        }
      }
    };
  }, [variants, quantity, getProduct, getVariantColours, getVariantSizes, handleBuyNow]);

  const formik = useFormik(getFormikConfig());

  const { values, touched, errors, getFieldProps, setFieldValue, handleSubmit, validateForm } = formik;

  useEffect(() => {
    if (!isEqual(state.current, values)) {
      state.current = values;
      const snapshot = getProduct(values);
      handleProductChange(snapshot);
    }
  }, [getProduct, handleProductChange, values]);

  // SELECTED VALUES
  const selectedVariant = getProductVariant(values.colour, values.size);
  const selectedSku = selectedVariant ? selectedVariant?.sku : sku;
  const selectedPriceSale = selectedVariant ? selectedVariant?.priceSale?.amount : priceSaleInMajorUnit;
  const selectedPrice = getSelectedPrice(selectedVariant);
  const selectedAvailable = getSelectedAvailable(selectedVariant);

  const isAddedToCartResponse = handleIsAddedToCart(id, selectedVariant?.id);
  const isAddedToCart = isAddedToCartResponse?.isAddedToCart;
  const productInCart = isAddedToCartResponse?.product;
  const selectedNote = isAddedToCart ? productInCart : null;

  // OPTIONS
  const colours = getVariantColours();
  const sizes = getVariantSizes(values.colour);

  const showQuantityIndicator = shouldShowQuantityIndicator(selectedAvailable);
  const isProductOutOfStock = selectedAvailable <= 0;

  const onClickAddCart = () => {
    // Check if the notes exceed the maximum allowed word count
    validateForm().then((errors) => {
      if (isEmpty(errors)) {
        const orderProduct = getProduct(values);
        handleAddToCart(orderProduct);
      }
    });
  };

  const onClickRemoveCart = () => {
    handleRemoveFromCart(id, selectedVariant?.id);
  };

  const onChangeColour = (newColour) => {
    setFieldValue('colour', newColour);
    // Reset selected size based on available for colour
    setFieldValue('size', head(getVariantSizes(newColour)));
  };

  const ActiveCartButton = () => (
    <Button
      fullWidth
      disabled={isProductOutOfStock}
      type="button"
      variant="outlined"
      {...(addToCartButtonEnabled && { startIcon: <Icon icon={getAddToCartIcon(addToCartButtonIcon)} /> })}
      onClick={onClickAddCart}
    >
      Add To Cart
    </Button>
  );

  const InactiveCartButton = () => (
    <Button
      fullWidth
      type="button"
      variant="outlined"
      startIcon={<Icon icon={checkmark24Filled} />}
      onClick={onClickRemoveCart}
    >
      Remove From Cart
    </Button>
  );

  const ProductInfoMemo = useMemo(() => {
    return (
      <ProductInformation
        currency={currency}
        isWidget={isWidget}
        isWideView={isWideView}
        showSpacingTop={showSpacingTop}
        name={name}
        resource={resource}
        position={position}
        status={status}
        saleBgColour={saleBgColour}
        tagBgColour={tagBgColour}
        outOfStockBgColour={outOfStockBgColour}
        saleTextColour={saleTextColour}
        tagTextColour={tagTextColour}
        outOfStockTextColour={outOfStockTextColour}
        showProductTag={showProductTag}
        showSku={showSku}
        selectedSku={selectedSku}
        showProductColours={showProductColours}
        availableColours={availableColours}
        selectedPrice={selectedPrice.amount}
        selectedPriceSale={selectedPriceSale}
        handleNavigationClick={handleNavigationClick}
      />
    );
  }, [
    currency,
    isWidget,
    isWideView,
    showSpacingTop,
    name,
    resource,
    position,
    status,
    saleBgColour,
    tagBgColour,
    outOfStockBgColour,
    saleTextColour,
    tagTextColour,
    outOfStockTextColour,
    showProductTag,
    showSku,
    selectedSku,
    showProductColours,
    availableColours,
    selectedPrice.amount,
    selectedPriceSale,
    handleNavigationClick
  ]);

  const ProductDescriptionMemo = useMemo(() => {
    return (
      showDescriptionInSummary &&
      !isEmpty(description) && (
        <ProductDescription
          position={position}
          descriptionHeaderBorder={descriptionHeaderBorder}
          descriptionHeaderViewType={descriptionHeaderViewType}
          descriptionHeaderBackgroundColour={descriptionHeaderBackgroundColour}
          descriptionHeaderTextColour={descriptionHeaderTextColour}
          descriptionTitle={descriptionTitle}
          description={description}
        />
      )
    );
  }, [
    position,
    descriptionHeaderBorder,
    descriptionHeaderViewType,
    descriptionHeaderBackgroundColour,
    descriptionHeaderTextColour,
    descriptionTitle,
    showDescriptionInSummary,
    description
  ]);

  const ProductVariantSelectors = () => {
    const isSelectView = variantsViewType === PRODUCT_VARIANT_VIEW_TYPE[1];
    if (isEmpty(colours) && isEmpty(sizes)) {
      return null;
    }
    return (
      <Stack
        spacing={2}
        sx={{
          width: '100%',
          maxWidth: MAX_DROPDOWN_WIDTH_LARGE,
          ...(position && { alignItems: getTextAlignPosition(position) })
        }}
      >
        {!isEmpty(colours) && (
          <ProductVariantSelector
            position={position}
            isSelectView={isSelectView}
            prop="colour"
            title="Colour"
            options={colours}
            form={formik}
            onChange={(_, newValue) => onChangeColour(newValue)}
          />
        )}
        {!isEmpty(sizes) && (
          <ProductVariantSelector
            position={position}
            isSelectView={isSelectView}
            prop="size"
            title="Size"
            options={sizes}
            form={formik}
            {...(!isSelectView && { sx: { mt: (theme) => theme.spacing(2) } })}
            onChange={(e, newValue) => {
              // Dropdown view
              if (e) {
                getFieldProps('size').onChange(e);
                return;
              }
              // Select view
              setFieldValue('size', newValue);
            }}
          />
        )}
      </Stack>
    );
  };

  return (
    <RootStyle
      sx={{
        ...(isMobile && {
          paddingLeft: (theme) => `${theme.spacing(2)} !important`,
          paddingRight: (theme) => `${theme.spacing(2)} !important`,
          padding: (theme) => theme.spacing(MOBILE_CONTAINER_TOP_BOTTOM_PADDING, 2)
        }),
        ...(isWideView && { paddingBottom: (theme) => theme.spacing(5) })
      }}
    >
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={2} sx={{ ...(position && { alignItems: getTextAlignPosition(position) }) }}>
            {ProductInfoMemo}
            {ProductDescriptionMemo}
            <ProductVariantSelectors />

            <Stack direction="column" spacing={1}>
              <Typography variant="subtitle1" sx={{ color: 'text.primary', mt: 0.5 }}>
                Quantity
              </Typography>
              <ProductIncrementer
                name="purchaseQuantity"
                available={selectedAvailable}
                isInfiniteStock={isInfiniteStock}
              />
              {showQuantityIndicator && (
                <Typography
                  variant="caption"
                  sx={{
                    mt: 1,
                    display: 'block',
                    color: 'text.secondary'
                  }}
                >
                  Available: {selectedAvailable}
                </Typography>
              )}

              <FormHelperText error>{touched.purchaseQuantity && errors.purchaseQuantity}</FormHelperText>
            </Stack>

            {showNotes && (
              <ProductNote
                disabled={isAddedToCart}
                defaultNote={selectedNote?.note}
                title={noteTitle}
                shape={shape}
                position={position}
                getFieldProps={getFieldProps}
                touched={touched}
                errors={errors}
              />
            )}

            {!showDescription && isWidget && (
              <Box>
                <Typography
                  onClick={() => {
                    if (resource) {
                      handleNavigationClick(PRODUCT_PAGE, resource);
                    }
                  }}
                  variant="body2"
                  color="text.secondary"
                  component={Link}
                  sx={{ cursor: 'pointer' }}
                >
                  View product details
                </Typography>
              </Box>
            )}

            {Boolean(!isWidget && showPaymentMessages) &&
              messagingPlacements.map((placement, index) => (
                <Box key={index} sx={{ width: '100%', maxWidth: MAX_DROPDOWN_WIDTH_LARGE }}>
                  {placement}
                </Box>
              ))}
          </Stack>

          <Stack
            spacing={3}
            sx={{ mt: (theme) => theme.spacing(3), ...(position && { alignItems: getTextAlignPosition(position) }) }}
          >
            <Divider sx={{ width: '100%', maxWidth: MAX_DROPDOWN_WIDTH_LARGE }} />

            <Stack
              spacing={2}
              direction={{ xs: isMobile ? 'column' : 'row' }}
              sx={{
                mt: 3,
                width: '100%',
                maxWidth: MAX_DROPDOWN_WIDTH_LARGE
              }}
            >
              {isAddedToCart ? <InactiveCartButton /> : <ActiveCartButton />}

              <Button disabled={isProductOutOfStock} fullWidth type="submit" variant="contained">
                Buy Now
              </Button>
            </Stack>
          </Stack>
        </Form>
      </FormikProvider>
    </RootStyle>
  );
}
