import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { defaultTo, isEmpty } from 'lodash';
import { Divider, Stack, Container } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import useMobile from './hooks/useMobile';
import Page from './components/core/Page';
import TemplateLayout from './layout/TemplateLayout';
import { ProductsRecentlyViewedTable } from './components/products/ProductsRecentlyViewedTable';
import ThemeConfig from './theme';
import { PRODUCT_PAGE } from './utils/pageTypes';
import {
  DEFAULT_PRODUCT_PAGE_NAME,
  MOBILE_STACK_SPACING,
  DESKTOP_STACK_SPACING,
  PRODUCTS_TABLE_VIEW_TYPE
} from './utils/constants';
import { ProductView } from './components/product/ProductView';

const Content = styled(Stack)(({ theme }) => ({
  marginTop: theme.spacing(8),
  marginBottom: theme.spacing(10)
}));

ProductPageTemplate.propTypes = {
  /** pagePath:
   * Current location path id e.g. /terms-and-conditions, /:product-name
   * For the home page this is static otherwise this will be a variable.
   * When set within the editor the value will be pre-defined as you navigate the editor.
   * When consumed by hosting app then this will be pulled from the url path. */
  context: PropTypes.object,
  logo: PropTypes.object,
  config: PropTypes.object.isRequired,
  currency: PropTypes.object.isRequired,
  currencies: PropTypes.array.isRequired,
  currenciesLoading: PropTypes.bool.isRequired,
  product: PropTypes.object.isRequired,
  cartTotal: PropTypes.number.isRequired,
  recentlyViewedProducts: PropTypes.array.isRequired,
  messagingPlacements: PropTypes.array.isRequired,
  handleProductChange: PropTypes.func.isRequired,
  handleIsAddedToCart: PropTypes.func.isRequired,
  handleAddToCart: PropTypes.func.isRequired,
  handleRemoveFromCart: PropTypes.func.isRequired,
  handleBuyNow: PropTypes.func.isRequired,
  // Common functions
  handleChangeCurrency: PropTypes.func.isRequired,
  handleNavigationClick: PropTypes.func.isRequired,
  handleGetNavigation: PropTypes.func.isRequired,
  handleGetNavigations: PropTypes.func.isRequired,
  handleGetStoreInformation: PropTypes.func.isRequired,
  handleCreateCustomer: PropTypes.func.isRequired,
  handleOnPageEnter: PropTypes.func
};

export default function ProductPageTemplate({
  context,
  logo,
  config,
  currency,
  currencies,
  currenciesLoading,
  product,
  cartTotal,
  handleChangeCurrency,
  handleNavigationClick,
  handleGetNavigation,
  handleGetNavigations,
  handleGetStoreInformation,
  handleCreateCustomer,
  handleOnPageEnter,
  ...other
}) {
  const { pagePath } = context;
  const navigation = { type: PRODUCT_PAGE, path: pagePath };
  const pageTitle = defaultTo(product?.name, DEFAULT_PRODUCT_PAGE_NAME);

  return (
    <ThemeConfig customTheme={config?.global?.theme} settings={config?.settings}>
      <Page title={pageTitle} onPageEnter={handleOnPageEnter}>
        <TemplateLayout
          logo={logo}
          currency={currency}
          currencies={currencies}
          currenciesLoading={currenciesLoading}
          context={context}
          cartTotal={cartTotal}
          config={{
            ...config?.layout,
            ...config?.global?.theme,
            local: {
              layout: config?.product?.layout
            }
          }}
          currentNavigation={navigation}
          handleChangeCurrency={handleChangeCurrency}
          handleNavigationClick={handleNavigationClick}
          handleGetNavigation={handleGetNavigation}
          handleGetNavigations={handleGetNavigations}
          handleGetStoreInformation={handleGetStoreInformation}
          handleCreateCustomer={handleCreateCustomer}
        >
          <ProductViewComponent
            currency={currency}
            config={config}
            product={product}
            handleNavigationClick={handleNavigationClick}
            {...other}
          />
        </TemplateLayout>
      </Page>
    </ThemeConfig>
  );
}

const ProductViewComponent = ({
  config,
  currency: currencyObj,
  product,
  recentlyViewedProducts,
  messagingPlacements,
  handleProductChange,
  handleIsAddedToCart,
  handleAddToCart,
  handleRemoveFromCart,
  handleBuyNow,
  handleNavigationClick
}) => {
  const isMobile = useMobile();
  const currency = currencyObj?.symbol;

  const ComponentMemo = useMemo(() => {
    const common = {
      currency
    };
    const { showDivider } = config?.layout?.body;
    const productConfig = config?.product;
    const { productViewType, showSpacingTop } = productConfig?.item;
    const stackSpacing = isMobile ? MOBILE_STACK_SPACING : DESKTOP_STACK_SPACING;
    const isWideView = productViewType === PRODUCTS_TABLE_VIEW_TYPE[1];
    const appearance = config?.global?.theme?.appearance;

    const shouldShowPadding = () => {
      if (isWideView) {
        return false;
      }
      return !isMobile;
    };
    return (
      <Content
        spacing={stackSpacing}
        {...(showDivider ? { divider: <Divider sx={{ width: '100%' }} orientation="horizontal" /> } : null)}
        direction="column"
        alignItems="center"
        sx={{
          ...(showSpacingTop && isMobile && { marginTop: (theme) => theme.spacing(4) }),
          ...(!showSpacingTop && { marginTop: 0 })
        }}
      >
        <Container
          maxWidth={isWideView ? false : 'xl'}
          sx={{
            '&.MuiContainer-root': {
              ...(isWideView ? { paddingLeft: 0, paddingRight: 0 } : { px: (theme) => theme.spacing(2) })
            }
          }}
        >
          <ProductView
            isWideView={isWideView}
            config={{
              ...productConfig.item,
              ...productConfig.footer,
              ...config?.global?.theme?.colours?.product,
              appearance
            }}
            product={product}
            messagingPlacements={messagingPlacements}
            handleProductChange={handleProductChange}
            handleIsAddedToCart={handleIsAddedToCart}
            handleNavigationClick={handleNavigationClick}
            handleAddToCart={handleAddToCart}
            handleRemoveFromCart={handleRemoveFromCart}
            handleBuyNow={handleBuyNow}
            sx={{
              ...(shouldShowPadding() && { px: (theme) => theme.spacing(6) })
            }}
            {...common}
          />
        </Container>
        {!isEmpty(recentlyViewedProducts) && config?.product.recentlyViewed.enableRecentlyViewed && (
          <ProductsRecentlyViewedTable
            config={{
              recentlyViewed: config.product.recentlyViewed,
              ...config?.global?.theme.colours.product
            }}
            products={recentlyViewedProducts}
            handleNavigationClick={handleNavigationClick}
            {...common}
          />
        )}
      </Content>
    );
  }, [
    isMobile,
    config,
    currency,
    product,
    recentlyViewedProducts,
    messagingPlacements,
    handleProductChange,
    handleIsAddedToCart,
    handleAddToCart,
    handleRemoveFromCart,
    handleBuyNow,
    handleNavigationClick
  ]);

  return ComponentMemo;
};
