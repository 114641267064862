import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { BookingView } from './components/booking/BookingView';
import Page from './components/core/Page';
import ThemeConfig from './theme';
import { DEFAULT_BOOKING_PAGE_NAME, DESKTOP_CONTAINER_TOP_BOTTOM_PADDING } from './utils/constants';
import { defaultTo } from './utils/nullable';

const RootStyle = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  height: '100%'
}));

const InnerStyle = styled(Box)(({ theme }) => ({
  padding: theme.spacing(DESKTOP_CONTAINER_TOP_BOTTOM_PADDING, 0),
  backgroundColor: 'inherit'
}));

BookingPageTemplate.propTypes = {
  context: PropTypes.object, // Used to pre-fill information
  state: PropTypes.object.isRequired,
  logo: PropTypes.object,
  config: PropTypes.object.isRequired,
  currency: PropTypes.object.isRequired,
  timezone: PropTypes.object.isRequired,
  handleReset: PropTypes.func.isRequired,
  handleGetBookingLocations: PropTypes.func.isRequired,
  handleGetBookingServices: PropTypes.func.isRequired,
  handleGetBookingAvailability: PropTypes.func.isRequired,
  handleGetBookingSummary: PropTypes.func.isRequired,
  // Callback for booking
  handleCompleteBooking: PropTypes.func.isRequired,
  // Common functions
  handleNavigationClick: PropTypes.func.isRequired,
  handleOnPageEnter: PropTypes.func
};

export default function BookingPageTemplate({ config, handleOnPageEnter, ...other }) {
  // Config
  const backgroundColour = config?.booking?.general?.backgroundColour;

  return (
    <ThemeConfig customTheme={config?.global?.theme} settings={config?.settings}>
      <RootStyle sx={{ ...(backgroundColour && { backgroundColor: backgroundColour }) }}>
        <InnerStyle>
          <Page
            title={defaultTo(config?.booking?.metadata?.page, DEFAULT_BOOKING_PAGE_NAME)}
            description={defaultTo(config?.booking?.metadata?.description, '')}
            onPageEnter={handleOnPageEnter}
            sx={{ height: 'auto' }}
          >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <BookingView config={config} handleOnPageEnter={handleOnPageEnter} {...other} />
            </LocalizationProvider>
          </Page>
        </InnerStyle>
      </RootStyle>
    </ThemeConfig>
  );
}
