import { Stack, Alert } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { CART, CHECKOUT, BOOKING, CUSTOM } from '../utils/editorPages';
import { ResizeWindowButtons } from './ResizeWindowButtons';

const HeaderContainer = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(0, 5.3, 3)
}));

export const EditorHeader = ({ page }) => (
  <HeaderContainer spacing={2}>
    <ResizeWindowButtons />
    {[CART.value, CHECKOUT.value, BOOKING.value].includes(page.value) && (
      <Alert severity="info" sx={{ mb: 3 }}>
        All products shown on this page is only an example to demonstrate the view.
      </Alert>
    )}
    {[CUSTOM.value].includes(page.value) && (
      <Alert severity="info" sx={{ mb: 3 }}>
        All text or data shown on this page is only an example to demonstrate the view.
      </Alert>
    )}
  </HeaderContainer>
);
