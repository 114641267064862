import styled from '@emotion/styled';
import { Box, Button, Typography, Stack } from '@material-ui/core';
import { isEmpty } from 'lodash';
import { GenericAccordion, OrderSection, RowIcon, RowImage } from './AccordianStyled';

const RowStyle = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0)
}));

const SingleRowComponent = ({
  id,
  componentName,
  sectionName,
  editComponent,
  icon,
  iconRotated = false,
  title = '',
  image = '',
  openEdit,
  handleMoveUp,
  handleMoveDown,
  disableOrder = false
}) => {
  const componentTitle = isEmpty(title) ? 'Section' : title;

  return (
    <RowStyle>
      <GenericAccordion name={componentName} icon={icon} iconRotated={iconRotated} image={image}>
        {!disableOrder && (
          <OrderSection handleMoveUp={() => handleMoveUp(id)} handleMoveDown={() => handleMoveDown(id)} />
        )}
        <Button
          fullWidth
          variant="text"
          color="inherit"
          disableElevation
          disableFocusRipple
          disableTouchRipple
          disableRipple
          sx={{ justifyContent: 'flex-start' }}
          onClick={() => openEdit(editComponent)}
        >
          <Stack direction="row" spacing={1} sx={{ width: '100%' }}>
            {icon ? <RowIcon icon={icon} {...(iconRotated && { rotate: 2 })} /> : <RowImage img={image} />}
            <Typography noWrap variant="body2" sx={{ alignSelf: 'center', fontWeight: 200 }}>
              {sectionName || componentTitle}
            </Typography>
          </Stack>
        </Button>
      </GenericAccordion>
    </RowStyle>
  );
};

export default SingleRowComponent;
