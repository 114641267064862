import textAlignLeft from '@iconify/icons-akar-icons/text-align-left';
import textAlignCenter from '@iconify/icons-akar-icons/text-align-center';
import textAlignRight from '@iconify/icons-akar-icons/text-align-right';
import panelTop from '@iconify/icons-akar-icons/panel-top';
import panelCenter from '@iconify/icons-akar-icons/panel-split-column';
import panelBottom from '@iconify/icons-akar-icons/panel-bottom';
import underlineIcon from '@iconify/icons-fa-solid/underline';
import roundTextFields from '@iconify/icons-ic/round-text-fields';
import twotoneRadioButtonChecked from '@iconify/icons-ic/twotone-radio-button-checked';
import arrowLeftRight from '@iconify/icons-mdi/arrow-left-right';
import arrowUpDown from '@iconify/icons-mdi/arrow-up-down';
import arrowTopRightBoldOutline from '@iconify/icons-mdi/arrow-top-right-bold-outline';
import arrowTopLeftBoldOutline from '@iconify/icons-mdi/arrow-top-left-bold-outline';
import arrowBottomRightBoldOutline from '@iconify/icons-mdi/arrow-bottom-right-bold-outline';
import arrowBottomLeftBoldOutline from '@iconify/icons-mdi/arrow-bottom-left-bold-outline';
import roundClose from '@iconify/icons-ic/round-close';
import binIcon from '@iconify/icons-mdi/bin';
import bulbFilled from '@iconify/icons-ant-design/bulb-filled';
import bulbOutlined from '@iconify/icons-ant-design/bulb-outlined';
import topAlign from '@iconify/icons-icon-park-outline/to-top';
import bottomAlign from '@iconify/icons-icon-park-outline/to-bottom';
import circleHalfFilled from '@iconify/icons-fluent/circle-half-fill-16-filled';
import circleFilled from '@iconify/icons-fluent/circle-16-filled';
import circleEmpty from '@iconify/icons-fluent/circle-16-regular';

export const CONTENT_POSITIONS = [
  { value: 'BOTTOM_LEFT', label: 'Bottom left' },
  { value: 'BOTTOM_CENTER', label: 'Bottom center' },
  { value: 'BOTTOM_RIGHT', label: 'Bottom right' },
  { value: 'MIDDLE_LEFT', label: 'Middle left' },
  { value: 'MIDDLE_CENTER', label: 'Middle center' },
  { value: 'MIDDLE_RIGHT', label: 'Middle right' },
  { value: 'TOP_LEFT', label: 'Top left' },
  { value: 'TOP_CENTER', label: 'Top center' },
  { value: 'TOP_RIGHT', label: 'Top right' }
];
export const IMAGE_POSITIONS = [
  { value: 'COVER', label: 'Cover' },
  { value: 'CONTAIN', label: 'Contained' }
];
export const OVERLAY_DIRECTIONS = [
  { value: 'TOP_BOTTOM', label: 'Bottom To Top' },
  { value: 'BOTTOM_TOP', label: 'Top To Bottom' },
  { value: 'LEFT_RIGHT', label: 'Left To Right' },
  { value: 'RIGHT_LEFT', label: 'Right To Left' },
  { value: 'FULL_HEIGHT', label: 'Full Height' }
];
export const OVERLAY_SPREAD = [
  { value: 'LOW', label: 'Low' },
  { value: 'MEDIUM', label: 'Medium' },
  { value: 'HIGH', label: 'High' }
];
export const PAGE_POSITIONS = [
  {
    label: 'Top',
    value: 'TOP',
    icon: panelTop
  },
  {
    label: 'Top Sticky',
    value: 'TOP_STICKY',
    icon: panelTop
  },
  {
    label: 'Bottom',
    value: 'BOTTOM',
    icon: panelBottom
  },
  {
    label: 'Bottom Sticky',
    value: 'BOTTOM_STICKY',
    icon: panelBottom
  }
];
export const GENERIC_CURRENCY_VIEW = ['CODE', 'SYMBOL'];
export const GENERIC_FONT_SIZE = ['SMALL', 'LARGE'];

export const GENERIC_CONTENT_VERTICAL_POSITIONS = [
  {
    label: 'Top',
    value: 'TOP',
    icon: panelTop
  },
  {
    label: 'Center',
    value: 'CENTER',
    icon: panelCenter
  },
  {
    label: 'Bottom',
    value: 'BOTTOM',
    icon: panelBottom
  }
];

export const GENERIC_CONTENT_POSITIONS = [
  {
    label: 'Left',
    value: 'LEFT',
    icon: textAlignLeft
  },
  {
    label: 'Center',
    value: 'CENTER',
    icon: textAlignCenter
  },
  {
    label: 'Right',
    value: 'RIGHT',
    icon: textAlignRight
  }
];
export const GENERIC_WIDE_CONTENT_POSITIONS = [
  {
    label: 'Left',
    value: 'LEFT',
    icon: textAlignLeft
  },
  {
    label: 'Right',
    value: 'RIGHT',
    icon: textAlignRight
  }
];
export const NAVIGATION_ITEM_APPEARANCE = [
  {
    label: 'Tab',
    value: 'STANDARD',
    icon: underlineIcon
  },
  {
    label: 'Button',
    value: 'BUTTON',
    icon: twotoneRadioButtonChecked
  },
  {
    label: 'Text',
    value: 'TEXT',
    icon: roundTextFields
  }
];

export const HEADER_APPEARANCE_FILL = [
  {
    label: 'No Fill',
    value: 'TRANSPARENT',
    icon: circleEmpty
  },
  {
    label: 'Ghost',
    value: 'TRANSLUCENT',
    icon: circleHalfFilled
  },
  {
    label: 'Fill',
    value: 'OPAQUE',
    icon: circleFilled
  }
];

export const HEADER_APPEARANCE_FILL_WITH_SCROLL = [
  {
    label: 'No Fill',
    value: 'TRANSPARENT',
    icon: circleEmpty
  },
  {
    label: 'Scroll Ghost',
    value: 'SCROLL_TRANSLUCENT',
    icon: arrowUpDown
  },
  {
    label: 'Scroll Fill',
    value: 'SCROLL_FILL',
    icon: arrowUpDown
  },
  {
    label: 'Ghost',
    value: 'TRANSLUCENT',
    icon: circleHalfFilled
  },
  {
    label: 'Fill',
    value: 'OPAQUE',
    icon: circleFilled
  }
];
export const SLIDER_HEADER_LINKS_MIN_SIZE = 0;
export const SLIDER_HEADER_LINKS_MAX_SIZE = 10;
export const HEADER_APPEARANCE = ['STANDARD', 'STANDARD_STICKY', 'OVERLAP', 'OVERLAP_STICKY'];
export const NAVIGATION_VIEW_TYPE = ['STANDARD', 'COMPACT'];
export const SUBSCRIPTION_POSITIONS = ['STANDARD', 'TOP'];
export const TEXT_VIEW_DIRECTION = ['STANDARD', 'TOP'];
export const INPUT_SHAPE_VARIANT = ['STANDARD', 'FILLED', 'OUTLINED'];
export const PRODUCTS_TABLE_VIEW_TYPE = ['STANDARD', 'WIDE'];
export const PRODUCTS_FIXED_IMAGE_HEIGHT_MIN = 100;
export const PRODUCTS_FIXED_IMAGE_HEIGHT_MAX = 1000;
export const DEFAULT_PRODUCT_FIXED_HEIGHT = 500;
export const DEFAULT_PRODUCT_FIXED_MOBILE_HEIGHT = 500;
export const PRODUCT_VARIANT_VIEW_TYPE = ['DROPDOWN', 'SELECT'];
export const PRODUCT_FOOTER_VIEW_TYPE = ['TAB', 'DROPDOWN'];
export const PRODUCT_FOOTER_VIEW_POSITION = [
  {
    label: 'Standard',
    value: 'STANDARD',
    icon: textAlignLeft
  },
  {
    label: 'Center',
    value: 'CENTERED',
    icon: textAlignCenter
  }
];
export const RECENTLY_VIEWED_SCROLL_DIRECTION = [
  {
    label: 'Horizontal',
    value: 'HORIZONTAL',
    icon: arrowLeftRight
  },
  {
    label: 'Vertical',
    value: 'VERTICAL',
    icon: arrowUpDown
  }
];

export const GENERAL_CONTACT_VIEW_DIRECTION = [
  {
    label: 'Horizontal',
    value: 'HORIZONTAL',
    icon: arrowLeftRight
  },
  {
    label: 'Vertical',
    value: 'VERTICAL',
    icon: arrowUpDown
  }
];

export const PRODUCTS_TAG_POSITION = [
  {
    label: 'Top Left',
    value: 'TOP_LEFT',
    icon: arrowTopLeftBoldOutline
  },
  {
    label: 'Top Right',
    value: 'TOP_RIGHT',
    icon: arrowTopRightBoldOutline
  },
  {
    label: 'Bottom Left',
    value: 'BOTTOM_LEFT',
    icon: arrowBottomLeftBoldOutline
  },
  {
    label: 'Bottom Right',
    value: 'BOTTOM_RIGHT',
    icon: arrowBottomRightBoldOutline
  }
];
export const PRODUCTS_ITEM_DESCRIPTION_VIEW_TYPE = ['TEXT', 'DROPDOWN'];
export const FILTER_INDICATOR_TYPE = [null, 'DOT', 'COUNT'];
export const ADD_TO_CART_BUTTON_ICONS = [
  {
    label: 'Cart',
    value: 'CART'
  },
  {
    label: 'Basket',
    value: 'BASKET'
  },
  { label: 'Plus', value: 'PLUS' },
  { label: 'Plus One', value: 'PLUS_ONE' },
  {
    label: 'Package Plus',
    value: 'CUBE'
  }
];

export const CART_BUTTON_VIEW_TYPE = [
  {
    label: 'Text',
    value: 'TEXT',
    icon: roundTextFields
  },
  {
    label: 'Remove',
    value: 'REMOVE',
    icon: roundClose
  },
  {
    label: 'Trash Bin',
    value: 'BIN',
    icon: binIcon
  }
];

export const CHECKOUT_SELECTION_VIEW_TYPE = ['CHECKBOX', 'SWITCH'];
export const CART_PREVIEW_VIEW_TYPE = ['STANDARD', 'COMPACT'];
export const HOME_COMPONENT_VERTICAL_VIEW_TYPE = ['STANDARD', 'WIDE'];

export const CURRENCY_TYPE = [
  {
    label: 'Dropdown',
    value: 'STANDARD',
    icon: arrowTopLeftBoldOutline
  },
  {
    label: 'Button',
    value: 'BUTTON',
    icon: arrowTopLeftBoldOutline
  }
];
export const CURRENCY_BUTTON_FILLED = [
  {
    label: 'Filled',
    value: 'FILLED',
    icon: bulbFilled
  },
  {
    label: 'Outlined',
    value: 'STANDARD',
    icon: bulbOutlined
  }
];

export const CURRENCY_VERTICAL_POSITIONS = [
  {
    label: 'Top',
    value: 'TOP',
    icon: topAlign
  },
  {
    label: 'Bottom',
    value: 'BOTTOM',
    icon: bottomAlign
  }
];

export const TOOLBAR_BUTTON_FILLED = [
  {
    label: 'Filled',
    value: 'FILLED',
    icon: bulbFilled
  },
  {
    label: 'Standard',
    value: 'STANDARD',
    icon: bulbOutlined
  }
];

export const LAYOUT_NAVIGATION_ITEM_VIEW = [
  {
    label: 'Standard',
    value: 'STANDARD'
  },
  {
    label: 'Compact',
    value: 'COMPACT'
  }
];

export const IMAGE_AND_TEXT_VIEW_TYPE = ['COMPACT', 'STANDARD'];
export const DEFAULT_IMAGE_AND_TEXT_MOBILE_HEIGHT = 400;
export const DEFAULT_IMAGE_AND_TEXT_HEIGHT = 500;
export const SLIDER_IMAGE_AND_TEXT_MIN_HEIGHT = 400;
export const SLIDER_IMAGE_AND_TEXT_MAX_HEIGHT = 850;
export const GENERIC_FIXED_IMAGE_ANCHOR_POSITIONS = ['TOP', 'CENTER', 'BOTTOM'];

export const GALLERY_IMAGES_VIEW_TYPE = ['STANDARD', 'WIDE'];
export const DEFAULT_GALLERY_IMAGES_HEIGHT = 200;
export const SLIDER_GALLERY_IMAGES_MIN_HEIGHT = 100;
export const SLIDER_GALLERY_IMAGES_MAX_HEIGHT = 400;
export const GALLERY_IMAGES_FIXED_IMAGE_ANCHOR_POSITIONS = ['TOP', 'CENTER', 'BOTTOM'];
