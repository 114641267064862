import { useSelector } from 'react-redux';
import { useDispatch } from 'src/redux/store';
import { updateEditorPage } from 'src/redux/slices/store';
import { Box, MenuItem, TextField } from '@material-ui/core';
import { find } from 'lodash';
import { HOME } from '../utils/editorPages';

export default function PageSelector({ sx }) {
  const {
    editor: { page },
    editorPages
  } = useSelector((state) => state.store);
  const selected = find(editorPages, (o) => o.value === page.value) || HOME;

  const dispatch = useDispatch();

  const handleChangePage = (value) => {
    dispatch(updateEditorPage(value));
  };

  return (
    <Box sx={{ ...sx }}>
      <TextField
        select
        size="small"
        value={selected.value}
        sx={{ width: '100%' }}
        inputProps={{
          sx: {
            color: 'secondary.contrastText'
          }
        }}
        onChange={(e) => {
          const selected = editorPages.find((_option) => _option.value === e.target.value);
          if (selected) {
            handleChangePage(selected);
          }
        }}
      >
        {editorPages.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.name}
          </MenuItem>
        ))}
      </TextField>
    </Box>
  );
}
