// material
import React from 'react';
import { defaultTo } from 'lodash';
import { styled } from '@material-ui/core/styles';
import { Stack, IconButton, Badge, Box } from '@material-ui/core';
import { Icon } from '@iconify/react';
import shoppingCartFill from '@iconify/icons-eva/shopping-cart-fill';
import searchFill from '@iconify/icons-eva/search-fill';
import { SYSTEM_PAGE, SEARCH_PATH, CART_PATH } from '../../../utils/pageTypes';

const ActionButtonStack = styled(Stack)(() => ({
  justifyContent: 'end',
  textAlign: 'end',
  alignSelf: 'center'
}));

const BadgeStyle = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    color: theme.palette.secondary.contrastText,
    backgroundColor: theme.palette.secondary.main
  }
}));

const ACTION_SIZES_MOBILE = {
  width: 25,
  height: 25
};
const ACTION_SIZES_DESKTOP = {
  width: 25,
  height: 25
};

export const HeaderButtons = ({
  isMobile,
  cartTotal,
  logoPosition,
  enableCartButton,
  enableSearch,
  handleNavigationClick
}) => {
  const size = isMobile ? ACTION_SIZES_MOBILE : ACTION_SIZES_DESKTOP;
  const buttonPadding = (theme) => (isMobile ? theme.spacing(0.5) : theme.spacing(1));
  const isRight = logoPosition === 'RIGHT';
  const showActionButtons = enableSearch || defaultTo(enableCartButton, true);
  return !showActionButtons ? (
    <Box sx={{ ...(!isRight && { flex: '1 0 0' }) }} />
  ) : (
    <ActionButtonStack
      spacing={isMobile ? 0 : 0.5}
      direction="row"
      sx={{
        ...(isRight && { ml: 1 }),
        ...(!isRight && { flex: '1 0 0' })
      }}
    >
      {enableSearch && (
        <IconButton
          color="inherit"
          key="search"
          disableRipple
          disableTouchRipple
          sx={{ padding: (theme) => buttonPadding(theme) }}
          onClick={() => handleNavigationClick(SYSTEM_PAGE, SEARCH_PATH)}
        >
          <Icon icon={searchFill} {...size} />
        </IconButton>
      )}
      <IconButton
        color="inherit"
        key="cart"
        disableRipple
        disableTouchRipple
        sx={{ padding: (theme) => buttonPadding(theme) }}
        onClick={() => handleNavigationClick(SYSTEM_PAGE, CART_PATH)}
      >
        <BadgeStyle
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          badgeContent={cartTotal}
          max={99}
        >
          <Icon icon={shoppingCartFill} {...size} />
        </BadgeStyle>
      </IconButton>
    </ActionButtonStack>
  );
};
