import EmptyContent from 'src/components/EmptyContent';
import GenericSelectListDialog from './GenericSelectListDialog';

export default function SelectNavigationListDialog(props) {
  return (
    <GenericSelectListDialog
      title="Choose Navigations"
      fallbackComponent={
        <EmptyContent
          title="No navigations found"
          description="Looks like you do not have any navigations at this time."
          img="/static/illustrations/illustration_empty_navigation.svg"
        />
      }
      {...props}
    />
  );
}
