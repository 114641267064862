import {
  Box,
  Typography,
  Stack,
  Divider,
  TextField,
  FormControlLabel,
  FormHelperText,
  Switch,
  Collapse
} from '@material-ui/core';
import ColourPicker from '../../../components/pickers/ColourPicker';
import { GENERIC_CONTENT_POSITIONS, SUBSCRIPTION_POSITIONS } from '../../../utils/constantsEditor';
import { SubscriptionViewPicker } from '../../../components/pickers/SubscriptionViewPicker';
import MultiOptionPicker from '../../../components/pickers/MultiOptionPicker';

export default function FooterSubscriptionEditorCommon({ form }) {
  const { values, errors, touched, setFieldValue, getFieldProps } = form;

  return (
    <>
      <Divider />

      <Stack spacing={2}>
        <Typography variant="body1">
          <strong>Options</strong>
        </Typography>
        <div>
          <FormControlLabel
            label="Enable Subscription"
            control={<Switch checked={values.showSubscrition} {...getFieldProps('showSubscrition')} />}
          />
          {touched.showSubscrition && errors.showSubscrition && (
            <FormHelperText error>{touched.showSubscrition && errors.showSubscrition}</FormHelperText>
          )}
        </div>

        <Collapse in={values.showSubscrition}>
          <Stack spacing={3}>
            <Divider />

            <Stack spacing={2}>
              <Typography variant="body1">
                <strong>Vertical Position</strong>
              </Typography>

              <Box>
                <SubscriptionViewPicker
                  value={values.subscription.viewType}
                  onChange={(newValue) => setFieldValue('subscription.viewType', newValue)}
                />
              </Box>
            </Stack>

            <Stack spacing={2}>
              <Typography variant="body1">
                <strong>Horizontal Position</strong>
              </Typography>

              <Stack spacing={2}>
                <Typography variant="overline" color="text.secondary">
                  <strong>Desktop</strong>
                </Typography>
                <MultiOptionPicker
                  data={GENERIC_CONTENT_POSITIONS}
                  value={values.subscription.horizontalPosition}
                  onChange={(_, newValue) => setFieldValue('subscription.horizontalPosition', newValue)}
                />
              </Stack>
            </Stack>

            <Divider />

            <Stack spacing={2}>
              <Typography variant="body1">
                <strong>Text Position</strong>
              </Typography>

              <Stack spacing={2}>
                <Typography variant="overline" color="text.secondary">
                  <strong>Desktop</strong>
                </Typography>
                <MultiOptionPicker
                  data={GENERIC_CONTENT_POSITIONS}
                  value={values.subscription.textPosition}
                  onChange={(_, newValue) => setFieldValue('subscription.textPosition', newValue)}
                />
                {touched?.subscription?.textPosition && errors?.subscription?.textPosition && (
                  <FormHelperText error>
                    {touched?.subscription?.textPosition && errors?.subscription?.textPosition}
                  </FormHelperText>
                )}
              </Stack>

              <Stack spacing={2}>
                <Typography variant="overline" color="text.secondary">
                  <strong>Mobile</strong>
                </Typography>
                <MultiOptionPicker
                  data={GENERIC_CONTENT_POSITIONS}
                  value={values.subscription.mobileTextPosition}
                  onChange={(_, newValue) => setFieldValue('subscription.mobileTextPosition', newValue)}
                />
                {touched?.subscription?.mobileTextPosition && errors?.subscription?.mobileTextPosition && (
                  <FormHelperText error>
                    {touched?.subscription?.mobileTextPosition && errors?.subscription?.mobileTextPosition}
                  </FormHelperText>
                )}
                <FormHelperText>
                  For mobile, all text and components inside the footer be vertically adjusted
                </FormHelperText>
              </Stack>
            </Stack>

            <Collapse in={values.subscription.viewType === SUBSCRIPTION_POSITIONS[1]}>
              <Stack spacing={2}>
                <Divider />

                <Typography variant="body1">
                  <strong>Divider</strong>
                </Typography>

                <div>
                  <FormControlLabel
                    label="Show Divider"
                    control={
                      <Switch
                        checked={values.subscription.showDivider}
                        {...getFieldProps('subscription.showDivider')}
                      />
                    }
                  />
                  {touched?.subscription?.showDivider && errors?.subscription?.showDivider && (
                    <FormHelperText error>
                      {touched?.subscription?.showDivider && errors?.subscription?.showDivider}
                    </FormHelperText>
                  )}
                </div>
                <Collapse in={values.subscription.showDivider}>
                  <div>
                    <ColourPicker
                      title="Divider"
                      value={values.subscription.dividerColour}
                      handleChangeColour={(value) => setFieldValue('subscription.dividerColour', value)}
                      handleClearColour={() => setFieldValue('subscription.dividerColour', null)}
                    />
                    {errors?.subscription?.dividerColour && (
                      <FormHelperText error>{errors?.subscription?.dividerColour}</FormHelperText>
                    )}
                  </div>
                </Collapse>
              </Stack>
            </Collapse>

            <Divider />

            <Typography variant="body1">
              <strong>Content</strong>
            </Typography>

            <TextField
              fullWidth
              autoComplete="off"
              label="Subscription Header"
              {...getFieldProps('subscription.title')}
              error={Boolean(touched.subscription?.title && errors.subscription?.title)}
              helperText={touched.subscription?.title && errors.subscription?.title}
            />
            <TextField
              rows={5}
              multiline
              fullWidth
              autoComplete="off"
              label="Description"
              {...getFieldProps('subscription.description')}
              error={Boolean(touched.subscription?.description && errors.subscription?.description)}
              helperText={touched.subscription?.description && errors.subscription?.description}
            />
            <TextField
              fullWidth
              autoComplete="off"
              label="Placeholder"
              {...getFieldProps('subscription.placeholder')}
              error={Boolean(touched.subscription?.placeholder && errors.subscription?.placeholder)}
              helperText={touched.subscription?.placeholder && errors.subscription?.placeholder}
            />

            <Divider />

            <Typography variant="body1">
              <strong>Button</strong>
            </Typography>

            <TextField
              fullWidth
              autoComplete="off"
              label="Button Text"
              {...getFieldProps('subscription.buttonText')}
              error={Boolean(touched.subscription?.buttonText && errors.subscription?.buttonText)}
              helperText={touched.subscription?.buttonText && errors.subscription?.buttonText}
            />

            <div>
              <ColourPicker
                title="Button"
                value={values.subscription.buttonColour}
                handleChangeColour={(value) => setFieldValue('subscription.buttonColour', value)}
                handleClearColour={() => setFieldValue('subscription.buttonColour', null)}
              />
              {errors?.subscription?.buttonColour && (
                <FormHelperText error>{errors?.subscription?.buttonColour}</FormHelperText>
              )}
            </div>
            <div>
              <ColourPicker
                title="Button Text"
                value={values.subscription.buttonTextColour}
                handleChangeColour={(value) => setFieldValue('subscription.buttonTextColour', value)}
                handleClearColour={() => setFieldValue('subscription.buttonTextColour', null)}
              />
              {errors?.subscription?.buttonTextColour && (
                <FormHelperText error>{errors?.subscription?.buttonTextColour}</FormHelperText>
              )}
            </div>
          </Stack>
        </Collapse>
      </Stack>
    </>
  );
}
