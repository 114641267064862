import { styled } from '@material-ui/core/styles';
import { useState } from 'react';
import { Icon } from '@iconify/react';
import connectIcon from '@iconify/icons-icon-park-outline/connect';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Form, FormikProvider, useFormik } from 'formik';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Typography,
  Stack,
  Button,
  TextField,
  IconButton,
  MenuItem,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TableContainer
} from '@material-ui/core';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import EmptyContent from 'src/components/EmptyContent';
import { defaultTo, find, isEmpty } from 'lodash';
import InfoBlock from 'src/components/_admin_v2/InfoBlock';

const ManageSocialsTable = ({ socials, handleUpdateSocialLink, handleRemoveSocialLink }) => (
  <TableContainer>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell align="center">Type</TableCell>
          <TableCell align="center">Link</TableCell>
          <TableCell align="center" padding="none" width={10} />
        </TableRow>
      </TableHead>

      <TableBody>
        {socials.map((_social, index) => {
          const { name, href } = _social;
          return (
            <TableRow key={index}>
              <TableCell>
                <Typography variant="body2">{name}</Typography>
              </TableCell>

              <TableCell align="right">
                <TextField defaultValue={href} onChange={(e) => handleUpdateSocialLink(e, _social, index)} />
              </TableCell>
              <TableCell>
                <IconButton size="medium" type="button" onClick={() => handleRemoveSocialLink(index)}>
                  <Icon icon={trash2Outline} width={20} />
                </IconButton>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  </TableContainer>
);

const AddRowSection = ({ options, handleAddRow }) => {
  const [value, setValue] = useState(options[0]?.key);
  return (
    <Stack direction="row" spacing={2}>
      <TextField value={value} fullWidth label="Social Media" select onChange={(e) => setValue(e.target.value)}>
        {options.map((option, index) => (
          <MenuItem key={index} value={option.key}>
            {option.name}
          </MenuItem>
        ))}
      </TextField>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center'
        }}
      >
        <IconButton onClick={() => handleAddRow(value)}>
          <Icon icon={plusFill} width={25} height={25} />
        </IconButton>
      </Box>
    </Stack>
  );
};

const SelectSocialsDialog = ({ socials, options, open, onClose, handleSave }) => {
  const formik = useFormik({
    enableReinitialize: false,
    initialValues: {
      socials: defaultTo(socials, [])
    },
    onSubmit: async (values) => {
      const { socials } = values;
      handleSave(socials);
    }
  });

  const { values, isSubmitting, handleSubmit, setFieldValue } = formik;

  const handleAddRow = (key) => {
    const selected = find(options, (_obj) => _obj.key === key);
    if (selected) {
      const update = [...values.socials];
      update.push({
        ...selected,
        href: ''
      });
      setFieldValue('socials', update);
    }
  };

  const handleUpdateSocialLink = (e, current, index) => {
    const update = [...values.socials];
    update[index] = {
      ...current,
      href: e.target.value
    };
    setFieldValue('socials', update);
  };

  const handleRemoveSocialLink = (index) => {
    const update = [...values.socials];
    update.splice(index, 1);
    setFieldValue('socials', update);
  };

  return (
    <Dialog fullWidth open={open} onClose={onClose} scroll="paper">
      <FormikProvider value={formik}>
        <Form autoComplete="off" onSubmit={handleSubmit}>
          <DialogTitle sx={{ pb: 2 }}>Manage Socials</DialogTitle>
          <DialogContent dividers>
            <Stack spacing={2} direction="column">
              <AddRowSection options={options} handleAddRow={handleAddRow} />
              {isEmpty(values.socials) ? (
                <EmptyContent
                  title="No socials found"
                  description="Looks like you do not have any socials at this time."
                  img="/static/illustrations/illustration_empty_navigation.svg"
                />
              ) : (
                <ManageSocialsTable
                  socials={values.socials}
                  handleUpdateSocialLink={handleUpdateSocialLink}
                  handleRemoveSocialLink={handleRemoveSocialLink}
                />
              )}
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button disabled={isSubmitting} onClick={onClose}>
              Cancel
            </Button>
            <Button type="submit" variant="contained">
              Okay
            </Button>
          </DialogActions>
        </Form>
      </FormikProvider>
    </Dialog>
  );
};

const IconContainer = styled(Box)(({ theme }) => ({
  width: 64,
  height: 64,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.grey[200],
  marginRight: theme.spacing(2),
  borderRadius: theme.shape.borderRadiusCircle
}));

export const SelectSocials = ({ values, options, handleSave }) => {
  const [open, setOpen] = useState(false);

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleOpenDialog = () => {
    setOpen(true);
  };

  const onOptionsSave = (options) => {
    handleCloseDialog();
    handleSave(options);
  };

  return (
    <>
      <SelectSocialsDialog
        open={open}
        socials={values}
        options={options}
        onClose={handleCloseDialog}
        handleSave={onOptionsSave}
      />
      <Box sx={{ p: (theme) => theme.spacing(3), border: (theme) => `solid 1px ${theme.palette.divider}` }}>
        <Stack direction="row" spacing={1}>
          <div>
            <IconContainer>
              <Icon icon={connectIcon} width={20} height={20} />
            </IconContainer>
          </div>

          <Stack spacing={2} sx={{ width: '100%' }}>
            <InfoBlock
              caption="Social Media"
              titleObj={
                <Typography variant="body2">
                  <strong>{isEmpty(values) ? 'No Socials Selected' : `${values.length} Socials Selected`}</strong>
                </Typography>
              }
            />
            <Button fullWidth variant="contained" size="small" onClick={handleOpenDialog}>
              Manage Socials
            </Button>
          </Stack>
        </Stack>
      </Box>
    </>
  );
};
