import * as Yup from 'yup';
import { styled } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack5';
import { Form, FormikProvider, useFormik } from 'formik';
import { Box, Typography, TextField, Stack, Divider, Button, FormHelperText } from '@material-ui/core';
import { defaultTo } from 'lodash';
import { InputVariantPicker } from '../../components/pickers/InputVariantPicker';
import { INPUT_SHAPE_VARIANT } from '../../utils/constantsEditor';

const EditorConatiner = styled(Box)(({ theme }) => ({
  padding: theme.spacing(5)
}));

const ExampleView = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.grey[200],
  border: `2px solid ${theme.palette.divider}`,
  width: '100%',
  borderRadius: theme.shape.borderRadiusSm,
  padding: theme.spacing(3)
}));

const ExampleInputView = ({ variant }) => {
  const type = () => {
    if (variant === INPUT_SHAPE_VARIANT[0]) {
      return 'standard';
    }
    if (variant === INPUT_SHAPE_VARIANT[1]) {
      return 'filled';
    }
    return 'outlined';
  };
  return (
    <ExampleView>
      <TextField
        fullWidth
        variant={type()}
        name="Example"
        label="Example"
        defaultValue="Example"
        placeholder="Type your text here"
      />
    </ExampleView>
  );
};

export default function AppearanceShapesEditor({ config, heading, closeEdit, handleUpdateComponent }) {
  const { enqueueSnackbar } = useSnackbar();

  const GeneralShapesSchema = Yup.object().shape({
    input: Yup.string().nullable().required('Input appearance option is required')
  });

  const form = useFormik({
    initialValues: {
      input: defaultTo(config?.input, '')
    },
    validationSchema: GeneralShapesSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        handleUpdateComponent({
          ...config,
          ...values
        });
        enqueueSnackbar('Saved', { variant: 'success' });
        setSubmitting(false);
      } catch (error) {
        console.error(error);
        setSubmitting(false);
      }
    }
  });

  const { initialValues, values, touched, errors, setFieldValue, resetForm, handleSubmit } = form;

  return (
    <FormikProvider value={form}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <EditorConatiner>
          <Stack spacing={3}>
            <Stack spacing={2} justifyContent="flex-end" direction="row">
              <Button variant="outlined" color="primary" onClick={resetForm} disabled={initialValues === values}>
                Reset
              </Button>
              <Box sx={{ flexGrow: 1 }} />
              <Button variant="outlined" color="primary" onClick={closeEdit}>
                Cancel
              </Button>
              <Button variant="contained" color="primary" type="submit">
                Save
              </Button>
            </Stack>
            <Typography variant="h5">{heading}</Typography>

            <Divider />

            <Stack spacing={2}>
              <Typography variant="body1">
                <strong>TextField</strong>
              </Typography>

              <ExampleInputView variant={values.input} />

              <div>
                <InputVariantPicker value={values.input} onChangeShape={(value) => setFieldValue('input', value)} />
              </div>
              {touched.input && errors.input && <FormHelperText error>{touched.input && errors.input}</FormHelperText>}
              <FormHelperText>This will change the appearance of all Textbox input.</FormHelperText>
            </Stack>
          </Stack>
        </EditorConatiner>
      </Form>
    </FormikProvider>
  );
}
