import React, { useCallback, useEffect, useState } from 'react';
import { Stack, Box, Container } from '@material-ui/core';
import { isEmpty } from 'lodash';
import useMobile from '../../hooks/useMobile';
import { ProductView } from '../product/ProductView';
import { ComponentHeader } from './ComponentHeader';
import { PRODUCTS_TABLE_VIEW_TYPE } from '../../utils/constants';

export default function HomeFeaturedProduct({
  config,
  currency,
  component,
  handleNavigationClick,
  handleIsAddedToCart,
  handleAddToCart,
  handleRemoveFromCart,
  handleBuyNow,
  handleGetProduct
}) {
  const [product, setProduct] = useState(null);
  const isMobile = useMobile();

  const {
    title = '',
    caption = '',
    titleColour,
    captionColour,
    resource,
    showMobileHeadings,
    productViewType
  } = component;
  const isWideView = productViewType === PRODUCTS_TABLE_VIEW_TYPE[1];
  const isHeaderEmpty = Boolean(isEmpty(caption) && isEmpty(title));
  const hideHeadings = isMobile && !showMobileHeadings;
  const showHeader = Boolean(!hideHeadings && !isHeaderEmpty);
  const appearance = config?.global?.theme?.appearance;

  const getProducts = useCallback(() => {
    handleGetProduct(resource).then((_product) => {
      setProduct(_product);
    });
  }, [resource, handleGetProduct]);

  useEffect(() => {
    getProducts();
  }, [getProducts]);

  return (
    <Box
      sx={{
        ...(showHeader && { padding: (theme) => (isWideView ? theme.spacing(0) : theme.spacing(10, 0)) }),
        ...(Boolean(!showHeader && !isWideView) && { padding: (theme) => theme.spacing(12, 0) })
      }}
    >
      <Container
        maxWidth={isWideView ? false : 'lg'}
        sx={{
          '&.MuiContainer-root': {
            ...(isWideView && { paddingLeft: 0, paddingRight: 0 })
          }
        }}
      >
        <Stack spacing={8}>
          {showHeader && (
            <ComponentHeader
              isWideView={isWideView}
              caption={caption}
              title={title}
              titleColour={titleColour}
              captionColour={captionColour}
            />
          )}
          <ProductView
            isWidget
            isWideView={isWideView}
            product={product}
            currency={currency}
            config={{
              ...component,
              ...config?.global?.theme?.colours?.product,
              appearance
            }}
            handleNavigationClick={handleNavigationClick}
            handleIsAddedToCart={handleIsAddedToCart}
            handleAddToCart={handleAddToCart}
            handleRemoveFromCart={handleRemoveFromCart}
            handleBuyNow={handleBuyNow}
          />
        </Stack>
      </Container>
    </Box>
  );
}
