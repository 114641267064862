import React from 'react';
import { Box, Stack, Typography } from '@material-ui/core';
import { alpha, styled } from '@material-ui/core/styles';
import {
  DEFAULT_BOOKING_FAILURE_NEXT_STEPS_TITLE,
  DEFAULT_BOOKING_FAILURE_NEXT_STEPS_MESSAGE,
  DEFAULT_BOOKING_FAILURE_TITLE,
  DEFAULT_BOOKING_FAILURE_MESSAGE
} from '../../../../utils/constants';
import Markdown from '../../../core/Markdown';

const SectionRootStyle = styled(Box)(({ theme }) => ({
  boxShadow: 'none',
  borderRadius: theme.shape.borderRadius,
  border: `solid 1px ${alpha(theme.palette.border, 0.32)}`,
  padding: theme.spacing(2)
}));

const BookingFailureHeading = ({ title, description }) => {
  return (
    <Stack spacing={0.5}>
      <Typography variant="h5" color="error.main">
        {title || DEFAULT_BOOKING_FAILURE_TITLE}
      </Typography>
      <Markdown children={description || DEFAULT_BOOKING_FAILURE_MESSAGE} />
    </Stack>
  );
};

const BookingReviewSection = ({ title, description }) => {
  return (
    <SectionRootStyle>
      <Stack spacing={1}>
        <Typography variant="h6">{title}</Typography>
        <Markdown children={description} />
      </Stack>
    </SectionRootStyle>
  );
};

export const BookingFailurePage = ({ config }) => {
  const title = config?.summary?.genericFailure?.title;
  const message = config?.summary?.genericFailure?.message;
  const nextStepsTitle = config?.summary?.genericFailure?.nextStepsTitle;
  const nextStepsMessage = config?.summary?.genericFailure?.nextStepsMessage;
  return (
    <Stack spacing={3}>
      <BookingFailureHeading title={title} description={message} />
      <Stack spacing={2}>
        <BookingReviewSection
          title={nextStepsTitle || DEFAULT_BOOKING_FAILURE_NEXT_STEPS_TITLE}
          description={nextStepsMessage || DEFAULT_BOOKING_FAILURE_NEXT_STEPS_MESSAGE}
        />
      </Stack>
    </Stack>
  );
};
