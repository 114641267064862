import { styled } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack5';
import { Form, FormikProvider, useFormik } from 'formik';
import { Box, Typography, Stack, Divider, Button, TextField } from '@material-ui/core';
import { defaultTo } from 'lodash';

const EditorConatiner = styled(Box)(({ theme }) => ({
  padding: theme.spacing(5)
}));

export default function CheckoutMessagesEditor({ config, heading, closeEdit, handleUpdateComponent }) {
  const { enqueueSnackbar } = useSnackbar();

  const form = useFormik({
    initialValues: {
      shipping: {
        title: defaultTo(config?.shipping?.title, ''),
        description: defaultTo(config?.shipping?.description, '')
      },
      payments: {
        title: defaultTo(config?.payments?.title, ''),
        description: defaultTo(config?.payments?.description, '')
      }
    },

    onSubmit: async (values, { setSubmitting }) => {
      try {
        handleUpdateComponent(values);
        enqueueSnackbar('Saved', { variant: 'success' });
        setSubmitting(false);
      } catch (error) {
        console.error(error);
        setSubmitting(false);
      }
    }
  });

  const { initialValues, values, getFieldProps, resetForm, handleSubmit } = form;

  return (
    <FormikProvider value={form}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <EditorConatiner>
          <Stack spacing={3}>
            <Stack spacing={2} justifyContent="flex-end" direction="row">
              <Button variant="outlined" color="primary" onClick={resetForm} disabled={initialValues === values}>
                Reset
              </Button>
              <Box sx={{ flexGrow: 1 }} />
              <Button variant="outlined" color="primary" onClick={closeEdit}>
                Cancel
              </Button>
              <Button variant="contained" color="primary" type="submit">
                Save
              </Button>
            </Stack>
            <Typography variant="h5">{heading}</Typography>

            <Divider />

            <Stack spacing={2}>
              <div>
                <Typography variant="body1">
                  <strong>Empty Shipping Options</strong>
                </Typography>
                <Typography
                  variant="subtitle2"
                  color="text.secondary"
                  sx={{ fontWeight: (theme) => theme.typography.fontWeightRegular, my: (theme) => theme.spacing(1) }}
                >
                  This is displayed when a customer reaches the shipping step at checkout and there are no shipping
                  options available to choose
                </Typography>
              </div>
              <TextField fullWidth autoComplete="off" label="Title" {...getFieldProps('shipping.title')} />
              <TextField
                rows={5}
                multiline
                fullWidth
                autoComplete="off"
                label="Description"
                {...getFieldProps('shipping.description')}
              />
            </Stack>

            <Divider />

            <Stack spacing={2}>
              <div>
                <Typography variant="body1">
                  <strong>Empty Payment Methods</strong>
                </Typography>
                <Typography
                  variant="subtitle2"
                  color="text.secondary"
                  sx={{ fontWeight: (theme) => theme.typography.fontWeightRegular, my: (theme) => theme.spacing(1) }}
                >
                  This is displayed when a customer reaches the payment step at checkout and there are no payment
                  methods available to choose
                </Typography>
              </div>
              <TextField fullWidth autoComplete="off" label="Title" {...getFieldProps('payments.title')} />
              <TextField
                rows={5}
                multiline
                fullWidth
                autoComplete="off"
                label="Description"
                {...getFieldProps('payments.description')}
              />
            </Stack>
          </Stack>
        </EditorConatiner>
      </Form>
    </FormikProvider>
  );
}
