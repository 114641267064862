import React, { useCallback, useEffect, useState } from 'react';
import { ceil, defaultTo, isEmpty } from 'lodash';
import { Icon } from '@iconify/react';
import checkmarkFill from '@iconify/icons-fluent/checkmark-20-regular';
import { Box, Stack, Pagination, Button, Typography } from '@material-ui/core';
import { alpha, styled } from '@material-ui/core/styles';
import { BookingTextBlock } from '../BookingTextBlock';
import EmptyContent from '../../core/EmptyContent';
import { isInvalid } from '../../../utils/nullable';
import { fCurrency } from '../../../utils/formatNumber';
import {
  DEFAULT_BOOKING_SERVICES_EMPTY_DESCRIPTION,
  DEFAULT_BOOKING_SERVICES_EMPTY_TITLE
} from '../../../utils/constants';
import { Loader } from '../../core/Loader';
import Label from '../../core/Label';

const INITIAL_STATE = {
  loading: false,
  data: null
};

const DEFAULT_PAGE_SIZE = 20;

const EmptyRootStyle = styled(EmptyContent)(({ theme }) => ({
  boxShadow: 'none',
  borderRadius: theme.shape.borderRadius,
  border: `solid 1px ${alpha(theme.palette.border, 0.32)}`,
  padding: theme.spacing(2)
}));

const ServiceOptionRootStyle = styled(Button)(({ theme }) => ({
  boxShadow: 'none',
  borderRadius: theme.shape.borderRadius,
  border: `solid 1px ${alpha(theme.palette.border, 0.32)}`,
  padding: theme.spacing(2),
  color: 'unset'
}));

const ServiceOption = ({ currency, service, isOptionActive, handleClickOption }) => {
  const { symbol } = currency;
  const { id, name, description, price, duration: durationInMins } = service;
  const supportText = [];
  if (price) {
    const formattedPrice = fCurrency(price?.amount, symbol);
    supportText.push(formattedPrice);
  }
  if (durationInMins) {
    supportText.push(`${durationInMins} min`);
  }
  const isSelected = isOptionActive(id);

  return (
    <ServiceOptionRootStyle onClick={() => handleClickOption(service)}>
      <BookingTextBlock
        title={name}
        description={description}
        supportText={supportText}
        {...(isSelected && {
          activeComponent: (
            <Box>
              <Label color="success" sx={{ p: (theme) => theme.spacing(1.5), mt: 1 }}>
                <Stack spacing={0.5} direction="row">
                  <Icon icon={checkmarkFill} fontSize={14} style={{ color: 'inherit', alignSelf: 'center' }} />
                  <Typography
                    variant="caption"
                    color="success"
                    sx={{ fontSize: (theme) => theme.typography.pxToRem(12) }}
                  >
                    Added
                  </Typography>
                </Stack>
              </Label>
            </Box>
          )
        })}
      />
    </ServiceOptionRootStyle>
  );
};

const BookingServicesList = ({ options, ...others }) => (
  <Stack spacing={2}>
    {defaultTo(options?.data, []).map((option) => (
      <ServiceOption key={option.id} service={option} {...others} />
    ))}
  </Stack>
);

export const BookingServicesPage = ({
  messages,
  currency,
  handleGetBookingServices,
  handleCheckServiceSelected,
  handleUpdateServices
}) => {
  const message = messages?.services;
  const [state, setState] = useState(INITIAL_STATE);
  const servicesData = state.data;
  const isServiceDataEmpty = isEmpty(servicesData?.data);
  const isLoading = state.loading;
  const pageCount = ceil(defaultTo(servicesData?.total, 0) / DEFAULT_PAGE_SIZE);

  const handleGetServices = useCallback(
    async (page) => {
      try {
        setState((prev) => ({ ...prev, loading: true }));
        const { code: currencyCode } = currency;
        const response = await handleGetBookingServices(currencyCode, defaultTo(page, 0), DEFAULT_PAGE_SIZE);
        setState({ data: response, loading: false });
      } catch (e) {
        setState({ data: false, loading: false });
      }
    },
    [currency, setState, handleGetBookingServices]
  );

  const handleChangePage = useCallback(
    (newPage) => {
      handleGetServices(newPage);
    },
    [handleGetServices]
  );

  useEffect(() => {
    if (servicesData === INITIAL_STATE.data && !isLoading) {
      handleGetServices();
    }
  }, [servicesData, isLoading, handleGetServices]);

  return (
    <Stack spacing={2}>
      {isLoading ? (
        <Box sx={{ height: '100px' }}>
          <Loader />
        </Box>
      ) : (
        <>
          {isServiceDataEmpty ? (
            <EmptyRootStyle
              title={isInvalid(message?.title) ? DEFAULT_BOOKING_SERVICES_EMPTY_TITLE : message?.title}
              description={
                isInvalid(message?.description) ? DEFAULT_BOOKING_SERVICES_EMPTY_DESCRIPTION : message?.description
              }
              variant="h5"
            />
          ) : (
            <BookingServicesList
              options={servicesData}
              currency={currency}
              isOptionActive={handleCheckServiceSelected}
              handleClickOption={handleUpdateServices}
            />
          )}
        </>
      )}
      {servicesData?.total > DEFAULT_PAGE_SIZE && (
        <Box sx={{ width: '100%', justifyItems: 'center' }}>
          <Pagination
            color="primary"
            count={pageCount}
            page={defaultTo(servicesData?.page, 0) + 1}
            onChange={(_, value) => handleChangePage(value - 1)}
          />
        </Box>
      )}
    </Stack>
  );
};
