// material
import React, { useMemo } from 'react';
import { Box, Grid, Stack } from '@material-ui/core';
import { HeaderNavigationTab } from './HeaderNavigationTab';
import { HeaderLogo } from './HeaderLogo';
import { HeaderButtons } from './HeaderButtons';
import HeaderToolbarStyle from './styled/HeaderToolbarStyle';
import { APPBAR_DESKTOP_COMPACT } from './util/HeaderConstant';

const NAVIGATION_FULL_CENTER_STYLE = {
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  height: '100%',
  margin: '0 auto !important'
};

const CompactHeader = (props) => {
  const {
    enableNavigation,
    enableMaxNavigationSize,
    maxNavigationSize,
    isStandardView,
    logo,
    isMobile,
    cartTotal,
    logoPosition,
    showLogo,
    enableSearch,
    enableCartButton,
    navigationViewType,
    showDivider,
    textColour,
    isNavigationActive,
    currentTabValue,
    navigation,
    navigationPosition,
    handleNavigationClick
  } = props;

  const isRight = logoPosition === 'RIGHT';
  const isCenter = logoPosition === 'CENTER';
  const isLeft = logoPosition === 'LEFT';
  const isNavigationCenter = navigationPosition === 'CENTER';

  const LogoMemo = useMemo(
    () => (
      <HeaderLogo
        isMobile={isMobile}
        isStandardView={isStandardView}
        logoPosition={logoPosition}
        logo={logo}
        handleNavigationClick={handleNavigationClick}
      />
    ),
    [isMobile, isStandardView, logoPosition, logo, handleNavigationClick]
  );
  const ActionButtons = useMemo(
    () => (
      <HeaderButtons
        isMobile={isMobile}
        cartTotal={cartTotal}
        logoPosition={logoPosition}
        enableCartButton={enableCartButton}
        enableSearch={enableSearch}
        handleNavigationClick={handleNavigationClick}
      />
    ),
    [isMobile, cartTotal, logoPosition, enableCartButton, enableSearch, handleNavigationClick]
  );

  const CompactCenterView = (
    <Box sx={{ height: '100%', width: '100%' }}>
      <Grid container direction="row" sx={{ height: '100%', width: '100%' }}>
        <Grid item sm={4} alignContent="center">
          {enableNavigation && (
            <HeaderNavigationTab
              config={{
                enableMaxNavigationSize,
                maxNavigationSize,
                viewType: navigationViewType,
                showDivider,
                textColour
              }}
              enabled={isNavigationActive}
              activeTab={currentTabValue}
              navigation={navigation.data}
              position={navigationPosition}
              handleNavigationClick={handleNavigationClick}
            />
          )}
        </Grid>
        <Grid item sm={4} alignContent="center" sx={{ height: '100%' }}>
          {showLogo && LogoMemo}
        </Grid>
        <Grid item sm={4} alignContent="center">
          {ActionButtons}
        </Grid>
      </Grid>
    </Box>
  );

  const CompactWideView = (
    <Stack spacing={2} direction="row" justifyContent="space-between" sx={{ width: '100%' }}>
      {Boolean(showLogo && isLeft) && LogoMemo}
      {enableNavigation ? (
        <HeaderNavigationTab
          fullWidth={!isCenter}
          config={{ enableMaxNavigationSize, maxNavigationSize, viewType: navigationViewType, showDivider, textColour }}
          enabled={isNavigationActive}
          activeTab={currentTabValue}
          navigation={navigation.data}
          position={navigationPosition}
          handleNavigationClick={handleNavigationClick}
          {...(isNavigationCenter && { sx: { ...NAVIGATION_FULL_CENTER_STYLE } })}
        />
      ) : (
        <Box flexGrow={1} />
      )}
      {Boolean(showLogo && isRight) && LogoMemo}
      {!showLogo && <Box flexGrow={1} />}
      {ActionButtons}
    </Stack>
  );

  return (
    <HeaderToolbarStyle
      sx={{
        '&.MuiToolbar-root': {
          height: APPBAR_DESKTOP_COMPACT
        }
      }}
    >
      {isCenter ? CompactCenterView : CompactWideView}
    </HeaderToolbarStyle>
  );
};

export default CompactHeader;
