import React, { useState } from 'react';
import { Icon } from '@iconify/react';
import { defaultTo, truncate } from 'lodash';
import { Typography, Box, IconButton, Stack } from '@material-ui/core';
import moreHorizontalFill from '@iconify/icons-eva/more-horizontal-fill';
import { DEFAULT_PRODUCT_NOTE_TITLE } from '../../utils/constants';

const DEFAULT_TEXT_SIZE = 30;

export const CartNotesView = ({ isMobile, title, note }) => {
  const [textSize, setTextSize] = useState({
    length: DEFAULT_TEXT_SIZE,
    expanded: false
  });

  const handleToggleSize = () =>
    setTextSize((prev) => ({ length: prev.expanded ? DEFAULT_TEXT_SIZE : note?.length, expanded: !prev.expanded }));

  return (
    <Stack spacing={1} direction={isMobile || textSize.expanded ? 'column' : 'row'}>
      <Box>
        <Typography variant="body2">
          <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
            {defaultTo(title, DEFAULT_PRODUCT_NOTE_TITLE)}:&nbsp;
          </Typography>
          {truncate(defaultTo(note, ''), { length: textSize.length })}
        </Typography>
      </Box>
      <Box>
        <IconButton
          sx={{ height: 25, width: 40, borderRadius: (theme) => theme.spacing(3) }}
          onClick={handleToggleSize}
        >
          <Icon icon={moreHorizontalFill} />
        </IconButton>
      </Box>
    </Stack>
  );
};
