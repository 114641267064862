import React, { useCallback, useMemo } from 'react';
import { defaultTo } from 'lodash';
import { styled } from '@material-ui/core/styles';
import { Card, CardMedia, CardActionArea, Box, Typography, Stack } from '@material-ui/core';
import * as AspectRatioPrimitive from '@radix-ui/react-aspect-ratio';
import missingImageDefault from '../../static/placeholder/product_image.jpg';
import { MLazyLoadImage } from '../core/@react-lazy-load-image-component-extend';
import { varFadeIn, MotionInView } from '../core/animate';
import { DEFAULT_IMAGE_HEIGHT } from '../../utils/constants';
import { getImageFit, getImagePosition, getTextAlignPosition } from '../../utils/getPositions';
import { isInvalidText } from '../../utils/nullable';

export const AspectRatio = AspectRatioPrimitive;

const Root = styled(Box)(() => ({
  position: 'relative',
  cursor: 'pointer',
  padding: 0
}));

const CardStyle = styled(Card)(() => ({
  backgroundColor: 'transparent',
  boxShadow: 'none',
  borderRadius: 0
}));

const StyledCardMedia = styled(CardMedia)(({ theme }) => ({
  borderRadius: theme.shape.borderRadiusSm
}));

const StyledCardActionArea = styled(CardActionArea)(({ theme }) => ({
  borderRadius: theme.shape.borderRadiusSm
}));

export const GalleryImageItem = ({ isMobile, showImagePreview, item, handleOpenPreview, handleNavigationClick }) => {
  const {
    enableNavigation,
    title,
    textPosition,
    navigation,
    desktopImage,
    imagePosition,
    mobileFixedImageHeight,
    mobileFixedImageHeightValue,
    mobileFixedImageHeightPosition,
    fixedImageHeight,
    fixedImageHeightValue,
    fixedImageHeightPosition
  } = item;
  const fixedHeight = isMobile ? mobileFixedImageHeight : fixedImageHeight;
  const imageHeight = isMobile ? mobileFixedImageHeightValue : fixedImageHeightValue;
  const imageHeightPosition = isMobile ? mobileFixedImageHeightPosition : fixedImageHeightPosition;

  const handleCardClick = useCallback(() => {
    if (enableNavigation) {
      handleNavigationClick(navigation?.type, navigation?.path);
    }
    if (!enableNavigation) {
      handleOpenPreview(desktopImage);
    }
  }, [enableNavigation, navigation, desktopImage, handleNavigationClick, handleOpenPreview]);

  const ImageContentMemo = useMemo(
    () => (
      <Stack spacing={2}>
        {fixedHeight ? (
          <StyledCardMedia
            component={MLazyLoadImage}
            source={desktopImage || missingImageDefault}
            imgSx={{
              height: defaultTo(imageHeight, DEFAULT_IMAGE_HEIGHT),
              objectPosition: getImagePosition(imageHeightPosition),
              objectFit: getImageFit(imagePosition)
            }}
            sx={{
              height: defaultTo(imageHeight, DEFAULT_IMAGE_HEIGHT),
              width: '100%',
              borderRadius: (theme) => theme.shape.borderRadiusSm
            }}
          />
        ) : (
          <AspectRatio.Root ratio={1 / 1}>
            <StyledCardMedia
              component={MLazyLoadImage}
              source={desktopImage || missingImageDefault}
              imgSx={{
                height: '100%',
                width: '100%',
                objectPosition: getImagePosition(imageHeightPosition),
                objectFit: getImageFit(imagePosition)
              }}
              sx={{ height: '100%', width: '100%', borderRadius: (theme) => theme.shape.borderRadiusSm }}
            />
          </AspectRatio.Root>
        )}
        {!isInvalidText(title) && (
          <Box sx={{ px: 1 }}>
            <Typography variant="h6" color="primary" textAlign={getTextAlignPosition(textPosition)}>
              {title}
            </Typography>
          </Box>
        )}
      </Stack>
    ),
    [title, desktopImage, imagePosition, textPosition, fixedHeight, imageHeight, imageHeightPosition]
  );

  return (
    <Root>
      <MotionInView variants={varFadeIn}>
        <CardStyle
          {...((showImagePreview || enableNavigation) && { onClick: handleCardClick })}
          sx={{ ...(!showImagePreview && !enableNavigation && { cursor: 'default' }) }}
        >
          {showImagePreview ? <StyledCardActionArea>{ImageContentMemo}</StyledCardActionArea> : ImageContentMemo}
        </CardStyle>
      </MotionInView>
    </Root>
  );
};
