import {
  Box,
  Typography,
  Stack,
  Divider,
  FormHelperText,
  FormControlLabel,
  Switch,
  Collapse,
  TextField
} from '@material-ui/core';
import ColourPicker from '../../../components/pickers/ColourPicker';
import { BORDER_RADIUS_MAX, BORDER_SIZE_MAX } from '../common/footerConstants';

export default function FooterStyleEditorCommon({ form }) {
  const { values, errors, touched, setFieldValue, getFieldProps } = form;

  return (
    <>
      <Divider />

      <Typography variant="body1">
        <strong>Options</strong>
      </Typography>

      <div>
        <Box>
          <FormControlLabel
            label="Show Border"
            control={<Switch checked={values.showBorder} {...getFieldProps('showBorder')} />}
          />
          {touched.showBorder && errors.showBorder && (
            <FormHelperText error>{touched.showBorder && errors.showBorder}</FormHelperText>
          )}
        </Box>
        <Collapse in={values.showBorder}>
          <Box>
            <FormControlLabel
              label="Show Curved Borders"
              control={<Switch checked={values.showBorderRadius} {...getFieldProps('showBorderRadius')} />}
            />
            {touched.showBorderRadius && errors.showBorderRadius && (
              <FormHelperText error>{touched.showBorderRadius && errors.showBorderRadius}</FormHelperText>
            )}
          </Box>
        </Collapse>

        <Collapse in={values.showBorder} sx={{ ...(values.showBorder && { mt: (theme) => theme.spacing(3) }) }}>
          <Stack spacing={3}>
            <Box>
              <TextField
                fullWidth
                type="number"
                autoComplete="off"
                label="Border Size"
                {...getFieldProps('borderSize')}
                error={Boolean(touched.borderSize && errors.borderSize)}
                helperText={touched.borderSize && errors.borderSize}
              />
              <FormHelperText>
                This will control the curve of the borders. Maximum value {BORDER_SIZE_MAX}
              </FormHelperText>
            </Box>

            <Box>
              <ColourPicker
                title="Border"
                value={values.borderColour}
                handleChangeColour={(value) => setFieldValue('borderColour', value)}
                handleClearColour={() => setFieldValue('borderColour', null)}
              />
              {errors.borderColour && <FormHelperText error>{errors.borderColour}</FormHelperText>}
            </Box>

            <Collapse in={values.showBorderRadius}>
              <Box>
                <TextField
                  fullWidth
                  type="number"
                  autoComplete="off"
                  label="Curve Size"
                  {...getFieldProps('borderRadius')}
                  error={Boolean(touched.borderRadius && errors.borderRadius)}
                  helperText={touched.borderRadius && errors.borderRadius}
                />
                <FormHelperText>
                  This will control the curve of the borders. Maximum value {BORDER_RADIUS_MAX}
                </FormHelperText>
              </Box>
            </Collapse>
          </Stack>
        </Collapse>
      </div>

      <Divider />

      <Stack spacing={2}>
        <Typography variant="body1">
          <strong>Colours</strong>
        </Typography>
        <div>
          <ColourPicker
            title="Background"
            value={values.backgroundColour}
            handleChangeColour={(value) => setFieldValue('backgroundColour', value)}
            handleClearColour={() => setFieldValue('backgroundColour', null)}
          />
          {errors.backgroundColour && <FormHelperText error>{errors.backgroundColour}</FormHelperText>}
          <FormHelperText>This will change the default colour of the footer background</FormHelperText>
        </div>
        <div>
          <ColourPicker
            title="Contrast"
            value={values.primaryColour}
            handleChangeColour={(value) => setFieldValue('primaryColour', value)}
            handleClearColour={() => setFieldValue('primaryColour', null)}
          />
          {errors.primaryColour && <FormHelperText error>{errors.primaryColour}</FormHelperText>}
          <FormHelperText>This will change the default contrast colour of the footer text</FormHelperText>
        </div>
        <div>
          <ColourPicker
            title="Text"
            value={values.textColour}
            handleChangeColour={(value) => setFieldValue('textColour', value)}
            handleClearColour={() => setFieldValue('textColour', null)}
          />
          {errors.textColour && <FormHelperText error>{errors.textColour}</FormHelperText>}
          <FormHelperText>This will change the default colour of the footer text</FormHelperText>
        </div>
      </Stack>
    </>
  );
}
