import React from 'react';
import { Box, Stack, Typography } from '@material-ui/core';
import { alpha, styled } from '@material-ui/core/styles';
import Markdown from '../../../core/Markdown';
import {
  DEFAULT_BOOKING_CANCELLLATION_POLICY_MESSAGE,
  DEFAULT_BOOKING_CANCELLLATION_POLICY_TITLE,
  DEFAULT_BOOKING_PAYMENT_SUCCESS_MESSAGE,
  DEFAULT_BOOKING_PAYMENT_SUCCESS_TITLE,
  DEFAULT_BOOKING_SUCCESS_MESSAGE,
  DEFAULT_BOOKING_SUCCESS_TITLE
} from '../../../../utils/constants';

const SectionRootStyle = styled(Box)(({ theme }) => ({
  boxShadow: 'none',
  borderRadius: theme.shape.borderRadius,
  border: `solid 1px ${alpha(theme.palette.border, 0.32)}`,
  padding: theme.spacing(2)
}));

const BookingSuccessHeading = ({ title, description }) => {
  return (
    <Stack spacing={0.5}>
      <Typography variant="h5" color="success.main">
        {title || DEFAULT_BOOKING_SUCCESS_TITLE}
      </Typography>
      <Markdown children={description || DEFAULT_BOOKING_SUCCESS_MESSAGE} />
    </Stack>
  );
};

const BookingReviewSection = ({ title, description }) => {
  return (
    <SectionRootStyle>
      <Stack spacing={1}>
        <Typography variant="h6">{title}</Typography>
        <Markdown children={description} />
      </Stack>
    </SectionRootStyle>
  );
};

export const BookingSuccessPage = ({ config }) => {
  const showCancelPolicy = config?.general?.showCancelPolicy;
  const cancelPolicyTitle = config?.general?.cancelPolicyTitle;
  const cancelPolicyMessage = config?.general?.cancelPolicyMessage;
  const title = config?.summary?.success?.title;
  const message = config?.summary?.success?.message;
  const paymentTitle = config?.summary?.success?.paymentTitle;
  const paymentMessage = config?.summary?.success?.paymentMessage;

  return (
    <Stack spacing={3}>
      <BookingSuccessHeading title={title} description={message} />
      <Stack spacing={2}>
        <BookingReviewSection
          title={paymentTitle || DEFAULT_BOOKING_PAYMENT_SUCCESS_TITLE}
          description={paymentMessage || DEFAULT_BOOKING_PAYMENT_SUCCESS_MESSAGE}
        />
        {showCancelPolicy && (
          <BookingReviewSection
            title={cancelPolicyTitle || DEFAULT_BOOKING_CANCELLLATION_POLICY_TITLE}
            description={cancelPolicyMessage || DEFAULT_BOOKING_CANCELLLATION_POLICY_MESSAGE}
          />
        )}
      </Stack>
    </Stack>
  );
};
