import PropTypes from 'prop-types';
import useEditor from 'src/hooks/useEditor';
import useCollapseDrawer from 'src/hooks/useCollapseDrawer';
import AdminGenericSidebar from '../admin/AdminGenericSidebar';

export default function AdminStoreSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { sidebar } = useEditor();
  const { isCollapse, collapseClick, collapseHover, onHoverEnter, onHoverLeave } = useCollapseDrawer();

  return (
    <AdminGenericSidebar
      isOpenSidebar={isOpenSidebar}
      onCloseSidebar={onCloseSidebar}
      isCollapse={isCollapse}
      collapseClick={collapseClick}
      collapseHover={collapseHover}
      onHoverEnter={onHoverEnter}
      onHoverLeave={onHoverLeave}
    >
      {sidebar}
    </AdminGenericSidebar>
  );
}

AdminStoreSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func
};
