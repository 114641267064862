import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Box } from '@material-ui/core';
import useAuth from '../../hooks/useAuth';
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
import { useSelector } from '../../redux/store';
import { PATH_PAGE } from '../../routes/paths';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
import getSidebarConfig from './SidebarConfig';
import AdminGenericSidebar from './AdminGenericSidebar';
import AdminSidebarWrapper from './AdminSidebarWrapper';
import { AdminSidebarUserCard } from './AdminSidebarUserCard';

// ----------------------------------------------------------------------

export default function AdminSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();
  const { user } = useAuth();
  const { tenant } = useSelector((state) => state.tenant);
  const storeUrl = tenant?.storeUrl;
  const navConfig = getSidebarConfig(storeUrl);

  const { isCollapse, collapseClick, collapseHover, onToggleCollapse, onHoverEnter, onHoverLeave } =
    useCollapseDrawer();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <AdminGenericSidebar
      isOpenSidebar={isOpenSidebar}
      onCloseSidebar={onCloseSidebar}
      isCollapse={isCollapse}
      collapseClick={collapseClick}
      collapseHover={collapseHover}
      onHoverEnter={onHoverEnter}
      onHoverLeave={onHoverLeave}
    >
      <Scrollbar
        sx={{
          height: 1,
          '& .simplebar-content': {
            height: 1,
            display: 'flex',
            flexDirection: 'column'
          }
        }}
      >
        <AdminSidebarWrapper
          path={PATH_PAGE.home}
          isCollapse={isCollapse}
          collapseClick={collapseClick}
          onToggleCollapse={onToggleCollapse}
          headerSx={{
            px: 2.5
          }}
          sx={{
            pt: 3,
            pb: 2
          }}
        >
          <AdminSidebarUserCard isCollapse={isCollapse} user={user} tenant={tenant} />
        </AdminSidebarWrapper>

        <NavSection isDashboard navConfig={navConfig} isShow={!isCollapse} å />

        <Box sx={{ flexGrow: 1 }} />
      </Scrollbar>
    </AdminGenericSidebar>
  );
}

AdminSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func
};
