import * as Yup from 'yup';
import { useSnackbar } from 'notistack5';
import { Form, FormikProvider, useFormik } from 'formik';
import { styled } from '@material-ui/core/styles';
import {
  Box,
  Typography,
  TextField,
  Stack,
  Divider,
  Button,
  FormHelperText,
  FormControlLabel,
  Switch,
  Slider
} from '@material-ui/core';
import { defaultTo } from 'lodash';
import ColourPicker from '../../components/pickers/ColourPicker';
import { DEFAULT_SHOW_ANNOUNCEMENT_BAR_PROMINENT_TEXT, REQUIRED_OPTION_MESSAGE } from '../../utils/constants';
import { EditorConatiner } from '../../common/EditorConatiner';

const ANNOUNCEMENT_HEIGHT_MAX = 60;
const ANNOUNCEMENT_FONT_SIZE_MIN = 8;
const ANNOUNCEMENT_FONT_SIZE_MAX = 30;
const ANNOUNCEMENT_DEFAULT_FONT_SIZE = 12;

const SliderWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  border: `solid 1px ${theme.palette.divider}`
}));

export default function AnnouncementStyleEditor({ config, heading, closeEdit, handleUpdateComponent }) {
  const { enqueueSnackbar } = useSnackbar();

  const showProminent = config?.showProminent;
  const backgroundColour = config?.backgroundColour;
  const textColour = config?.textColour;
  const height = config?.height;
  const textSize = config?.textSize;

  const AnnouncementStyleSchema = Yup.object().shape({
    showProminent: Yup.boolean().required(REQUIRED_OPTION_MESSAGE),
    height: Yup.number()
      .required('Please choose a height value or set to 0')
      .min(0)
      .max(ANNOUNCEMENT_HEIGHT_MAX, `Maximum height value is ${ANNOUNCEMENT_HEIGHT_MAX}`),
    textSize: Yup.number()
      .required(REQUIRED_OPTION_MESSAGE)
      .min(8)
      .max(ANNOUNCEMENT_FONT_SIZE_MAX, `Maximum size is ${ANNOUNCEMENT_FONT_SIZE_MAX}`)
  });

  const form = useFormik({
    initialValues: {
      backgroundColour: defaultTo(backgroundColour, ''),
      textColour: defaultTo(textColour, ''),
      showProminent: defaultTo(showProminent, DEFAULT_SHOW_ANNOUNCEMENT_BAR_PROMINENT_TEXT),
      height: defaultTo(height, 0),
      textSize: defaultTo(textSize, ANNOUNCEMENT_DEFAULT_FONT_SIZE)
    },
    validationSchema: AnnouncementStyleSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        handleUpdateComponent(values);
        enqueueSnackbar('Saved', { variant: 'success' });
        setSubmitting(false);
      } catch (error) {
        console.error(error);
        setSubmitting(false);
      }
    }
  });

  const { values, errors, touched, handleSubmit, getFieldProps, setFieldValue } = form;

  return (
    <FormikProvider value={form}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <EditorConatiner>
          <Stack spacing={3}>
            <Stack spacing={2} justifyContent="flex-end" direction="row">
              <Button variant="outlined" color="primary" onClick={closeEdit}>
                Cancel
              </Button>
              <Button variant="contained" color="primary" type="submit">
                Save
              </Button>
            </Stack>
            <Typography variant="h5">{heading}</Typography>

            <Divider />

            <Stack spacing={2}>
              <Typography variant="body1">
                <strong>Options</strong>
              </Typography>
              <Stack spacing={0}>
                <FormControlLabel
                  label="Prominent announcement title"
                  control={<Switch checked={values.showProminent} {...getFieldProps('showProminent')} />}
                />
              </Stack>
            </Stack>

            <Divider />

            <Stack spacing={3}>
              <Typography variant="body1">
                <strong>Size</strong>
              </Typography>

              <Box>
                <TextField
                  fullWidth
                  type="number"
                  autoComplete="off"
                  label="Height"
                  {...getFieldProps('height')}
                  error={Boolean(touched.height && errors.height)}
                  helperText={touched.height && errors.height}
                />

                <FormHelperText>
                  <strong>Set value to 0 for a default height</strong>.&nbsp; Maximum height value is&nbsp;
                  {ANNOUNCEMENT_HEIGHT_MAX}.
                </FormHelperText>
              </Box>

              <SliderWrapper>
                <Typography variant="body2" sx={{ mb: 1 }}>
                  Text Size
                </Typography>
                <Stack direction="row">
                  <Typography variant="body2">{ANNOUNCEMENT_FONT_SIZE_MIN}</Typography>
                  <Slider
                    defaultValue={ANNOUNCEMENT_FONT_SIZE_MIN}
                    {...getFieldProps('textSize')}
                    valueLabelDisplay="auto"
                    step={1}
                    marks
                    min={ANNOUNCEMENT_FONT_SIZE_MIN}
                    max={ANNOUNCEMENT_FONT_SIZE_MAX}
                    sx={{ ml: 2, mr: 2 }}
                  />
                  <Typography variant="body2">{ANNOUNCEMENT_FONT_SIZE_MAX}</Typography>
                </Stack>
                {touched.textSize && errors.textSize && (
                  <FormHelperText error>{touched.textSize && errors.textSize}</FormHelperText>
                )}
              </SliderWrapper>
            </Stack>

            <Divider />

            <Stack spacing={2}>
              <Typography variant="body1">
                <strong>Colours</strong>
              </Typography>
              <div>
                <ColourPicker
                  title="Background"
                  value={values.backgroundColour}
                  handleChangeColour={(value) => setFieldValue('backgroundColour', value)}
                  handleClearColour={() => setFieldValue('backgroundColour', null)}
                />
                {errors.backgroundColour && <FormHelperText error>{errors.backgroundColour}</FormHelperText>}
                <FormHelperText>This will change the default colour of the announcement bar background</FormHelperText>
              </div>
              <div>
                <ColourPicker
                  title="Text"
                  value={values.textColour}
                  handleChangeColour={(value) => setFieldValue('textColour', value)}
                  handleClearColour={() => setFieldValue('textColour', null)}
                />
                {errors.textColour && <FormHelperText error>{errors.textColour}</FormHelperText>}
                <FormHelperText>This will change the default colour of the announcement text</FormHelperText>
              </div>
            </Stack>
          </Stack>
        </EditorConatiner>
      </Form>
    </FormikProvider>
  );
}
