import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Icon } from '@iconify/react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Box, Button, Collapse } from '@material-ui/core';
import arrowLeft from '@iconify/icons-mdi/arrow-left';
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
import { PATH_DASHBOARD, PATH_PAGE } from '../../routes/paths';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
import AdminGenericSidebar from './AdminGenericSidebar';
import AdminSidebarWrapper from './AdminSidebarWrapper';
import getSettingsSidebarConfig from './SidebarSettingsConfig';

// ----------------------------------------------------------------------

export default function AdminSettingsSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();
  const navConfig = getSettingsSidebarConfig();

  const { isCollapse, collapseClick, collapseHover, onToggleCollapse, onHoverEnter, onHoverLeave } =
    useCollapseDrawer();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <AdminGenericSidebar
      isOpenSidebar={isOpenSidebar}
      onCloseSidebar={onCloseSidebar}
      isCollapse={isCollapse}
      collapseClick={collapseClick}
      collapseHover={collapseHover}
      onHoverEnter={onHoverEnter}
      onHoverLeave={onHoverLeave}
    >
      <Scrollbar
        sx={{
          height: 1,
          '& .simplebar-content': {
            height: 1,
            display: 'flex',
            flexDirection: 'column'
          }
        }}
      >
        <AdminSidebarWrapper
          showPageTitle
          pageTitle="Settings"
          path={PATH_PAGE.home}
          isCollapse={isCollapse}
          collapseClick={collapseClick}
          onToggleCollapse={onToggleCollapse}
          headerSx={{
            px: 2.5
          }}
          sx={{
            pt: 3,
            pb: 2
          }}
        >
          <Collapse in={!isCollapse}>
            <Box
              sx={{
                padding: (theme) => theme.spacing(1, 2.5),
                width: '100%',
                ...(isCollapse && { opacity: '0' }),
                transition: (theme) =>
                  theme.transitions.create('all', {
                    duration: theme.transitions.duration.shortest
                  })
              }}
            >
              <Button
                LinkComponent={RouterLink}
                to={PATH_DASHBOARD.root}
                fullWidth
                variant="outlined"
                size="medium"
                color="primary"
                startIcon={<Icon icon={arrowLeft} />}
              >
                Back To Dashboard
              </Button>
            </Box>
          </Collapse>
        </AdminSidebarWrapper>

        <NavSection isDashboard navConfig={navConfig} isShow={!isCollapse} å />

        <Box sx={{ flexGrow: 1 }} />
      </Scrollbar>
    </AdminGenericSidebar>
  );
}

AdminSettingsSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func
};
