import * as Yup from 'yup';
import { styled } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack5';
import { Form, FormikProvider, useFormik } from 'formik';
import { Box, Typography, Stack, Divider, Button, FormHelperText } from '@material-ui/core';
import { defaultTo } from 'lodash';
import { ShapePicker } from '../../components/pickers/ShapePicker';

const EditorConatiner = styled(Box)(({ theme }) => ({
  padding: theme.spacing(5)
}));

export default function BordersShapeEditor({ config, heading, closeEdit, handleUpdateComponent }) {
  const { enqueueSnackbar } = useSnackbar();

  const GeneralShapesSchema = Yup.object().shape({
    defaultShapeType: Yup.string().nullable().required('Default border shape is required'),
    buttonShapeType: Yup.string().nullable().required('Button border shape is required'),
    textfieldShapeType: Yup.string().nullable().required('Text field border shape is required'),
    tagShapeType: Yup.string().nullable().required('Tag border shape is required')
  });

  const form = useFormik({
    initialValues: {
      defaultShapeType: defaultTo(config.defaultShapeType, ''),
      buttonShapeType: defaultTo(config.buttonShapeType, ''),
      textfieldShapeType: defaultTo(config.textfieldShapeType, ''),
      tagShapeType: defaultTo(config.tagShapeType, ''),
      colourIndicatorShapeType: defaultTo(config.colourIndicatorShapeType, '')
    },
    validationSchema: GeneralShapesSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        handleUpdateComponent(values);
        enqueueSnackbar('Saved', { variant: 'success' });
        setSubmitting(false);
      } catch (error) {
        console.error(error);
        setSubmitting(false);
      }
    }
  });

  const { initialValues, values, touched, errors, setFieldValue, resetForm, handleSubmit } = form;

  return (
    <FormikProvider value={form}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <EditorConatiner>
          <Stack spacing={3}>
            <Stack spacing={2} justifyContent="flex-end" direction="row">
              <Button variant="outlined" color="primary" onClick={resetForm} disabled={initialValues === values}>
                Reset
              </Button>
              <Box sx={{ flexGrow: 1 }} />
              <Button variant="outlined" color="primary" onClick={closeEdit}>
                Cancel
              </Button>
              <Button variant="contained" color="primary" type="submit">
                Save
              </Button>
            </Stack>
            <Typography variant="h5">{heading}</Typography>

            <Divider />

            <Stack spacing={2}>
              <Typography variant="body1">
                <strong>Default</strong>
              </Typography>
              <div>
                <ShapePicker
                  value={values.defaultShapeType}
                  onChangeShape={(value) => setFieldValue('defaultShapeType', value)}
                />
              </div>
              {touched.defaultShapeType && errors.defaultShapeType && (
                <FormHelperText error>{touched.defaultShapeType && errors.defaultShapeType}</FormHelperText>
              )}
              <FormHelperText>
                This will change the radius of your component borders except for all other configured settings below.
              </FormHelperText>
            </Stack>

            <Stack spacing={2}>
              <Typography variant="body1">
                <strong>Text Fields</strong>
              </Typography>
              <div>
                <ShapePicker
                  value={values.textfieldShapeType}
                  onChangeShape={(value) => setFieldValue('textfieldShapeType', value)}
                />
              </div>
              {touched.textfieldShapeType && errors.textfieldShapeType && (
                <FormHelperText error>{touched.textfieldShapeType && errors.textfieldShapeType}</FormHelperText>
              )}
            </Stack>

            <Stack spacing={2}>
              <Typography variant="body1">
                <strong>Buttons</strong>
              </Typography>
              <div>
                <ShapePicker
                  extended
                  value={values.buttonShapeType}
                  onChangeShape={(value) => setFieldValue('buttonShapeType', value)}
                />
              </div>
              {touched.buttonShapeType && errors.buttonShapeType && (
                <FormHelperText error>{touched.buttonShapeType && errors.buttonShapeType}</FormHelperText>
              )}
            </Stack>

            <Stack spacing={2}>
              <Typography variant="body1">
                <strong>Product Tags</strong>
              </Typography>
              <div>
                <ShapePicker
                  extended
                  value={values.tagShapeType}
                  onChangeShape={(value) => setFieldValue('tagShapeType', value)}
                />
              </div>
              {touched.tagShapeType && errors.tagShapeType && (
                <FormHelperText error>{touched.tagShapeType && errors.tagShapeType}</FormHelperText>
              )}
              <FormHelperText>
                This will change border radius for the floating tag above a product image. e.g. Sale, New
              </FormHelperText>
            </Stack>

            <Stack spacing={2}>
              <Typography variant="body1">
                <strong>Product Colour Indicators</strong>
              </Typography>
              <div>
                <ShapePicker
                  value={values.colourIndicatorShapeType}
                  onChangeShape={(value) => setFieldValue('colourIndicatorShapeType', value)}
                />
              </div>
              {touched.colourIndicatorShapeType && errors.colourIndicatorShapeType && (
                <FormHelperText error>
                  {touched.colourIndicatorShapeType && errors.colourIndicatorShapeType}
                </FormHelperText>
              )}
              <FormHelperText>
                These are the tags that appear below each product to show the available colours. This will need to be
                configured with product variants to be displayed on your store.
              </FormHelperText>
            </Stack>
          </Stack>
        </EditorConatiner>
      </Form>
    </FormikProvider>
  );
}
