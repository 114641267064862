import PropTypes from 'prop-types';
import useRole from 'src/hooks/useRole';

// ----------------------------------------------------------------------

export default function RoleBasedComponent({ accessibleRoles = ['admin'], children }) {
  const isAccessible = useRole(accessibleRoles);

  if (!isAccessible) {
    return null;
  }

  return <>{children}</>;
}

RoleBasedComponent.propTypes = {
  accessibleRoles: PropTypes.array, // Example ['admin', 'user']
  children: PropTypes.node
};
