import React from 'react';
import { isEmpty } from 'lodash';
import { Box, Divider, Stack } from '@material-ui/core';
import { alpha, styled } from '@material-ui/core/styles';
import { isFirstStep, isLastStep, STEPS } from '../../utils/checkoutUtils';
import { CheckoutPanel } from './CheckoutPanel';
import { CartPreviewPanel } from './CartPreviewPanel';
import { Logo } from '../global/Logo';
import useMobile from '../../hooks/useMobile';
import {
  DESKTOP_MAX_CHECKOUT_COMPONENT_WIDTH,
  MOBILE_CONTAINER_LEFT_RIGHT_PADDING,
  MOBILE_CONTAINER_TOP_BOTTOM_PADDING,
  CHECKOUT_PAYMENT_FAILURE_STATUS,
  CHECKOUT_POST_PAYMENT_FAILURE_STATUS,
  CHECKOUT_UNKNOWN_FAILURE_STATUS
} from '../../utils/constants';
import { CheckoutCompleteSummary } from './CheckoutCompleteSummary';

const isErrorStatus = (status) =>
  [CHECKOUT_PAYMENT_FAILURE_STATUS, CHECKOUT_POST_PAYMENT_FAILURE_STATUS, CHECKOUT_UNKNOWN_FAILURE_STATUS].includes(
    status
  );

const CHECKOUT_PANEL_MIN_WIDTH = '500px';

const ViewContainer = styled(Box)(() => ({
  height: '100%'
}));

const VerticalDivider = styled(Divider)(({ theme }) => ({
  width: '1px',
  backgroundColor: alpha(theme.palette.divider, 0.24)
}));

const CheckoutStack = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(10, 7.5),
  width: '100%',
  height: '100%',
  maxWidth: DESKTOP_MAX_CHECKOUT_COMPONENT_WIDTH
}));

export const CheckoutView = ({ form, ...other }) => {
  const isMobile = useMobile();

  return isMobile ? <MobileView form={form} {...other} /> : <DesktopView form={form} {...other} />;
};

const DesktopView = ({
  config,
  logo,
  form,
  products,
  activeStep,
  shippingCountries,
  shippingOptions,
  paymentMethods,
  checkoutStage,
  handleSubmit,
  handleBack,
  handleNavigationClick,
  handleChangePaymentOption,
  ...other
}) => {
  const { showDivider } = config?.checkout?.general;
  const { switchDesktopOrientation, showVerticalDivider } = config?.checkout?.preview;
  const desktopLogo = logo?.desktopLogo;
  const { completed } = checkoutStage;
  return (
    <ViewContainer>
      <Stack
        spacing={0}
        direction={switchDesktopOrientation ? 'row-reverse' : 'row'}
        {...(showVerticalDivider && { divider: <VerticalDivider flexItem /> })}
        sx={{ height: '100%', width: '100%' }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
          <CheckoutStack
            spacing={8}
            direction="column"
            {...(showDivider && { divider: <Divider sx={{ width: '100%' }} /> })}
          >
            <Box>
              <Logo logo={desktopLogo} handleNavigationClick={handleNavigationClick} containerSx={{ flexGrow: 0 }} />
            </Box>
            {!completed ? (
              <CheckoutPanel
                form={form}
                config={config}
                isCartEmpty={isEmpty(products)}
                steps={STEPS}
                shippingCountries={shippingCountries}
                shippingOptions={shippingOptions}
                paymentMethods={paymentMethods}
                activeStep={activeStep}
                isFirstStep={isFirstStep(activeStep)}
                isLastStep={isLastStep(activeStep)}
                onNextStep={handleSubmit}
                onBack={handleBack}
                handleNavigationClick={handleNavigationClick}
                handleChangePaymentOption={handleChangePaymentOption}
                {...other}
              />
            ) : (
              <CheckoutCompleteSummary
                checkoutStage={checkoutStage}
                config={config}
                handleNavigationClick={handleNavigationClick}
              />
            )}
          </CheckoutStack>
        </Box>
        <CartPreviewPanel
          checkoutStage={checkoutStage}
          form={form}
          config={config}
          shippingOptions={shippingOptions}
          products={products}
          sx={{ width: CHECKOUT_PANEL_MIN_WIDTH }}
          {...other}
        />
      </Stack>
    </ViewContainer>
  );
};

const MobileView = ({
  config,
  logo,
  form,
  products,
  activeStep,
  shippingCountries,
  shippingOptions,
  paymentMethods,
  checkoutStage,
  handleSubmit,
  handleBack,
  handleNavigationClick,
  handleChangePaymentOption,
  ...other
}) => {
  const showDivider = config?.layout?.body?.showDivider;
  const desktopLogo = logo?.desktopLogo;
  const mobileLogo = logo?.mobileLogo;
  const { completed, status } = checkoutStage;
  return (
    <Stack
      direction="column"
      spacing={0}
      {...(showDivider && { divider: <Divider sx={{ width: '100%', height: '100%' }} /> })}
    >
      <Box sx={{ display: 'flex', justifyContent: 'center', padding: (theme) => theme.spacing(3.75, 5) }}>
        <Logo
          logo={desktopLogo}
          mobileLogo={mobileLogo}
          handleNavigationClick={handleNavigationClick}
          containerSx={{ flexGrow: 0 }}
        />
      </Box>
      {!isErrorStatus(status) && (
        <CartPreviewPanel
          checkoutStage={checkoutStage}
          form={form}
          config={config}
          shippingOptions={shippingOptions}
          products={products}
          {...other}
        />
      )}
      <CheckoutStack
        spacing={4}
        direction="column"
        sx={{
          alignSelf: 'center',
          padding: (theme) => theme.spacing(MOBILE_CONTAINER_TOP_BOTTOM_PADDING, MOBILE_CONTAINER_LEFT_RIGHT_PADDING)
        }}
      >
        {!completed ? (
          <CheckoutPanel
            form={form}
            config={config}
            isCartEmpty={isEmpty(products)}
            steps={STEPS}
            shippingCountries={shippingCountries}
            shippingOptions={shippingOptions}
            paymentMethods={paymentMethods}
            activeStep={activeStep}
            isFirstStep={isFirstStep(activeStep)}
            isLastStep={isLastStep(activeStep)}
            onNextStep={handleSubmit}
            onBack={handleBack}
            handleNavigationClick={handleNavigationClick}
            handleChangePaymentOption={handleChangePaymentOption}
            {...other}
          />
        ) : (
          <CheckoutCompleteSummary
            checkoutStage={checkoutStage}
            config={config}
            handleNavigationClick={handleNavigationClick}
          />
        )}
      </CheckoutStack>
    </Stack>
  );
};
