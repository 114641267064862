import React from 'react';
import { Icon } from '@iconify/react';
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, Stack, Typography } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import {
  DEFAULT_BOOKING_CANCELLLATION_POLICY_MESSAGE,
  DEFAULT_BOOKING_CANCELLLATION_POLICY_TITLE,
  DEFAULT_BOOKING_NOTE_TITLE
} from '../../../utils/constants';
import MTextField from '../../core/@material-extend/MTextField';
import { PhoneNumberInput } from '../../core/PhoneNumberInput';
import Markdown from '../../core/Markdown';

const AccordionStyle = styled(Accordion)(({ theme }) => ({
  '&.MuiAccordion-root': {
    boxShadow: 'none',
    backgroundColor: 'transparent',
    padding: theme.spacing(0),
    width: '100%',
    borderRadius: 0,
    border: 'none',
    '&::before': {
      top: 1
    }
  }
}));

const AccordionSummaryStyle = styled(AccordionSummary)(({ theme }) => ({
  padding: theme.spacing(0)
}));

const AccordionDetailsStyle = styled(AccordionDetails)(({ theme }) => ({
  padding: theme.spacing(1, 0)
}));

const DropdownActionView = ({ defaultExpanded, title, children }) => (
  <Box>
    <AccordionStyle disableGutters elevation={0} defaultExpanded={defaultExpanded}>
      <AccordionSummaryStyle expandIcon={<Icon icon={arrowIosDownwardFill} width={20} height={20} color="primary" />}>
        <Typography variant="h6">{title}</Typography>
      </AccordionSummaryStyle>
      <AccordionDetailsStyle>{children}</AccordionDetailsStyle>
    </AccordionStyle>
  </Box>
);
export const BookingInformationPage = ({ config, isMobile, shape, form }) => {
  const enableBookingNote = config?.general?.enableBookingNote;
  const bookingNoteTitle = config?.general?.bookingNoteTitle;
  const showCancelPolicy = config?.general?.showCancelPolicy;
  const cancelPolicyTitle = config?.general?.cancelPolicyTitle;
  const cancelPolicyMessage = config?.general?.cancelPolicyMessage;

  const { getFieldProps, touched, errors } = form;

  return (
    <Stack spacing={2} divider={<Divider />}>
      <Stack spacing={3} divider={<Divider />} sx={{ mb: 2 }}>
        <PhoneNumberInput
          form={form}
          fullWidth
          shape={shape}
          key="number"
          label="Number"
          {...getFieldProps('number')}
          error={Boolean(touched.number && errors.number)}
          helperText={touched.number && errors.number}
        />
        <Stack spacing={2}>
          <Stack spacing={1} direction={isMobile ? 'column' : 'row'}>
            <MTextField
              fullWidth
              shape={shape}
              key="firstname"
              label="First Name"
              {...getFieldProps('firstName')}
              error={Boolean(touched.firstName && errors.firstName)}
              helperText={touched.firstName && errors.firstName}
            />
            <MTextField
              fullWidth
              shape={shape}
              key="lastname"
              label="Last Name"
              {...getFieldProps('lastName')}
              error={Boolean(touched.lastName && errors.lastName)}
              helperText={touched.lastName && errors.lastName}
            />
          </Stack>
          <MTextField
            fullWidth
            shape={shape}
            key="email"
            label="Email"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />
        </Stack>
      </Stack>

      {enableBookingNote && (
        <DropdownActionView title={bookingNoteTitle || DEFAULT_BOOKING_NOTE_TITLE} defaultExpanded={false}>
          <MTextField fullWidth multiline minRows={3} maxRows={15} autoComplete="off" {...getFieldProps('notes')} />
        </DropdownActionView>
      )}

      {showCancelPolicy && (
        <DropdownActionView title={cancelPolicyTitle || DEFAULT_BOOKING_CANCELLLATION_POLICY_TITLE} defaultExpanded>
          <Markdown children={cancelPolicyMessage || DEFAULT_BOOKING_CANCELLLATION_POLICY_MESSAGE} />
        </DropdownActionView>
      )}
    </Stack>
  );
};
