import { defaultTo, max, slice } from 'lodash';

export const getNavigations = (enabled, data, size) => {
  if (!enabled) {
    return data;
  }
  const links = defaultTo(data, []);
  const maxSize = defaultTo(size, links.length);
  const endSize = max([maxSize, 0]);
  return slice(links, 0, endSize);
};
