import EmptyContent from 'src/components/EmptyContent';
import GenericSelectDialog from './GenericSelectDialog';

export default function SelectNavigationDialog(props) {
  return (
    <GenericSelectDialog
      title="Choose Navigation"
      label="Navigation"
      requiredOptionMessage="Please choose an option"
      fallbackComponent={
        <EmptyContent
          title="No navigations found"
          description="Looks like you do not have any navigations at this time."
          img="/static/illustrations/illustration_empty_navigation.svg"
        />
      }
      {...props}
    />
  );
}
