import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
// slices
import productReducer from './slices/product';
import bookingReducer from './slices/bookings';
import customerReducer from './slices/customer';
import discountReducer from './slices/discount';
import tenantReducer from './slices/tenant';
import tenantUserReducer from './slices/tenantUser';
import shippingReducer from './slices/shipping';
import paymentReducer from './slices/payment';
import messagingReducer from './slices/messaging';
import messagesReducer from './slices/messages';
import planReducer from './slices/plan';
import billingReducer from './slices/billing';
import reportReducer from './slices/report';
import orderReducer from './slices/order';
import fileReducer from './slices/file';
import fulfilmentProviderReducer from './slices/fulfilmentProvider';
import notificationReducer from './slices/notification';
import storeReducer from './slices/store';
import websiteReducer from './slices/website';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: []
};

const appReducer = combineReducers({
  product: productReducer,
  booking: bookingReducer,
  customer: customerReducer,
  discount: discountReducer,
  order: orderReducer,
  tenant: tenantReducer,
  tenantUser: tenantUserReducer,
  shipping: shippingReducer,
  payment: paymentReducer,
  messaging: messagingReducer,
  messages: messagesReducer,
  plan: planReducer,
  billing: billingReducer,
  report: reportReducer,
  notification: notificationReducer,
  file: fileReducer,
  fulfilmentProvider: fulfilmentProviderReducer,
  store: storeReducer,
  website: websiteReducer
});

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT') {
    // RESETING ALL STATES
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export { rootPersistConfig, rootReducer };
