import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import flashFill from '@iconify/icons-eva/flash-fill';
import { useState, useEffect } from 'react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
// material
import { alpha, styled } from '@material-ui/core/styles';
import {
  Box,
  List,
  Drawer,
  Link,
  Collapse,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  Typography,
  Grid,
  Stack,
  Button,
  Divider
} from '@material-ui/core';
import { PATH_AUTH, PATH_DASHBOARD, PATH_PAGE } from 'src/routes/paths';
import Logo from '../../components/Logo';
import Scrollbar from '../../components/Scrollbar';
import { MIconButton } from '../../components/@material-extend';

// ----------------------------------------------------------------------

const ITEM_SIZE = 48;
const PADDING = 2.5;

const ListItemStyle = styled(ListItemButton)(({ theme }) => ({
  ...theme.typography.body2,
  height: ITEM_SIZE,
  textTransform: 'capitalize',
  paddingLeft: theme.spacing(PADDING),
  paddingRight: theme.spacing(2.5),
  color: theme.palette.text.secondary
}));

// ----------------------------------------------------------------------

const ButtonStyle = styled(ListItemButton)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius
}));

const LinkItemSelectedDot = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignSelf: 'center',
  height: 5,
  width: 5,
  backgroundColor: theme.palette.primary.main,
  borderRadius: theme.shape.borderRadiusCircle,
  marginRight: theme.spacing(0.5)
}));

function MenuMobileItem({ item, isOpen, isActive, pathname, onOpen, onClose }) {
  const { title, path, icon, children } = item;

  if (children) {
    return (
      <div key={title}>
        <ListItemStyle
          onClick={() => {
            if (isOpen) {
              onClose();
            } else {
              onOpen(title);
            }
          }}
        >
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText disableTypography primary={title} />
          <Box
            component={Icon}
            icon={isOpen ? arrowIosDownwardFill : arrowIosForwardFill}
            sx={{ width: 16, height: 16, ml: 1 }}
          />
        </ListItemStyle>

        <Collapse in={isOpen} timeout="auto" unmountOnExit>
          <Grid container spacing={0}>
            {children.map((list) => {
              const { title, subheader, items } = list;

              return (
                <Grid key={title} item xs={12}>
                  <Box sx={{ backgroundColor: 'grey.100' }}>
                    <Stack spacing={1} sx={{ p: (theme) => theme.spacing(3) }}>
                      <Typography variant="overline" color="primary">
                        {subheader}
                      </Typography>

                      <Box>
                        <Grid container spacing={0} direction="column">
                          {items.map((item) => (
                            <Grid key={item.title} item xs={12}>
                              <ButtonStyle component={RouterLink} to={item.path} variant="text">
                                <Stack direction="row" spacing={1} sx={{ width: '100%' }}>
                                  <Typography variant="body2" color="text.secondary">
                                    {item.title}
                                  </Typography>
                                  <Box flexGrow={1} />
                                  <LinkItemSelectedDot
                                    sx={{
                                      ...(item.path !== pathname && { display: 'none' })
                                    }}
                                  />
                                </Stack>
                              </ButtonStyle>
                            </Grid>
                          ))}
                        </Grid>
                      </Box>
                    </Stack>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </Collapse>
      </div>
    );
  }

  return (
    <ListItemStyle
      to={path}
      component={RouterLink}
      sx={{
        ...(isActive && {
          color: 'primary.main',
          fontWeight: 'fontWeightMedium',
          bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity)
        })
      }}
    >
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText disableTypography primary={title} />
    </ListItemStyle>
  );
}

export default function MenuMobile({ authenticated, isOffset, isFullscreen, isDark, navConfig }) {
  const { pathname } = useLocation();
  const [open, setOpen] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);
  const defaultThemeLogo = {
    variant: 'white',
    contrast: 'primary',
    background: 'primary'
  };

  const handleDrawerOpen = () => {
    setMobileOpen(true);
  };

  const handleDrawerClose = () => {
    setMobileOpen(false);
  };

  const handleOpen = (title) => {
    setOpen(title);
  };

  const handleClose = () => {
    setOpen(null);
  };

  useEffect(() => {
    if (mobileOpen) {
      handleDrawerClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <>
      <MIconButton
        onClick={handleDrawerOpen}
        sx={{
          ml: 1,
          ...(isFullscreen && { color: 'common.white' }),
          ...(isOffset && { color: 'text.primary' }),
          ...(isDark && { color: 'common.white' })
        }}
      >
        <Icon icon={menu2Fill} />
      </MIconButton>

      <Drawer
        open={mobileOpen}
        onClose={handleDrawerClose}
        ModalProps={{ keepMounted: true }}
        PaperProps={{ sx: { backgroundColor: 'grey.100', pb: 5, width: 260 } }}
      >
        <Scrollbar>
          <Stack spacing={2}>
            <Link component={RouterLink} to={PATH_PAGE.home} sx={{ display: 'inline-flex', alignSelf: 'center' }}>
              <Logo isLogo logo={defaultThemeLogo} text={{ variant: 'primary' }} sx={{ mx: PADDING, mt: 3, mb: 2 }} />
            </Link>

            <Stack direction="column" spacing={2} sx={{ px: 2 }}>
              {authenticated ? (
                <Button fullWidth LinkComponent={RouterLink} variant="contained" to={PATH_DASHBOARD.root}>
                  Dashboard
                </Button>
              ) : (
                <>
                  <Button
                    fullWidth
                    LinkComponent={RouterLink}
                    variant="contained"
                    to={PATH_AUTH.signup}
                    startIcon={<Icon icon={flashFill} width={20} height={20} />}
                  >
                    Start free trial
                  </Button>
                  <Button fullWidth LinkComponent={RouterLink} variant="text" to={PATH_AUTH.login}>
                    Login
                  </Button>
                </>
              )}
            </Stack>

            <Divider />

            <List disablePadding>
              {navConfig.map((link) => {
                return (
                  <MenuMobileItem
                    key={link.title}
                    item={link}
                    isOpen={open === link.title}
                    onOpen={handleOpen}
                    onClose={handleClose}
                    isActive={pathname === link.path}
                    pathname={pathname}
                  />
                );
              })}
            </List>
          </Stack>
        </Scrollbar>
      </Drawer>
    </>
  );
}

MenuMobileItem.propTypes = {
  item: PropTypes.object,
  isOpen: PropTypes.bool,
  isActive: PropTypes.bool,
  onOpen: PropTypes.func
};

MenuMobile.propTypes = {
  isOffset: PropTypes.bool,
  isFullscreen: PropTypes.bool
};
