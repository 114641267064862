import { Button, Dialog, DialogTitle, DialogActions, Stack, Typography } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';

// ----------------------------------------------------------------------

export default function GenericAlertDialog({
  open,
  title,
  description,
  okayText,
  loading = false,
  showOkayButton = true,
  handleClose,
  handleOkay,
  children
}) {
  return (
    <Dialog fullWidth open={open} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <Stack spacing={3} sx={{ p: (theme) => theme.spacing(3) }}>
        {children}
        <Typography variant="body2" color="text.secondary">
          {description}
        </Typography>
      </Stack>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        {showOkayButton && (
          <LoadingButton loading={loading} variant="contained" onClick={handleOkay} autoFocus>
            {okayText || 'Okay'}
          </LoadingButton>
        )}
      </DialogActions>
    </Dialog>
  );
}
