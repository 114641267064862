import React, { useCallback, useMemo, useState } from 'react';
import { Icon } from '@iconify/react';
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
import { Box, Button, InputAdornment, Menu, MenuItem, Stack, Typography } from '@material-ui/core';
import { defaultTo, lowerCase } from 'lodash';
import { phoneCodes } from '../../utils/constants/phoneCodes';
import MTextField from './@material-extend/MTextField';

export const PhoneNumberInput = ({ form: { getFieldProps, setFieldValue }, ...others }) => {
  const selected = getFieldProps('numberCode')?.value;
  const [open, setOpen] = useState(null);

  const handleOpen = useCallback((event) => setOpen(event.currentTarget), [setOpen]);

  const handleClose = useCallback(() => setOpen(false), [setOpen]);

  const handleChange = useCallback(
    (option) => {
      setFieldValue('numberCode', option);
      setOpen(null);
    },
    [setFieldValue, setOpen]
  );

  const CountryCodes = useMemo(() => {
    return (
      <Menu keepMounted anchorEl={open} open={Boolean(open)} onClose={handleClose}>
        {defaultTo(phoneCodes, []).map((option) => (
          <MenuItem key={option.code} value={option.code} onClick={() => handleChange(option)}>
            <Stack direction="row" spacing={2}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Icon icon={`flag:${lowerCase(option.code)}-4x3`} />
              </Box>
              <Box>{`${option.name} ${option.dial_code}`}</Box>
            </Stack>
          </MenuItem>
        ))}
      </Menu>
    );
  }, [open, handleChange, handleClose]);

  return (
    <MTextField
      {...others}
      InputProps={{
        style: {
          height: '64px'
        },
        startAdornment: (
          <InputAdornment position="start">
            <Button onClick={handleOpen}>
              <Stack direction="row" spacing={2} alignItems="center">
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Icon fontSize={20} icon={`flag:${lowerCase(selected?.code)}-4x3`} />
                </Box>
                <Typography variant="body1">{selected?.dial_code}</Typography>
                <Icon icon={arrowIosDownwardFill} />
              </Stack>
            </Button>
            {CountryCodes}
          </InputAdornment>
        )
      }}
    />
  );
};
