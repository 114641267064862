import { Typography, Stack, Divider } from '@material-ui/core';
import { GENERIC_CONTENT_POSITIONS } from '../../../utils/constantsEditor';
import MultiOptionPicker from '../../../components/pickers/MultiOptionPicker';
import { NavigationViewPicker } from '../../../components/pickers/NavigationViewPicker';
import { EmptyConfigurationsView } from '../../../common/EmptyConfigurationsView';

export default function HeaderOrientationEditorCommon({ form, showLogo, enableNavigation }) {
  const { values, setFieldValue } = form;

  return (
    <>
      {!showLogo && !enableNavigation && (
        <>
          <Divider />
          <EmptyConfigurationsView />
        </>
      )}
      <>
        {showLogo && (
          <Stack spacing={3}>
            <Divider />
            <Typography variant="body1">
              <strong>Logo Position</strong>
            </Typography>

            <Stack spacing={2}>
              <Typography variant="overline" color="text.secondary">
                <strong>Desktop</strong>
              </Typography>
              <MultiOptionPicker
                data={GENERIC_CONTENT_POSITIONS}
                value={values.logoPosition}
                onChange={(_, newValue) => setFieldValue('logoPosition', newValue)}
              />
            </Stack>

            <Stack spacing={2}>
              <Typography variant="overline" color="text.secondary">
                <strong>Mobile</strong>
              </Typography>
              <MultiOptionPicker
                data={GENERIC_CONTENT_POSITIONS}
                value={values.logoPositionMobile}
                onChange={(_, newValue) => setFieldValue('logoPositionMobile', newValue)}
              />
            </Stack>
          </Stack>
        )}
      </>

      {enableNavigation && (
        <>
          <Stack spacing={3}>
            <Divider />

            <Typography variant="body1">
              <strong>Navigation View</strong>
            </Typography>

            <Stack spacing={2}>
              <Typography variant="overline" color="text.secondary">
                <strong>Desktop</strong>
              </Typography>
              <NavigationViewPicker
                value={values.viewType}
                onChange={(newValue) => setFieldValue('viewType', newValue)}
              />
            </Stack>

            <Divider />

            <Stack spacing={2} sx={{ mb: 2, pb: 2 }}>
              <Typography variant="body1">
                <strong>Navigation Position</strong>
              </Typography>

              <Stack spacing={2}>
                <Typography variant="overline" color="text.secondary">
                  <strong>Desktop</strong>
                </Typography>
                <MultiOptionPicker
                  data={GENERIC_CONTENT_POSITIONS}
                  value={values.navigationPosition}
                  onChange={(_, newValue) => setFieldValue('navigationPosition', newValue)}
                />
              </Stack>
            </Stack>
          </Stack>
        </>
      )}
    </>
  );
}
