import PropTypes from 'prop-types';
import { defaultTo, isNull } from 'lodash';
import { paramCase } from 'change-case';
import { isAfter } from 'date-fns';
import { useSelector } from 'src/redux/store';
import PlansGetStarted from 'src/pages/admin_v2/PlansGetStarted';

// ----------------------------------------------------------------------

export default function SubscriptionGuard({ children }) {
  const { tenant } = useSelector((state) => state.tenant);
  const plan = tenant?.plan;
  const trialEndDate = plan?.trialEndsAt && new Date(plan?.trialEndsAt);
  const isTrialEndDatePast = trialEndDate && isAfter(new Date(), trialEndDate);

  const isTrialOnlyAccount = Boolean(
    // In trial period
    paramCase(defaultTo(plan?.type, '')) === 'trial' &&
      // Does not have a future plan
      isNull(plan?.startsAt) &&
      // Trial date is gone
      isTrialEndDatePast
  );

  if (isTrialOnlyAccount) {
    return <PlansGetStarted />;
  }

  return <>{children}</>;
}

SubscriptionGuard.propTypes = {
  children: PropTypes.node
};
