import * as Yup from 'yup';
import { useSnackbar } from 'notistack5';
import { Typography, Stack, Button } from '@material-ui/core';
import { Form, FormikProvider, useFormik } from 'formik';
import { defaultTo } from 'lodash';
import { REQUIRED_OPTION_MESSAGE } from '../../../utils/constants';
import FooterStyleEditorCommon from './FooterStyleEditorCommon';
import { BORDER_SIZE_MAX, BORDER_SIZE_MIN, BORDER_RADIUS_MIN, BORDER_RADIUS_MAX } from '../common/footerConstants';
import { EditorConatiner } from '../../../common/EditorConatiner';

export default function FooterStyleEditor({ heading, config, closeEdit, handleUpdateComponent }) {
  const { enqueueSnackbar } = useSnackbar();
  const backgroundColour = config?.backgroundColour;
  const primaryColour = config?.primaryColour;
  const textColour = config?.textColour;
  const showBorder = config?.showBorder;
  const showBorderRadius = config?.showBorderRadius;
  const borderColour = config?.borderColour;
  const borderSize = config?.borderSize;
  const borderRadius = config?.borderRadius;

  const FooterStyleSchema = Yup.object().shape({
    showBorder: Yup.boolean().required(REQUIRED_OPTION_MESSAGE),
    showBorderRadius: Yup.boolean().required(REQUIRED_OPTION_MESSAGE),
    borderSize: Yup.number()
      .optional()
      .nullable()
      .when('showBorder', {
        is: true,
        then: Yup.number()
          .required('Border size is required')
          .min(BORDER_SIZE_MIN, `Minimum border size value is ${BORDER_SIZE_MIN}`)
          .max(BORDER_SIZE_MAX, `Maximum border size value is ${BORDER_SIZE_MAX}`)
      }),
    borderRadius: Yup.number()
      .optional()
      .nullable()
      .when('showBorderRadius', {
        is: true,
        then: Yup.number()
          .required('Curve value is required')
          .min(BORDER_RADIUS_MIN, `Minimum curve value is ${BORDER_RADIUS_MIN}`)
          .max(BORDER_RADIUS_MAX, `Maximum curve value is ${BORDER_RADIUS_MAX}`)
      })
  });

  const form = useFormik({
    initialValues: {
      backgroundColour: defaultTo(backgroundColour, ''),
      primaryColour: defaultTo(primaryColour, ''),
      textColour: defaultTo(textColour, ''),
      showBorder: defaultTo(showBorder, false),
      showBorderRadius: defaultTo(showBorderRadius, false),
      borderColour: defaultTo(borderColour, ''),
      borderSize: defaultTo(borderSize, 3),
      borderRadius: defaultTo(borderRadius, 20)
    },
    validationSchema: FooterStyleSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        handleUpdateComponent({
          ...config,
          ...values
        });
        enqueueSnackbar('Saved', { variant: 'success' });
        setSubmitting(false);
      } catch (error) {
        console.error(error);
        setSubmitting(false);
      }
    }
  });

  const { handleSubmit } = form;

  return (
    <FormikProvider value={form}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <EditorConatiner>
          <Stack spacing={3}>
            <Stack spacing={2} justifyContent="flex-end" direction="row">
              <Button variant="outlined" color="primary" onClick={closeEdit}>
                Cancel
              </Button>
              <Button variant="contained" color="primary" type="submit">
                Save
              </Button>
            </Stack>
            <Typography variant="h5">{heading}</Typography>

            <FooterStyleEditorCommon form={form} />
          </Stack>
        </EditorConatiner>
      </Form>
    </FormikProvider>
  );
}
