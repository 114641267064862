import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

export function fDateISO(date) {
  return format(new Date(date), 'yyyy-MM-dd');
}

export function fDateLong(date) {
  return format(new Date(date), 'iiii, d MMM yyyy');
}

export function fTime(date) {
  return format(new Date(date), 'HH:mm');
}

export function fNowUtcToTz(timezone) {
  return utcToZonedTime(new Date(), timezone);
}
