import * as Yup from 'yup';
import { styled } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack5';
import { Form, FormikProvider, useFormik } from 'formik';
import { Box, Typography, Stack, Divider, Button, FormHelperText, FormControlLabel, Switch } from '@material-ui/core';
import { defaultTo } from 'lodash';

const EditorConatiner = styled(Box)(({ theme }) => ({
  padding: theme.spacing(5)
}));

export default function ShadowColoursEditor({ config, heading, closeEdit, handleUpdateComponent }) {
  const { enqueueSnackbar } = useSnackbar();

  const ShadowColoursSchema = Yup.object().shape({
    buttonShadow: Yup.boolean().nullable().required('Button shadow option is required')
  });

  const form = useFormik({
    initialValues: {
      buttonShadow: defaultTo(config?.buttonShadow, false)
    },
    validationSchema: ShadowColoursSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        handleUpdateComponent(values);
        enqueueSnackbar('Saved', { variant: 'success' });
        setSubmitting(false);
      } catch (error) {
        console.error(error);
        setSubmitting(false);
      }
    }
  });

  const { initialValues, values, getFieldProps, touched, errors, resetForm, handleSubmit } = form;

  return (
    <FormikProvider value={form}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <EditorConatiner>
          <Stack spacing={3}>
            <Stack spacing={2} justifyContent="flex-end" direction="row">
              <Button variant="outlined" color="primary" onClick={resetForm} disabled={initialValues === values}>
                Reset
              </Button>
              <Box sx={{ flexGrow: 1 }} />
              <Button variant="outlined" color="primary" onClick={closeEdit}>
                Cancel
              </Button>
              <Button variant="contained" color="primary" type="submit">
                Save
              </Button>
            </Stack>
            <Typography variant="h5">{heading}</Typography>

            <Divider />

            <Stack spacing={2}>
              <Typography variant="body1">
                <strong>Button</strong>
              </Typography>
              <div>
                <FormControlLabel
                  control={<Switch {...getFieldProps('buttonShadow')} checked={values.buttonShadow} />}
                  label="Enable Button Shadow"
                  labelPlacement="end"
                />
                {touched.buttonShadow && errors.buttonShadow && (
                  <FormHelperText error>{touched.buttonShadow && errors.buttonShadow}</FormHelperText>
                )}
                <FormHelperText>This will configure shadows for all buttons across your store</FormHelperText>
              </div>
            </Stack>
          </Stack>
        </EditorConatiner>
      </Form>
    </FormikProvider>
  );
}
