export const SOCIAL_OPTIONS = [
  {
    key: 'FACEBOOK',
    name: 'Facebook'
  },
  {
    key: 'INSTAGRAM',
    name: 'Instagram'
  },
  {
    key: 'SNAPCHAT',
    name: 'Snapchat'
  },
  {
    key: 'TIKTOK',
    name: 'Tiktok'
  },
  {
    key: 'LINKEDIN',
    name: 'LinkedIn'
  },
  {
    key: 'TWITTER',
    name: 'Twitter'
  },
  {
    key: 'YOUTUBE',
    name: 'Youtube'
  },
  {
    key: 'PINTEREST',
    name: 'Pinterest'
  },
  {
    key: 'REDDIT',
    name: 'Reddit'
  }
];
