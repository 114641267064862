import { useSnackbar } from 'notistack5';
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import { Typography, Stack, Button } from '@material-ui/core';
import { defaultTo } from 'lodash';
import {
  REQUIRED_OPTION_MESSAGE,
  DEFAULT_SHOW_NAVIGATION_SELECTION_DIVIDER,
  DEFAULT_SHOW_HEADER_DIVIDER,
  DEFAULT_ENABLE_SEARCH_ACTION
} from '../../../utils/constants';
import HeaderEditorCommon from './HeaderEditorCommon';
import { EditorConatiner } from '../../../common/EditorConatiner';

export default function HeaderEditor({ heading, config, closeEdit, options, handleUpdateComponent }) {
  const { enqueueSnackbar } = useSnackbar();

  const HeaderSchema = Yup.object().shape({
    showLogo: Yup.boolean().required(REQUIRED_OPTION_MESSAGE),
    enableNavigation: Yup.boolean().required(REQUIRED_OPTION_MESSAGE),
    enableMaxNavigationSize: Yup.boolean().required(REQUIRED_OPTION_MESSAGE)
  });

  const showLogo = config?.showLogo;
  const enableNavigation = config?.enableNavigation;
  const navigationLinkId = config?.navigationLinkId;
  const showDivider = config?.showDivider;
  const showHeaderDivider = config?.showHeaderDivider;
  const enableSearch = config?.enableSearch;
  const enableCartButton = config?.enableCartButton;
  const enableMaxNavigationSize = config?.enableMaxNavigationSize;
  const maxNavigationSize = config?.maxNavigationSize;

  const form = useFormik({
    initialValues: {
      showLogo: defaultTo(showLogo, true),
      enableNavigation: defaultTo(enableNavigation, true),
      navigationLinkId: defaultTo(navigationLinkId, null),
      showDivider: defaultTo(showDivider, DEFAULT_SHOW_NAVIGATION_SELECTION_DIVIDER),
      showHeaderDivider: defaultTo(showHeaderDivider, DEFAULT_SHOW_HEADER_DIVIDER),
      enableSearch: defaultTo(enableSearch, DEFAULT_ENABLE_SEARCH_ACTION),
      enableCartButton: defaultTo(enableCartButton, true),
      enableMaxNavigationSize: defaultTo(enableMaxNavigationSize, false),
      maxNavigationSize: defaultTo(maxNavigationSize, 0)
    },
    validationSchema: HeaderSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        handleUpdateComponent(values);
        enqueueSnackbar('Saved', { variant: 'success' });
        setSubmitting(false);
      } catch (error) {
        console.error(error);
        setSubmitting(false);
      }
    }
  });

  const { handleSubmit } = form;

  return (
    <FormikProvider value={form}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <EditorConatiner>
          <Stack spacing={3}>
            <Stack spacing={2} justifyContent="flex-end" direction="row">
              <Button variant="outlined" color="primary" onClick={closeEdit}>
                Cancel
              </Button>
              <Button variant="contained" color="primary" type="submit">
                Save
              </Button>
            </Stack>
            <Typography variant="h5">{heading}</Typography>

            <HeaderEditorCommon form={form} options={options} />
          </Stack>
        </EditorConatiner>
      </Form>
    </FormikProvider>
  );
}
