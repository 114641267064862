import * as Yup from 'yup';
import { styled } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack5';
import { Form, FormikProvider, useFormik } from 'formik';
import { Box, Typography, Stack, Divider, Button, FormHelperText, FormControlLabel, Switch } from '@material-ui/core';
import { defaultTo } from 'lodash';
import { REQUIRED_OPTION_MESSAGE } from '../../utils/constants';

const EditorConatiner = styled(Box)(({ theme }) => ({
  padding: theme.spacing(5)
}));

export default function BodyEditor({ config, heading, closeEdit, handleUpdateComponent }) {
  const { enqueueSnackbar } = useSnackbar();

  const showDivider = config?.showDivider;

  const BodySchema = Yup.object().shape({
    showDivider: Yup.boolean().required(REQUIRED_OPTION_MESSAGE)
  });

  const form = useFormik({
    initialValues: {
      showDivider: defaultTo(showDivider, true)
    },
    validationSchema: BodySchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        handleUpdateComponent(values);
        enqueueSnackbar('Saved', { variant: 'success' });
        setSubmitting(false);
      } catch (error) {
        console.error(error);
        setSubmitting(false);
      }
    }
  });

  const { values, errors, touched, handleSubmit, getFieldProps } = form;

  return (
    <FormikProvider value={form}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <EditorConatiner>
          <Stack spacing={3}>
            <Stack spacing={2} justifyContent="flex-end" direction="row">
              <Button variant="outlined" color="primary" onClick={closeEdit}>
                Cancel
              </Button>
              <Button variant="contained" color="primary" type="submit">
                Save
              </Button>
            </Stack>
            <Typography variant="h5">{heading}</Typography>

            <Divider />

            <Typography variant="body1">
              <strong>Divider</strong>
            </Typography>

            <Stack spacing={2}>
              <FormControlLabel
                label="Show Components Divider"
                control={<Switch checked={values.showDivider} {...getFieldProps('showDivider')} />}
              />
              {touched.showLogo && errors.showLogo && (
                <FormHelperText error>{touched.showLogo && errors.showLogo}</FormHelperText>
              )}
              <FormHelperText>
                If enabled, each component will be seperated with a divider. This will appear between all components
                throughout your store.
              </FormHelperText>
            </Stack>
          </Stack>
        </EditorConatiner>
      </Form>
    </FormikProvider>
  );
}
