import { Link as RouterLink } from 'react-router-dom';
import {
  Typography,
  Stack,
  Divider,
  Link,
  FormHelperText,
  Switch,
  FormControlLabel,
  Collapse,
  Slider,
  Box
} from '@material-ui/core';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { SelectNavigation } from '../../../components/SelectNavigation';
import { SLIDER_HEADER_LINKS_MAX_SIZE, SLIDER_HEADER_LINKS_MIN_SIZE } from '../../../utils/constantsEditor';

export default function HeaderEditorCommon({ form, options }) {
  const { values, touched, errors, setFieldValue, getFieldProps } = form;

  const handleNavigationSelected = (option) => {
    setFieldValue('navigationLinkId', option);
  };

  return (
    <>
      <Divider />

      <Typography variant="body1">
        <strong>Options</strong>
      </Typography>

      <Stack spacing={0}>
        <div>
          <FormControlLabel
            label="Show Logo"
            control={<Switch checked={values.showLogo} {...getFieldProps('showLogo')} />}
          />
          {touched.showLogo && errors.showLogo && (
            <FormHelperText error>{touched.showLogo && errors.showLogo}</FormHelperText>
          )}
        </div>

        <div>
          <FormControlLabel
            label="Show Cart Button"
            control={<Switch checked={values.enableCartButton} {...getFieldProps('enableCartButton')} />}
          />
          {touched.enableCartButton && errors.enableCartButton && (
            <FormHelperText error>{touched.enableCartButton && errors.enableCartButton}</FormHelperText>
          )}
        </div>

        <div>
          <FormControlLabel
            label="Enable Navigation"
            control={<Switch checked={values.enableNavigation} {...getFieldProps('enableNavigation')} />}
          />
          {touched.enableNavigation && errors.enableNavigation && (
            <FormHelperText error>{touched.enableNavigation && errors.enableNavigation}</FormHelperText>
          )}
        </div>

        <Collapse in={values.enableNavigation}>
          <div>
            <FormControlLabel
              label="Limit Navigation Size"
              control={
                <Switch checked={values.enableMaxNavigationSize} {...getFieldProps('enableMaxNavigationSize')} />
              }
            />
            {touched.enableMaxNavigationSize && errors.enableMaxNavigationSize && (
              <FormHelperText error>{touched.enableMaxNavigationSize && errors.enableMaxNavigationSize}</FormHelperText>
            )}
          </div>
          <Stack spacing={3} sx={{ mt: 2 }}>
            <Divider />

            <Stack spacing={2}>
              <Typography variant="body1">
                <strong>Navigation Link</strong>
              </Typography>
              <SelectNavigation values={values} options={options} handleNavigationSelected={handleNavigationSelected} />
              <FormHelperText>
                You can create a navigation link&nbsp;
                <Link to={PATH_DASHBOARD.store.links} color="primary" component={RouterLink}>
                  here
                </Link>
              </FormHelperText>
              {errors.navigationLinkId && <FormHelperText error>{errors.navigationLinkId}</FormHelperText>}
            </Stack>

            <Collapse in={values.enableMaxNavigationSize}>
              <Stack spacing={2}>
                <Typography variant="body1">
                  <strong>Navigation Size</strong>
                </Typography>
                <Box
                  sx={{
                    p: (theme) => theme.spacing(3),
                    border: (theme) => `solid 1px ${theme.palette.divider}`
                  }}
                >
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    Desktop Navigation Size
                  </Typography>
                  <Stack direction="row">
                    <Typography variant="body2">{SLIDER_HEADER_LINKS_MIN_SIZE}</Typography>
                    <Slider
                      defaultValue={SLIDER_HEADER_LINKS_MIN_SIZE}
                      {...getFieldProps('maxNavigationSize')}
                      valueLabelDisplay="auto"
                      step={1}
                      marks
                      min={SLIDER_HEADER_LINKS_MIN_SIZE}
                      max={SLIDER_HEADER_LINKS_MAX_SIZE}
                      sx={{ ml: 2, mr: 2 }}
                    />
                    <Typography variant="body2">{SLIDER_HEADER_LINKS_MAX_SIZE}</Typography>
                  </Stack>
                  <FormHelperText>Limit the number of navigation links displayed on desktop</FormHelperText>
                  {touched.maxNavigationSize && errors.maxNavigationSize && (
                    <FormHelperText error>{touched.maxNavigationSize && errors.maxNavigationSize}</FormHelperText>
                  )}
                </Box>
              </Stack>
            </Collapse>
          </Stack>
        </Collapse>
      </Stack>

      <Divider />

      <Stack spacing={2}>
        <Typography variant="body1">
          <strong>Dividers</strong>
        </Typography>
        <div>
          <FormControlLabel
            control={<Switch {...getFieldProps('showHeaderDivider')} checked={values.showHeaderDivider} />}
            label="Navigation Header"
            labelPlacement="end"
          />
          <FormHelperText>This is the position between your header and the first component on your page</FormHelperText>
        </div>
        <Collapse in={values.enableNavigation}>
          <div>
            <FormControlLabel
              control={<Switch {...getFieldProps('showDivider')} checked={values.showDivider} />}
              label="Navigation Selection"
              labelPlacement="end"
            />
            <FormHelperText>This is the position between each navigation link</FormHelperText>
          </div>
        </Collapse>
      </Stack>
    </>
  );
}
