import React, { useCallback, useEffect, useState } from 'react';
import { ceil, defaultTo, first, isEmpty } from 'lodash';
import { Box, Stack, Pagination, Button } from '@material-ui/core';
import { alpha, styled } from '@material-ui/core/styles';
import { shouldHideLocationsPage } from '../../../utils/bookingUtils';
import { BookingTextBlock } from '../BookingTextBlock';
import EmptyContent from '../../core/EmptyContent';
import { isInvalid } from '../../../utils/nullable';
import {
  DEFAULT_BOOKING_LOCATIONS_EMPTY_TITLE,
  DEFAULT_BOOKING_LOCATIONS_EMPTY_DESCRIPTION,
  DEFAULT_BOOKING_LOCATIONS_EMPTY_BUTTON_TEXT
} from '../../../utils/constants';
import { HOME_PATH, SYSTEM_PAGE } from '../../../utils/pageTypes';
import { Loader } from '../../core/Loader';

const LocationOptionRootStyle = styled(Button)(({ theme }) => ({
  boxShadow: 'none',
  borderRadius: theme.shape.borderRadius,
  border: `solid 1px ${alpha(theme.palette.border, 0.32)}`,
  padding: theme.spacing(2),
  color: 'unset'
}));

const EmptyRootStyle = styled(EmptyContent)(({ theme }) => ({
  boxShadow: 'none',
  borderRadius: theme.shape.borderRadius,
  border: `solid 1px ${alpha(theme.palette.border, 0.32)}`,
  padding: theme.spacing(2)
}));

const LocationOption = ({ location, handleClickOption }) => {
  const { name, description, address, number } = location;
  return (
    <LocationOptionRootStyle onClick={() => handleClickOption(location)}>
      <BookingTextBlock title={name} description={description} supportText={[address, number]} />
    </LocationOptionRootStyle>
  );
};

const INITIAL_STATE = {
  loading: false,
  data: null
};

const DEFAULT_PAGE_SIZE = 15;

const BookingLocationsList = ({ options, handleUpdateLocation }) => (
  <Stack spacing={2}>
    {defaultTo(options?.data, []).map((option) => (
      <LocationOption key={option.id} location={option} handleClickOption={handleUpdateLocation} />
    ))}
  </Stack>
);

export const BookingLocationsPage = ({
  messages,
  handleUpdateLocation,
  handleGetBookingLocations,
  handleNavigationClick
}) => {
  const message = messages?.locations;
  const [state, setState] = useState(INITIAL_STATE);
  const locationsData = state.data;
  const isLocationDataEmpty = isEmpty(locationsData?.data);
  const isLoading = state.loading;
  const pageCount = ceil(defaultTo(locationsData?.total, 0) / DEFAULT_PAGE_SIZE);
  const hideLocations = shouldHideLocationsPage(locationsData?.data, isLoading);

  const handleGetLocations = useCallback(
    async (page) => {
      try {
        setState((prev) => ({ ...prev, loading: true }));
        const response = await handleGetBookingLocations(defaultTo(page, 0), DEFAULT_PAGE_SIZE);
        setState({ data: response, loading: false });
      } catch (e) {
        setState({ data: false, loading: false });
      }
    },
    [setState, handleGetBookingLocations]
  );

  const handleChangePage = useCallback(
    (newPage) => {
      handleGetLocations(newPage);
    },
    [handleGetLocations]
  );

  useEffect(() => {
    if (locationsData === INITIAL_STATE.data && !isLoading) {
      handleGetLocations();
      return;
    }
    if (hideLocations) {
      const firstLocation = first(defaultTo(locationsData?.data, []));
      handleUpdateLocation(firstLocation, hideLocations);
    }
  }, [locationsData, hideLocations, isLoading, handleGetLocations, handleUpdateLocation]);

  return (
    <Stack spacing={2}>
      {isLoading ? (
        <Box sx={{ height: '100px' }}>
          <Loader />
        </Box>
      ) : (
        <>
          {isLocationDataEmpty ? (
            <EmptyRootStyle
              title={isInvalid(message?.title) ? DEFAULT_BOOKING_LOCATIONS_EMPTY_TITLE : message?.title}
              description={
                isInvalid(message?.description) ? DEFAULT_BOOKING_LOCATIONS_EMPTY_DESCRIPTION : message?.description
              }
              buttonText={
                isInvalid(message?.buttonText) ? DEFAULT_BOOKING_LOCATIONS_EMPTY_BUTTON_TEXT : message?.buttonText
              }
              onButtonClick={() => handleNavigationClick(SYSTEM_PAGE, HOME_PATH)}
              variant="h5"
            />
          ) : (
            <BookingLocationsList options={locationsData} handleUpdateLocation={handleUpdateLocation} />
          )}
        </>
      )}
      {locationsData?.total > DEFAULT_PAGE_SIZE && (
        <Box sx={{ width: '100%', justifyItems: 'center' }}>
          <Pagination
            color="primary"
            count={pageCount}
            page={defaultTo(locationsData?.page, 0) + 1}
            onChange={(_, value) => handleChangePage(value - 1)}
          />
        </Box>
      )}
    </Stack>
  );
};
