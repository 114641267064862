import { Box, Typography, Stack, CardActionArea } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';

const views = () => [
  {
    key: 'STANDARD',
    name: 'Standard',
    component: <StandardView />
  },
  {
    key: 'COMPACT',
    name: 'Compact',
    component: <CompactView />
  }
];

const CompactView = () => (
  <Stack direction="column" justifyContent="center" sx={{ height: 40 }}>
    <Stack spacing={1} direction="column" alignItems="center">
      <Box
        sx={{
          borderRadius: 0,
          height: 4,
          width: 50,
          backgroundColor: (theme) => theme.palette.primary.main
        }}
      />
      <Box
        sx={{
          borderRadius: 0,
          height: 4,
          width: 70,
          backgroundColor: (theme) => theme.palette.primary.main
        }}
      />
    </Stack>
  </Stack>
);

const StandardView = () => (
  <Stack direction="row" justifyContent="center" sx={{ height: 40 }}>
    <Stack spacing={1} direction="row" alignItems="center">
      <Box
        sx={{
          borderRadius: 0,
          height: 30,
          width: 4,
          backgroundColor: (theme) => theme.palette.primary.main
        }}
      />
      <Box
        sx={{
          borderRadius: 0,
          height: 30,
          width: 4,
          backgroundColor: (theme) => theme.palette.primary.main
        }}
      />
      <Box
        sx={{
          borderRadius: 0,
          height: 30,
          width: 4,
          backgroundColor: (theme) => theme.palette.primary.main
        }}
      />
    </Stack>
  </Stack>
);
const StyledCardActionArea = styled(CardActionArea)(({ theme }) => ({
  borderRadius: theme.shape.borderRadiusSm,
  padding: theme.spacing(3)
}));

const Option = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.grey[200],
  border: `2px solid ${theme.palette.divider}`,
  width: '100%',
  borderRadius: theme.shape.borderRadiusSm,
  padding: 0
}));

export const FooterNavigationViewPicker = ({ value, onChange, ...other }) => {
  return (
    <Stack spacing={2} direction="row" {...other}>
      {views().map((view) => {
        const { key, name, component } = view;
        return (
          <Option
            key={`${key}`}
            sx={{ ...(key === value && { border: (theme) => `2px solid ${theme.palette.primary.main}` }) }}
          >
            <StyledCardActionArea onClick={() => onChange(key)} sx={{ height: '100%' }}>
              <Box sx={{ textAlign: 'center', width: '100%', color: (theme) => theme.palette.primary.main }}>
                {component && <Box sx={{ width: '100%' }}>{component}</Box>}
                <Typography variant="subtitle2" sx={{ color: 'text.primary', mt: (theme) => theme.spacing(1) }}>
                  {name}
                </Typography>
              </Box>
            </StyledCardActionArea>
          </Option>
        );
      })}
    </Stack>
  );
};
