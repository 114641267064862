/**
 * These are the list of pages which are available for editing. This is added here to keep consistency of what pages are being used and avoid human error.
 */

export const THEME = {
  name: 'Theme',
  value: 'THEME'
};
export const HOME = {
  name: 'Home Page',
  value: 'HOME'
};
export const LAYOUT = {
  name: 'Global Layout',
  value: 'LAYOUT'
};
export const PRODUCT = {
  name: 'Product',
  value: 'PRODUCT'
};
export const PRODUCTS = {
  name: 'All Products',
  value: 'PRODUCTS'
};
export const COLLECTION_LIST = {
  name: 'All Collections',
  value: 'COLLECTION_LIST'
};
export const COLLECTION = {
  name: 'Collection',
  value: 'COLLECTION'
};
export const CHECKOUT = {
  name: 'Checkout',
  value: 'CHECKOUT'
};
export const BOOKING = {
  name: 'Booking',
  value: 'BOOKING'
};
export const CART = {
  name: 'Cart',
  value: 'CART'
};
export const CONTACT = {
  name: 'Contact',
  value: 'CONTACT'
};
export const CUSTOM = {
  name: 'Custom Page',
  value: 'CUSTOM',
  type: 'CUSTOM_PAGE'
};
export const ERROR_404 = {
  name: 'Error 404',
  value: 'ERROR_404'
};
export const ERROR_500 = {
  name: 'Error 500',
  value: 'ERROR_500'
};
