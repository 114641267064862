import PropTypes from 'prop-types';
import { createContext, useCallback, useState } from 'react';
import { useSelector } from 'src/redux/store';
import { CHECKOUT_STAGE_OPTIONS } from 'src/pages/admin_v2/editor/checkout/form-components/CheckoutStageSelector';
import { BOOKING_STAGE_OPTIONS } from 'src/pages/admin_v2/editor/booking/form-components/BookingStageSelector';
import LayoutSidebar from 'src/pages/admin_v2/editor/layout/LayoutSidebar';
import { LAYOUT, THEME } from 'src/pages/admin_v2/editor/utils/editorPages';
import ThemePageSidebar from 'src/pages/admin_v2/editor/theme/ThemePageSidebar';

// ----------------------------------------------------------------------
export const MOBILE_KEY = 'mobile';
export const DESKTOP_KEY = 'desktop';

const initialState = {
  sidebar: null,
  previewKey: DESKTOP_KEY,
  isMobileView: false,
  editDrawer: {
    open: false,
    component: null
  },
  collectionPreview: null,
  productPreview: null,
  bookingStagePreview: BOOKING_STAGE_OPTIONS[0].value,
  checkoutStagePreview: CHECKOUT_STAGE_OPTIONS[0].value,
  onChangeSidebar: () => {},
  onUpdateEditorDrawer: () => {},
  onMobileView: () => {},
  onDesktopView: () => {},
  onChangeCollectionPreview: () => {},
  onChangeProductPreview: () => {},
  onChangeBookingStagePreview: () => {},
  onChangeCheckoutStagePreview: () => {}
};

const EditorContext = createContext(initialState);

function EditorProvider({ children }) {
  const {
    editor: { page }
  } = useSelector((state) => state.store);
  const [editor, setEditor] = useState({
    // Change sidebar for different pages (e.g. Home, Contact Us)
    sidebar: initialState.sidebar,
    // Sidebar Editor
    editDrawer: initialState.editDrawer
  });
  const [view, setView] = useState({
    previewKey: initialState.previewKey,
    // Force the mobile view
    isMobileView: initialState.isMobileView
  });

  /**
   * When viewing the collection editor you can change the collection and this is used to keep track the selected option.
   * The context required and the two components do not directly interact with each other.
   */
  const [collectionPreview, setCollectionPreview] = useState();
  const [productPreview, setProductPreview] = useState();
  const [checkoutStagePreview, setCheckoutStagePreview] = useState(initialState.checkoutStagePreview);
  const [bookingStagePreview, setBookingStagePreview] = useState(initialState.bookingStagePreview);

  const handleGetSidebarComponent = useCallback(
    (component) => {
      switch (page.value) {
        case THEME.value:
          return <ThemePageSidebar />;
        case LAYOUT.value:
          return <LayoutSidebar />;
        default:
          return component;
      }
    },
    [page.value]
  );

  const handleChangeSidebar = useCallback(
    (component) => {
      setEditor((prev) => {
        return {
          ...prev,
          sidebar: handleGetSidebarComponent(component)
        };
      });
    },
    [handleGetSidebarComponent, setEditor]
  );

  const handleUpdateEditorDrawer = useCallback(
    (values) => {
      setEditor((prev) => {
        return {
          ...prev,
          editDrawer: values
        };
      });
    },
    [setEditor]
  );

  const handleMobileView = useCallback(
    (key) => {
      setView((prev) => {
        return {
          ...prev,
          previewKey: key,
          isMobileView: true
        };
      });
    },
    [setView]
  );

  const handleDesktopView = useCallback(
    (key) => {
      setView((prev) => {
        return {
          ...prev,
          previewKey: key,
          isMobileView: false
        };
      });
    },
    [setView]
  );

  const handleChangeCollectionPreview = useCallback((option) => setCollectionPreview(option), [setCollectionPreview]);

  const handleChangeProductPreview = useCallback(
    (option) => {
      setProductPreview(option);
    },
    [setProductPreview]
  );

  const handleBookingStagePreview = useCallback((value) => setBookingStagePreview(value), [setBookingStagePreview]);

  const handleCheckoutStagePreview = useCallback((value) => setCheckoutStagePreview(value), [setCheckoutStagePreview]);

  return (
    <EditorContext.Provider
      value={{
        ...editor,
        ...view,
        collectionPreview,
        productPreview,
        checkoutStagePreview,
        bookingStagePreview,
        // Sidebar
        onChangeSidebar: handleChangeSidebar,
        onUpdateEditorDrawer: handleUpdateEditorDrawer,
        onMobileView: handleMobileView,
        onDesktopView: handleDesktopView,
        onChangeCollectionPreview: handleChangeCollectionPreview,
        onChangeProductPreview: handleChangeProductPreview,
        onChangeBookingStagePreview: handleBookingStagePreview,
        onChangeCheckoutStagePreview: handleCheckoutStagePreview
      }}
    >
      {children}
    </EditorContext.Provider>
  );
}

export { EditorProvider, EditorContext };

EditorProvider.propTypes = {
  children: PropTypes.node
};
